import React, { useContext, useState, useMemo } from "react";
import useFetchItemHook from "../../hooks/use-fetch-item-hook";
import useFetchItemTypeHook from "../../hooks/use-fetch-item-type-hook";
import useFetchLotHook from "../../hooks/use-fetch-lot-hook";
import useInventoryTrackPageStateContext from "../inventory-track-page-state-context";
import useCalculateLocationMetricsHook from "../../hooks/use-calculate-location-metrics-hook";
import useInventoryTrackPageConfigContext from "../inventory-track-page-config-context";

const InventoryTrackPageDataSourceContext = React.createContext();

const initialState = {
  locationTreeMap: {},
  isLocationTreeMapLoading: true
};

export const useInventoryTrackPageDataSourceContext = () => {
  return useContext(InventoryTrackPageDataSourceContext);
};

export const InventoryTrackPageDataSourceContextProvider = ({ children }) => {
  const { selectedLocationId, applyItemTypeFilterFn, applyLotFilterFn } = useInventoryTrackPageStateContext();

  const [locationTreeMap, setLocationTreeMap] = useState(initialState.locationTreeMap);

  const [isLocationTreeMapLoading, setIsLocationTreeMapLoading] = useState(initialState.isLocationTreeMapLoading);

  const { combinedDuplicatesDataSources } = useInventoryTrackPageConfigContext();

  const leafLocationIds = useMemo(() => {
    const selectedLocation = locationTreeMap[selectedLocationId];
    if (!selectedLocation) {
      return Object.keys(locationTreeMap).filter((eachLocationId) => {
        return locationTreeMap[eachLocationId].childLocations?.length === 1;
      });
    }
    return selectedLocation.childLocations.filter((eachChildLocationId) => {
      return locationTreeMap[eachChildLocationId] && locationTreeMap[eachChildLocationId].childLocations.length === 1;
    });
  }, [selectedLocationId, locationTreeMap]);

  const itemsByLocationState = useFetchItemHook({ locationIds: leafLocationIds });

  const itemTypesByLocationState = useFetchItemTypeHook({ locationIds: leafLocationIds });

  const lotsByLocationState = useFetchLotHook({ locationIds: leafLocationIds });

  const itemTypesByLocation = useMemo(() => {
    return applyItemTypeFilterFn
      ? itemTypesByLocationState.dataList.filter((eachLot) => {
          return applyItemTypeFilterFn(eachLot);
        })
      : itemTypesByLocationState.dataList;
  }, [applyItemTypeFilterFn, itemTypesByLocationState.dataList]);

  const lotsByLocation = useMemo(() => {
    return applyLotFilterFn
      ? lotsByLocationState.dataList.filter((eachLot) => {
          return applyLotFilterFn(eachLot);
        })
      : lotsByLocationState.dataList;
  }, [applyLotFilterFn, lotsByLocationState.dataList]);

  const { currentLocationMetrics, childrenLocationsMetrics } = useCalculateLocationMetricsHook({
    itemTypesByLocation,
    lotsByLocation,
    selectedLocationId,
    locationTreeMap,
    combinedDuplicatesDataSources
  });

  return (
    <InventoryTrackPageDataSourceContext.Provider
      value={{
        locationTreeMap,
        setLocationTreeMap,
        itemsByLocationState,
        itemTypesByLocationState,
        lotsByLocationState,
        currentLocationMetrics,
        childrenLocationsMetrics,
        isLocationTreeMapLoading,
        setIsLocationTreeMapLoading
      }}
    >
      {children}
    </InventoryTrackPageDataSourceContext.Provider>
  );
};
