import React, { useMemo, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useFetchItemHook from "../../../../../../hooks/use-fetch-item-hook";
import Style from "./FilteredItemTable.module.css";
import PaginatedListTableWithTypes, {
  getValueByType
} from "../../../../../../../../components/paginated-list-table-with-types";
import useInventoryTrackPageDataSourceContext from "../../../../../../contexts/inventory-track-page-data-source-context";
import PartialLoadingMessage from "../../components/partial-loading-message";
import useInventoryTrackPageStateContext from "../../../../../../contexts/inventory-track-page-state-context";
import useAuthenticationContext from "../../../../../../../../context/authentication-context";
import { exportCsv } from "../../../../../../../../common/Utilities";
import MultiSelectActionsBar from "../../../../../../../../components/multi-select-actions-bar";
import MultiSelectActionModal from "../item-table/features/multi-select-action-modal";
import { TABLE_VIEW_TYPE_ID_MAP } from "../../../table-control-actions/features/table-view-type-control";
import useDebounce from "../../../../../../../../hooks/use-debounce";
import useGetFilteredDataList from "../../hooks/use-get-filtered-data-list";
import useInventoryTrackPageConfigContext from "../../../../../../contexts/inventory-track-page-config-context";
import { ITEM_CLASSES } from "../../../../../../../../data/constants";

export const FilteredItemTable = () => {
  const history = useHistory();

  const { cognitoUser } = useAuthenticationContext();

  const { locationTreeMap, isLocationTreeMapLoading } = useInventoryTrackPageDataSourceContext();
  const {
    freeTextSearchInputString,
    applyItemFilterFn,
    displayedTableHeadersMap,
    selectedViewModeId,
    setExportCsvFn,
    selectedLocationId,
    selectedItemGroup
  } = useInventoryTrackPageStateContext();

  const filterString = useMemo(() => {
    if (Object.keys(selectedItemGroup || {}).length) {
      return `properties.${selectedItemGroup?.type} == "${selectedItemGroup?.value}"`;
    }

    return "";
  }, [selectedItemGroup]);

  const leafLocationIds = useMemo(() => {
    if (!selectedLocationId) {
      return Object.keys(locationTreeMap).filter((eachLocationId) => {
        return locationTreeMap[eachLocationId].childLocations?.length === 1;
      });
    }
    return (
      locationTreeMap[selectedLocationId]?.childLocations.filter((eachLocationId) => {
        return locationTreeMap[eachLocationId].childLocations.length === 1;
      }) || []
    );
  }, [selectedLocationId, locationTreeMap]);

  const { dataList, isLoading, hasNextPage } = useFetchItemHook({ locationIds: leafLocationIds, filterString });

  const { listTableControl, tableViewTypeControl } = useInventoryTrackPageConfigContext();

  const [selectedItemIdsMap, setSelectedItemIdsMap] = useState({});
  const [selectedMultiSelectAction, setSelectedMultiSelectAction] = useState({});

  const numOfSelectedItem = useMemo(() => {
    return Object.values(selectedItemIdsMap).filter((eachValue) => {
      return eachValue;
    }).length;
  }, [selectedItemIdsMap]);

  const csvFileName = useMemo(() => {
    return `${selectedLocationId === undefined ? "All locations" : locationTreeMap?.[selectedLocationId]?.name} - ${
      tableViewTypeControl?.[TABLE_VIEW_TYPE_ID_MAP.item]?.label || "Item"
    }`;
  }, [locationTreeMap, selectedLocationId, tableViewTypeControl]);

  const { enabled: multiSelectEnabled, availableActions } = useMemo(() => {
    const { enabled, availableActions: availableActionsConfig } =
      listTableControl[TABLE_VIEW_TYPE_ID_MAP.item]?.multiSelectControl || {};
    const newAvailableActions = (availableActionsConfig || []).filter((eachActionConfig) => {
      if (eachActionConfig?.adminOnly && cognitoUser.attributes["custom:admin"] !== "true") {
        return false;
      }
      return true;
    });

    return {
      enabled: enabled && !!newAvailableActions.length,
      availableActions: newAvailableActions
    };
  }, [listTableControl, cognitoUser]);

  const headers = useMemo(() => {
    return displayedTableHeadersMap?.[TABLE_VIEW_TYPE_ID_MAP.item]?.[selectedViewModeId]
      ?.map((eachHeaderId) => {
        return listTableControl[TABLE_VIEW_TYPE_ID_MAP.item]?.headers.find(({ id }) => {
          return eachHeaderId === id;
        });
      })
      .filter((each) => {
        return !!each;
      });
  }, [listTableControl, displayedTableHeadersMap, selectedViewModeId]);

  const freeTextSearchInputTextDebounced = useDebounce(freeTextSearchInputString, 250);

  const filteredItemList = useGetFilteredDataList({
    freeTextSearchInputString: freeTextSearchInputTextDebounced,
    dataList,
    filterCallbackFn: applyItemFilterFn,
    headers
  });

  useEffect(() => {
    const csvData = filteredItemList.map((eachData) => {
      return {
        ...headers.reduce((accumulator, eachHeader) => {
          const { label } = eachHeader;
          accumulator[label] = getValueByType(eachData, eachHeader, true);
          return accumulator;
        }, {})
      };
    });
    setExportCsvFn(() => {
      return () => {
        exportCsv(
          csvData,
          {
            header: headers.map((eachHeader) => {
              return eachHeader.label;
            }),
            cellDates: true
          },
          csvFileName
        );
      };
    });
  }, [filteredItemList, headers, csvFileName]);

  const filteredItemListWithSelectedValue = useMemo(() => {
    return filteredItemList.map((eachItem) => {
      return { ...eachItem, isChecked: selectedItemIdsMap[eachItem.id] };
    });
  }, [filteredItemList, selectedItemIdsMap]);

  const selectedItems = filteredItemListWithSelectedValue.filter((eachItem) => {
    return eachItem.isChecked;
  });

  return (
    <div className={Style.container}>
      {hasNextPage && (
        <div
          className={`${Style.partial_loading_message_container} ${
            multiSelectEnabled &&
            !!numOfSelectedItem &&
            Style.partial_loading_message_container_with_multi_select_bar_enabled
          }`}
        >
          <PartialLoadingMessage />
        </div>
      )}
      {multiSelectEnabled && !!numOfSelectedItem && (
        <MultiSelectActionsBar
          numOfSelectedItem={numOfSelectedItem}
          multiSelectOptions={availableActions}
          onOptionClick={(action) => {
            setSelectedMultiSelectAction(action);
          }}
          onDeselectAllClick={() => {
            setSelectedItemIdsMap({});
          }}
        />
      )}
      <PaginatedListTableWithTypes
        onSelectClick={(ids, isSelected) => {
          const newSelectedItemIdsMap = ids.reduce(
            (accumulator, eachId) => {
              return { ...accumulator, [eachId]: isSelected };
            },
            { ...selectedItemIdsMap }
          );
          setSelectedItemIdsMap(newSelectedItemIdsMap);
        }}
        selectable={multiSelectEnabled}
        isLoading={isLocationTreeMapLoading || isLoading}
        paginatorLocation="top"
        headers={headers}
        getTitleURLCallbackFn={(eachItem) => {
          if (eachItem.class === ITEM_CLASSES.Container) {
            return `${history.location.pathname}/detail/container?id=${eachItem.id}`;
          }
          return `${history.location.pathname}/item/detail?itemId=${eachItem.id}`;
        }}
        dataList={filteredItemListWithSelectedValue}
      />
      {multiSelectEnabled && selectedMultiSelectAction?.id && (
        <MultiSelectActionModal
          action={selectedMultiSelectAction}
          selectedItems={selectedItems}
          onModalClose={() => {
            setSelectedMultiSelectAction(null);
          }}
        />
      )}
    </div>
  );
};
