import React, { useState, useMemo } from "react";
import { camelCase } from "lodash";
import { XemelgoClient } from "@xemelgo/x-client";
import { useItemAssociationConfigContext } from "../../../../contexts/ItemAssociationConfigContext";
import { useXemelgoClient } from "../../../../../../services/xemelgo-service";
import useAvoidRacingAPIHelper from "../../../../../../hooks/use-avoid-racing-api-helper";
import DropdownOption from "../../components/dropdown-option";
import MultiInputForm, { FieldValue, FormField, DropdownValue } from "../../../../../../components/multi-input-form";
import { FormSection } from "../../../../data/types";
import { ITEM_TYPE, ITEM } from "../../../../data/constants";
import { naturalSort } from "../../../../../../common/Utilities";
import Style from "./SingleItemAssociationForm.module.css";
import "../../../../ItemAssociationFeature.css";

type SingleItemAssociationFormProps = {
  onChange: (id: string, value: any) => void;
  errorMap: Record<string, string>;
  formData: Record<string, FieldValue>;
};

export const SingleItemAssociationForm: React.FC<SingleItemAssociationFormProps> = ({
  onChange,
  formData,
  errorMap
}) => {
  const executeFn = useAvoidRacingAPIHelper();
  const xemelgoClient: XemelgoClient | null = useXemelgoClient();
  const { itemLabel, updateItemAssociation } = useItemAssociationConfigContext();

  const formSections = useMemo<FormField[]>(() => {
    return updateItemAssociation.map((formSection: FormSection) => {
      const { id, index, label, type, options, optionsControl, isRequired } = formSection;
      const {
        fieldsToQuery = {
          item: [],
          itemType: []
        }
      } = optionsControl || {};
      const errorMessage = errorMap[id];
      const value = formData[id];
      const tempSection: FormField = { id, index, label, type, options, value, isRequired, errorMessage };
      if (id === ITEM_TYPE || id === ITEM) {
        tempSection.searchFn = (query: string) => {
          return executeFn(searchFunction(id, query, fieldsToQuery));
        };
        tempSection.renderOption = (item) => {
          return (
            <DropdownOption
              item={item}
              optionsControl={optionsControl}
            />
          );
        };
      }
      return tempSection;
    });
  }, [formData, errorMap, updateItemAssociation]);

  const searchFunction = async (
    id: string,
    input: string,
    fieldsToQuery: {
      item: string[];
      itemType: string[];
    } = { item: [], itemType: [] }
  ) => {
    const { item: itemFieldsToQuery, itemType: itemTypeFieldsToQuery } = fieldsToQuery;
    if (id === ITEM_TYPE) {
      const searchClient = xemelgoClient.getSearchClient();
      const result = await searchClient.getItemTypesByFullTextSearch(
        input,
        [...itemTypeFieldsToQuery, "identifier"],
        ["name", "identifier"]
      );
      const formattedResult = result.map((type: Record<string, unknown>) => {
        return {
          ...type,
          label: type.identifier,
          value: type.id
        };
      });

      return naturalSort(formattedResult, "label");
    }

    const itemClient = xemelgoClient.getItemClient();
    const itemType = formData!.itemType as DropdownValue;
    const { value: itemTypeId } = itemType || {};
    const result = await itemClient.getItemsFullTextContaining(input, 50, 0, itemFieldsToQuery, itemTypeFieldsToQuery);

    const formattedResult: Array<Record<string, unknown>> = [];
    result.forEach((item) => {
      const itemData = item.getResponseData();
      if (!itemTypeId || itemData.ofType?.[0]?.id === itemTypeId) {
        const { ofType = [{}], ...restProps } = itemData;
        Object.keys(ofType[0] || {}).forEach((key) => {
          restProps[camelCase(`itemType_${key}`)] = ofType[0][key];
        });
        formattedResult.push({
          ...restProps,
          label: itemData.identifier,
          value: itemData.id
        });
      }
    });
    return naturalSort(formattedResult, "label");
  };

  return (
    <div className={Style.form_container}>
      <p className={Style.form_title}>{`${itemLabel.charAt(0).toUpperCase()}${itemLabel.slice(1)} Information`}</p>
      <MultiInputForm
        formFields={formSections}
        onChange={onChange}
        isLoading={false}
        isFormDisabled={false}
        containerClassName={Style.section_container}
        fieldContainerClassName={Style.field_container}
      />
    </div>
  );
};
