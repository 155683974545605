import React, { useMemo } from "react";
import Style from "./ProgressBar.module.css";

interface ProgressBarProps {
  value?: number;
  maxValue?: number;
  renderText?: (value?: number, maxValue?: number) => string;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ value = 0, maxValue = 0, renderText }) => {
  const percentage = useMemo<number>(() => {
    if (value >= maxValue || maxValue <= 0) {
      return 100;
    }
    if (value <= 0) {
      return 0;
    }
    return (value / maxValue) * 100;
  }, [value, maxValue]);

  return (
    <div className={Style.container}>
      <div className={Style.percentage_container}>
        <div
          className={Style.percentage_fill}
          style={{ width: `${percentage}%` }}
        />
      </div>
      <p className={Style.text}>{renderText ? renderText(value, maxValue) : `${value} / ${maxValue}`}</p>
    </div>
  );
};
