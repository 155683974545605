import React, { useMemo } from "react";
import Style from "./FeatureButtons.module.css";
import useInventoryTrackPageConfigContext from "../../../../../../contexts/transfer-order-track-page-config-context";
import { FEATURE_BUTTON_COMPONENT_MAP } from "./data/constants";
import { useRoleAuthorization } from "../../../../../../../../apps/hooks/useRoleAuthorization";

export const FeatureButtons = () => {
  const { featureButtonsControl } = useInventoryTrackPageConfigContext();

  const { isAuthorized } = useRoleAuthorization({
    config: featureButtonsControl
  });

  const activeButtons = useMemo(() => {
    return featureButtonsControl
      ?.filter(({ id: featureButtonId, disabled }) => {
        return !disabled && isAuthorized(featureButtonId);
      })
      .map(({ id, label, ...restConfig }) => {
        const Component = FEATURE_BUTTON_COMPONENT_MAP[id];

        if (!Component) {
          return null;
        }
        return (
          <Component
            key={id}
            label={label}
            {...restConfig}
          />
        );
      })
      .filter((each) => {
        return each;
      });
  }, [featureButtonsControl, isAuthorized]);

  if (!activeButtons?.length) {
    return null;
  }

  return <div className={Style.container}>{activeButtons}</div>;
};
