import { TransferOrder } from "@xemelgo/x-client";
import onboardItems from "./utils/onboard-items";
import createTransferOrder from "./utils/create-transfer-order";
import updateTransferOrder from "./utils/update-transfer-order";
import createContainer from "./utils/create-container";
import publishDetectorEvents from "./utils/publish-detector-events";
import { XemelgoService } from "../../../../../../../../services/XemelgoService";
import { ACTION_OPTIONS_MAP } from "../../../../../../data/constants";
import publishMultiTransferOrderDetectorEvents from "../../features/picklist-verification/utils/publish-multi-transfer-order-detector-event";
import { ITEM_SCAN_STATUS_MAP, ITEM_TYPE_STATUS_MAP } from "../../data/constants";
import { PRINT_TIMES } from "../../../../../../../../data/constants";
import { ExistingTransferOrderContainer, NewTransferOrderContainer, TransferOrderStage } from "../../data/types";
import { ItemTypeReport, PrintOptions } from "../../../../../../data/types";

type SubmitTransferOrderActionParams = {
  existingTransferOrders: TransferOrder[];
  newTransferOrderIdentifier?: string;
  existingContainer?: ExistingTransferOrderContainer;
  newContainer?: NewTransferOrderContainer;
  stage: TransferOrderStage;
  itemTypeReports: ItemTypeReport[];
  detectorId: string;
  printOptions?: PrintOptions;
  printTime: string;
  printItems: (payload: any) => Promise<void>;
  actionType: string;
  containerTypeIdentifier: string;
};

export const submitTransferOrderAction = async (params: SubmitTransferOrderActionParams) => {
  const {
    existingTransferOrders,
    newTransferOrderIdentifier,
    existingContainer,
    newContainer,
    stage,
    itemTypeReports,
    detectorId,
    printOptions,
    printTime,
    printItems,
    actionType,
    containerTypeIdentifier
  } = params;

  const transferClient = XemelgoService.getClient().getTransferClient();

  // Onboard new items and get existing items
  const completeItemTypeReports = await onboardItems(itemTypeReports, "Inventory", actionType);

  // Create container
  let selectedContainer: ExistingTransferOrderContainer | undefined = existingContainer;
  if (newContainer?.identifier) {
    selectedContainer = await createContainer(newContainer, containerTypeIdentifier);
  }

  // The item type reports that will be used in the published detector event. If using a container, only
  // publish an event for the container to prevent issue with counts.
  let detectorEventItemReports = [...completeItemTypeReports];

  // Attach items to container
  if (selectedContainer) {
    const { itemId, itemTypeId, vid } = selectedContainer;
    const itemsIds = completeItemTypeReports.reduce((acc: string[], report) => {
      const scannedItemIds = Object.values(report.epcToItemMap)
        .filter((item) => {
          // Do not include items that are associated with the order but not scanned (for picklist verification)
          return !item.isAssociatedWithOrder || item.scanStatus.id === ITEM_SCAN_STATUS_MAP.SCANNED.id;
        })
        .map((item) => {
          return item.itemId;
        });

      return [...acc, ...scannedItemIds];
    }, []);
    await transferClient.attachItemsToContainer(itemId, itemsIds);

    const containerReport = {
      id: vid,
      identifier: vid,
      itemTypeId,
      quantityScanned: 1,
      quantityTotal: 1,
      status: ITEM_TYPE_STATUS_MAP.complete,
      epcToItemMap: {
        [vid]: {
          ...selectedContainer,
          id: "",
          type: {},
          scanStatus: ITEM_SCAN_STATUS_MAP.SCANNED,
          isAssociatedWithOrder: false,
          associatedWithTransferItemState: [],
          vid
        }
      }
    };

    completeItemTypeReports.push(containerReport);
    detectorEventItemReports = [containerReport];
  }

  if (actionType === ACTION_OPTIONS_MAP.ORDER_CREATION) {
    let transferOrder;
    if (!existingTransferOrders.length && newTransferOrderIdentifier) {
      transferOrder = await createTransferOrder(newTransferOrderIdentifier, completeItemTypeReports, "Inventory");
    } else if (existingTransferOrders.length) {
      transferOrder = await updateTransferOrder(existingTransferOrders[0], completeItemTypeReports, selectedContainer);
    } else {
      throw new Error("No transfer order found to update.");
    }

    await publishDetectorEvents(transferOrder, detectorEventItemReports, stage.submitStatus, detectorId);
  } else if (ACTION_OPTIONS_MAP.PICKLIST_VERIFICATION) {
    await publishMultiTransferOrderDetectorEvents(detectorId, existingTransferOrders, detectorEventItemReports, stage);
  }

  const transferOrderIdentifiers =
    newTransferOrderIdentifier ||
    existingTransferOrders
      .map((order) => {
        return order.identifier;
      })
      .sort()
      .join(", ");

  // Print container tags
  if (selectedContainer && printOptions?.enabled && printTime === PRINT_TIMES.now.id) {
    const printPayload = {
      containerIdentifier: selectedContainer.identifier,
      containerTypeIdentifier: selectedContainer.itemTypeIdentifier,
      transferOrderIdentifier: transferOrderIdentifiers,
      rfidTag: selectedContainer.vid
    };
    await printItems([printPayload]);
  }
};
