import React, { useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import Style from "./DetailsPageTabs.module.css";
import useDetailsPageConfigContext from "../../contexts/details-page-config-context";
import TabBar from "../../../../components/tab-bar";
import useDetailsPageStateContext from "../../contexts/details-page-state-context";

export const DetailsPageTabs = () => {
  const { tabs } = useDetailsPageConfigContext();
  const { featureMap, isLoading, selectedTabLabel, setSelectedTabLabel } = useDetailsPageStateContext();

  const currentTab = useMemo(() => {
    return (
      tabs.find((tab) => {
        return tab.label === selectedTabLabel;
      }) || tabs[0]
    );
  }, [selectedTabLabel, tabs]);

  return (
    <div>
      {tabs.length > 1 && currentTab.label && (
        <TabBar
          containerClassName={Style.tab_bar}
          tabList={tabs.map((tab) => {
            return {
              id: tab.label,
              label: tab.label
            };
          })}
          currentTabId={currentTab.label}
          onTabClick={(tabId) => {
            setSelectedTabLabel(tabId);
          }}
        />
      )}
      <div className={Style.tab_content}>
        {isLoading ? (
          <div>
            <Skeleton
              className={Style.title_text}
              width={120}
              height={40}
            />
            <Skeleton className={Style.skeleton_table} />
          </div>
        ) : (
          currentTab?.features?.map((feature) => {
            const { id, title } = feature;
            const subfeature = featureMap[id];

            if (!subfeature) {
              return null;
            }

            return (
              <div
                key={id}
                data-cy={`details-page-${id}`}
              >
                {title && <p className={Style.title_text}>{title}</p>}
                {subfeature}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};
