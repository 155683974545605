import React, { useMemo } from "react";
import Style from "./ContainerAssociationModal.module.css";
import PrinterSelectionComponent from "../../../../../../../../components/printer-selection-component";
import Modal from "../../../../../../../../components/modal";
import useKioskConfigContext from "../../../../../../contexts/kiosk-config-context";
import useKioskStateContext from "../../../../../../contexts/kiosk-state-context";
import useTransferOrderActionsConfigContext from "../../contexts/transfer-order-actions-config-context";

const FEATURE_ID = "container-association";

interface ContainerAssociationModalProps {
  numItems: number;
  printService: {
    isPrintReady: boolean;
  };
  onCancel: () => void;
  onConfirm: () => void;
  containerIdentifier: string;
  stageActionLabel: string;
}

export const ContainerAssociationModal = ({
  numItems = 0,
  printService,
  onCancel,
  onConfirm,
  containerIdentifier,
  stageActionLabel
}: ContainerAssociationModalProps) => {
  const { isPrintReady } = printService;
  const { panelValues } = useKioskStateContext();
  const { action, printOptions } = useKioskConfigContext();
  const { customText } = useTransferOrderActionsConfigContext();

  const modalText = useMemo(() => {
    return (
      <p>
        {`You are about to ${stageActionLabel} `}
        <span className={Style.bold_text}>{numItems}</span>
        {` items for `}
        <span className={Style.bold_text}>{`${panelValues.transferOrderIdentifiersString}.`}</span>
        {` Please ${
          printOptions.printLaterEnabled ? "state if you want to print a tag now or later" : " print a tag"
        }.`}
      </p>
    );
  }, [numItems, customText, containerIdentifier]);

  return (
    <Modal
      title={action.label}
      onCancel={onCancel}
      onConfirm={onConfirm}
      confirmDisabled={printOptions.enabled && !isPrintReady}
      confirmButtonText="Next"
    >
      <div className={Style.container}>
        {modalText}
        {printOptions.enabled && (
          <div className={Style.printer_component}>
            <PrinterSelectionComponent
              printService={printService}
              hideLabelSelection={!!printOptions.printConfig}
              solutionType={FEATURE_ID}
              isPrintLaterEnabled={printOptions.printLaterEnabled}
              isPrintQuantityEnabled={printOptions.printQuantityEnabled}
              showTitle={false}
              layout="vertical"
            />
          </div>
        )}
      </div>
    </Modal>
  );
};
