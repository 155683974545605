import React, { FunctionComponent, SVGProps } from "react";
import { ReactComponent as PrintIcon } from "../../assets/icons/printer.svg";
import { ReactComponent as LocationIcon } from "../../assets/icons/location.svg";
import { ReactComponent as CheckMarkIcon } from "../../assets/icons/checkmark.svg";
import Style from "./MultiSelectActionsBar.module.css";

type SVGIcon = FunctionComponent<
  SVGProps<SVGSVGElement> & {
    title?: string;
  }
>;

const multiSelectOptionIcons: Record<string, SVGIcon> = {
  printTags: PrintIcon,
  setLocation: LocationIcon,
  markAsComplete: CheckMarkIcon
};

export type MultiSelectOption = {
  id?: string;
  label?: string;
  IconComponent?: SVGIcon;
};

interface MultiSelectActionsBarProps {
  numOfSelectedItem: number;
  multiSelectOptions: MultiSelectOption[];
  onOptionClick: (option: MultiSelectOption) => void;
  onDeselectAllClick: () => void;
}

export const MultiSelectActionsBar: React.FC<MultiSelectActionsBarProps> = ({
  numOfSelectedItem,
  multiSelectOptions,
  onOptionClick,
  onDeselectAllClick
}) => {
  return (
    <div className={Style.action_bar}>
      <div className={Style.selection_info}>
        <div className={Style.selected_amount}>{numOfSelectedItem}</div>
        <div className={Style.selected_text}>selected</div>
        <div
          className={`${Style.action_bar_button} ${Style.deselect_all}`}
          onClick={onDeselectAllClick}
        >
          Deselect All
        </div>
      </div>
      <div className={Style.multi_select_options}>
        {multiSelectOptions.map((option) => {
          const { id, label, IconComponent: IconComponentProp } = option;

          if (!id || !label) {
            return null;
          }

          const IconComponent = IconComponentProp || multiSelectOptionIcons[id];

          return (
            <div
              className={`${Style.multi_select_option} ${Style.action_bar_button}`}
              key={id}
              onClick={() => {
                onOptionClick(option);
              }}
            >
              <IconComponent
                data-cy-multi-select-action-button
                width="23px"
                height="23px"
                className={Style.action_icon}
              />
              <div className={`${Style.multi_select_option_text}`}>{label}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
