import { useMemo } from "react";
import { useStore } from "react-hookstore";
import { API, graphqlOperation } from "aws-amplify";
import { ClientProvider } from "@xemelgo/x-client";
import CONFIGURATION from "../configuration.json";
import { configStore } from "../state-managements/stores/config-store";
import { userProfileStore } from "../state-managements/stores/user-profile-store";

export const useXemelgoAppsyncClient = () => {
  const [userConfig] = useStore(configStore);
  const [userProfile] = useStore(userProfileStore);
  const services = ["appSync"];

  const client = useMemo(() => {
    let apiVersion = null;
    const endpoints = services
      .filter((serviceName) => {
        return userConfig?.getServiceConfiguration(serviceName);
      })
      .map((serviceName) => {
        const config = userConfig?.getServiceConfiguration(serviceName);
        const { name, endpoint, region, apiVersion: version } = config;

        if (version) {
          apiVersion = version;
        }

        return {
          name,
          endpoint,
          region
        };
      });

    const clientConfig = {
      endpoints,
      apiVersion: apiVersion || "v2",
      auth: CONFIGURATION.AuthConfiguration,
      customerId: userProfile?.getTenantId(),
      partnerId: userProfile?.getPartnerId()
    };

    const xemelgoClient = ClientProvider.getClient(
      API,
      clientConfig,
      userProfile?.getIdToken(),
      () => {
        return userProfile?.refreshIdToken() || Promise.resolve("");
      },
      graphqlOperation
    );
    return xemelgoClient;
  }, [userProfile, userConfig]);

  return client;
};
