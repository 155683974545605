import React from "react";
import { ReactComponent as ErrorIcon } from "../../../../assets/icons/error.svg";
import Style from "./ErrorScreen.module.css";

export const ErrorScreen = () => {
  return (
    <div className={Style.error_screen}>
      <ErrorIcon className={Style.error_icon} />
      <p className={Style.text}>Failed to load details. Please contact Xemelgo support for assistance.</p>
    </div>
  );
};
