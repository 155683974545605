import React, { useState } from "react";
import Style from "../../FeatureButtons.module.css";
import ResetItemsActionModal from "../../../../../../../../../reset-items-action-modal";

interface ResetItemsButtonProps {
  label?: string;
  locationCategories?: string[];
}

export const ResetItemsButton: React.FC<ResetItemsButtonProps> = ({
  label = "Reset Items",
  locationCategories = ["all"]
}) => {
  const [showResetItemsModal, setShowResetItemsModal] = useState(false);

  return (
    <div>
      <button
        type="button"
        className={Style.button}
        onClick={() => {
          setShowResetItemsModal(true);
        }}
      >
        {label}
      </button>
      {showResetItemsModal && (
        <ResetItemsActionModal
          title={label}
          locationCategories={locationCategories}
          onCloseModal={() => {
            setShowResetItemsModal(false);
          }}
          onPostConfirm={(error) => {
            if (!error) {
              setShowResetItemsModal(false);
            }
          }}
        />
      )}
    </div>
  );
};
