import React, { useMemo } from "react";
import usePackageOrderDetailConfigContext from "../../contexts/package-order-detail-config-context";
import { getOverallOrderStatusData } from "../../utils/utils";
import Style from "./PackageOrderStatusTable.module.css";
import { DetailsPageTable } from "../../../details-page";
import { PackageOrderData } from "../../data/types";

const PackageOrderStatusTable = ({ orderData }: { orderData: PackageOrderData }) => {
  const { overallStatusTable, packageStateToDisplayedStatusMap } = usePackageOrderDetailConfigContext();

  const overallOrderStatusTableData = useMemo(() => {
    return getOverallOrderStatusData(orderData, packageStateToDisplayedStatusMap);
  }, [orderData, packageStateToDisplayedStatusMap]);

  return (
    <div className={Style.table_container}>
      <div className={Style.table_title}>Overall Status</div>
      <DetailsPageTable
        dataList={overallOrderStatusTableData}
        headers={overallStatusTable.headers}
        textSearchEnabled={false}
        exportEnabled={false}
        paginatorLocation="none"
      />
    </div>
  );
};

export default PackageOrderStatusTable;
