import { useReducer, useEffect, useRef, useState } from "react";
import { cloneDeep } from "lodash";
import { useXemelgoAppsyncClient } from "../../../../../../services/xemelgo-appsync-service";

export const ACTION_TYPE = {
  RESET: "reset",
  FINISHED_FETCHING: "finishedFetching",
  FETCHING: "fetching",
  FETCHING_NEXT_PAGE: "fetchingNextPage",
  PUBLISH_DATA: "publishData"
};

export type AssetType = {
  id: string;
  [key: string]: any;
};

type FetchAssetsState = {
  assetMap: Record<string, AssetType>;
  locationToAssets: Record<string, AssetType[]>;
  locationToAssetMap: Record<string, Record<string, AssetType>>;
  isLoading: boolean;
  hasNextPage: boolean;
};

const initialState: FetchAssetsState = {
  assetMap: {},
  locationToAssets: {},
  locationToAssetMap: {},
  isLoading: true,
  hasNextPage: false
};

const reducer = (
  state: FetchAssetsState,
  action: { type: string; payload?: { newAssets?: AssetType[]; incompleteLocationIds?: string[] } }
) => {
  switch (action.type) {
    case ACTION_TYPE.RESET:
      return cloneDeep(initialState);
    case ACTION_TYPE.FETCHING:
      return { ...state, isLoading: true };
    case ACTION_TYPE.FINISHED_FETCHING:
      return { ...state, isLoading: false };
    case ACTION_TYPE.FETCHING_NEXT_PAGE:
      return { ...state, hasNextPage: true };
    case ACTION_TYPE.PUBLISH_DATA:
      const newAssetMap = { ...state.assetMap };
      const newLocationToAssetMap = { ...state.locationToAssetMap };

      const { newAssets = [] } = action.payload || {};

      newAssets.forEach((asset) => {
        newAssetMap[asset.id] = asset;

        newLocationToAssetMap[asset.locationId] = newLocationToAssetMap[asset.locationId] || {};
        newLocationToAssetMap[asset.locationId][asset.id] = asset;
      });

      const newLocationToAssets = Object.keys(newLocationToAssetMap).reduce((acc, locationId) => {
        acc[locationId] = Object.values(newLocationToAssetMap[locationId]);
        return acc;
      }, {} as Record<string, AssetType[]>);

      return {
        ...state,
        assetMap: newAssetMap,
        locationToAssets: newLocationToAssets,
        locationToAssetMap: newLocationToAssetMap,
        isLoading: true,
        hasNextPage: false
      };
    default:
      return state;
  }
};

export const useFetchAssets = (
  locationTreeMap: Record<string, { childLocations: string[] }>,
  selection: string[] = [],
  ignoredItemStates: string[],
  flatCategoryTrees?: Record<string, { depth: number; identifier: string }>,
  selectedLocationId?: string
) => {
  const appsyncClient = useXemelgoAppsyncClient();
  const [completedLocationIdSet, setCompletedLocationIdSet] = useState<Set<string>>(new Set());

  const startTimeRef = useRef<number>();

  const [state, dispatch] = useReducer(reducer, {
    assetMap: initialState.assetMap,
    locationToAssets: initialState.locationToAssets,
    locationToAssetMap: initialState.locationToAssetMap,
    isLoading: initialState.isLoading,
    hasNextPage: initialState.hasNextPage
  });

  const fetchAssets = async () => {
    const assetAppsyncClient = appsyncClient.getAssetClient();
    const startTime = Date.now();
    startTimeRef.current = Date.now();

    dispatch({ type: ACTION_TYPE.FETCHING });

    // Only query for leaf locations that have not been fully queried yet
    let locationIdsToQuery = selectedLocationId
      ? locationTreeMap[selectedLocationId]?.childLocations
      : Object.keys(locationTreeMap);
    locationIdsToQuery = locationIdsToQuery.filter((locationId) => {
      return !completedLocationIdSet.has(locationId);
    });

    if (locationIdsToQuery.length === 0) {
      dispatch({ type: ACTION_TYPE.FINISHED_FETCHING });
      return;
    }

    let nextTokens: string[] = [];
    let hasNextPage = false;

    do {
      const { assets, nextTokens: newNextTokens } = await assetAppsyncClient.getAssetsByLocationIds(
        locationIdsToQuery,
        selection,
        ignoredItemStates,
        nextTokens
      );

      if (startTimeRef.current !== startTime) {
        return;
      }

      const newAssets = assets.map((asset: AssetType) => {
        const newAsset = { ...asset };
        const categoryIds: string[] = asset.itemTypeCategoryIds;

        categoryIds.forEach((categoryId) => {
          const category = flatCategoryTrees?.[categoryId];
          if (category?.depth === 0) {
            newAsset.itemTypeCategory = category.identifier;
          }
          if (category?.depth === 1) {
            newAsset.itemTypeSubcategory = category.identifier;
          }
        });
        return newAsset;
      });

      dispatch({
        type: ACTION_TYPE.PUBLISH_DATA,
        payload: {
          newAssets
        }
      });

      nextTokens = newNextTokens;
      hasNextPage =
        nextTokens.filter((eachToken) => {
          return !!eachToken;
        }).length !== 0;

      if (hasNextPage) {
        dispatch({ type: ACTION_TYPE.FETCHING_NEXT_PAGE });
      }
    } while (hasNextPage);

    const newCompletedLocationIdSet = new Set(completedLocationIdSet);
    locationIdsToQuery.forEach((locationId) => {
      newCompletedLocationIdSet.add(locationId);
    });
    setCompletedLocationIdSet(newCompletedLocationIdSet);

    dispatch({ type: ACTION_TYPE.FINISHED_FETCHING });
  };

  useEffect(() => {
    if (selection.length && flatCategoryTrees && Object.keys(locationTreeMap).length > 0) {
      fetchAssets();
    }
  }, [locationTreeMap, flatCategoryTrees, selection, selectedLocationId]);

  return state;
};
