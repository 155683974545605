import { binaryToHex, getFormattedDate } from "../../common/Utilities";

// TODO: Tag length should probably be retrieved from template to adapt to different tags/printers
export const DEFAULT_TAG_LENGTH = 24;

const XEMELGO_HEADER_BITS = "010111";
const EPC_FORMAT_VERSION_BITS = "00";

const CLASS_BITS = {
  Traveller: "0000",
  Part: "0001",
  Inventory: "0010",
  Material: "0011",
  Stock: "0100",
  Asset: "0101",
  Container: "0110",
  Collection: "0111",
  Package: "1000"
};

export const generateTag = (
  tagClass: keyof typeof CLASS_BITS,
  numCharacters: number = DEFAULT_TAG_LENGTH,
  customPrefix?: string
) => {
  return generateTags(tagClass, numCharacters, 1, customPrefix)[0];
};

export const generateTags = (
  tagClass: keyof typeof CLASS_BITS,
  numCharacters = 24,
  numTags = 1,
  customPrefix: string = ""
) => {
  if (numTags < 1) {
    throw new Error("Number of tags must be at least 1");
  } else if (!CLASS_BITS[tagClass]) {
    throw new Error(`Invalid tag class: "${tagClass}"`);
  } else if (!isValidHexadecimal(customPrefix)) {
    throw new Error(`Invalid hexadecimal prefix: "${customPrefix}"`);
  }

  const classBits = CLASS_BITS[tagClass];

  const xemelgoHeader = binaryToHex(`${XEMELGO_HEADER_BITS}${EPC_FORMAT_VERSION_BITS}${classBits}`);
  let epc = `${xemelgoHeader}${customPrefix}`;

  const availableBytes = numCharacters - epc.length - `${numTags}`.length;
  const timestamp = Date.now();
  const timestampLength = timestamp.toString().length;

  if (availableBytes >= 15) {
    const timeFormat = "YYMMDDHHmmssSSS";
    epc += getFormattedDate(timestamp, timeFormat);
  } else if (availableBytes >= timestampLength) {
    epc += timestamp;
  } else {
    const totalBytes = epc.length + `${numTags}`.length + timestampLength;

    throw new Error(
      `EPC exceeds maximum character limit of ${numCharacters}. Current parameters result in an EPC with ` +
        `total length ${totalBytes}, with ${epc.length} characters for the header, ${numTags.toString().length} ` +
        `characters for the serial number, and at least ${timestampLength} characters required for the timestamp.`
    );
  }

  const tags = [];
  for (let i = 1; i <= numTags; i++) {
    const paddingLength = numCharacters - `${epc}${i}`.length;
    const paddedString = "0".repeat(paddingLength) + i;
    tags.push(`${epc}${paddedString}`);
  }

  return tags;
};

const isValidHexadecimal = (hexStr: string) => {
  const hexRegex = /^([0-9a-fA-F]*)$/;
  return hexRegex.test(hexStr);
};
