import React from "react";
import PropTypes from "prop-types";
import Style from "./HeaderWithSortIcon.module.css";
import { ReactComponent as SortUpIcon } from "../../../../assets/icons/sort-up.svg";
import { ReactComponent as SortDownIcon } from "../../../../assets/icons/sort-down.svg";
import { SORT_DIRECTION } from "../../../../data/constants";

export const HeaderWithSortIcon = ({ isActive, direction, label, onClick, className }) => {
  return (
    <div
      className={`${Style.container} ${className}`}
      onClick={onClick}
    >
      <div className={`${Style.header_container}`}>
        <div className={Style.label}>{label}</div>
        <div className={`${Style.sort_icons_container} ${isActive && Style.sort_icons_container_active}`}>
          <SortUpIcon
            className={`${Style.sort_icon} ${
              isActive && direction === SORT_DIRECTION.ascending && Style.sort_icon_active
            }`}
          />
          <SortDownIcon
            className={`${Style.sort_icon} ${
              isActive && direction === SORT_DIRECTION.descending && Style.sort_icon_active
            }`}
          />
        </div>
      </div>
    </div>
  );
};

HeaderWithSortIcon.defaultProps = {
  isActive: false,
  direction: SORT_DIRECTION.ascending,
  label: "",
  onClick: () => {},
  className: ""
};

HeaderWithSortIcon.propTypes = {
  isActive: PropTypes.bool,
  direction: PropTypes.oneOf([SORT_DIRECTION.ascending, SORT_DIRECTION.descending]),
  label: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string
};
