import React from "react";
import Style from "./CollapsibleView.module.css";

interface CollapsibleViewProps {
  children?: React.ReactNode;
  isCollapsed?: boolean | null;
  width?: number;
}

export const CollapsibleView: React.FC<CollapsibleViewProps> = ({ children, isCollapsed, width = 190 }) => {
  return (
    <div
      className={Style.information_container}
      style={{
        width: isCollapsed ? 0 : width,
        maxWidth: width
      }}
    >
      {children}
    </div>
  );
};
