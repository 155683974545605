import React, { useMemo, useEffect } from "react";
import useInventoryTrackPageStateContext from "../../../../../../contexts/inventory-track-page-state-context";
import PaginatedListTableWithTypes, {
  getValueByType
} from "../../../../../../../../components/paginated-list-table-with-types";
import useInventoryTrackPageDataSourceContext from "../../../../../../contexts/inventory-track-page-data-source-context";
import Style from "./LotTable.module.css";
import PartialLoadingMessage from "../../components/partial-loading-message";
import useDebounce from "../../../../../../../../hooks/use-debounce";
import useGetFilteredDataList from "../../hooks/use-get-filtered-data-list";
import useInventoryTrackPageConfigContext from "../../../../../../contexts/inventory-track-page-config-context";
import { TABLE_VIEW_TYPE_ID_MAP } from "../../../table-control-actions/features/table-view-type-control";
import { exportCsv } from "../../../../../../../../common/Utilities";

export const LotTable = () => {
  const { lotsByLocationState, locationTreeMap, isLocationTreeMapLoading } = useInventoryTrackPageDataSourceContext();

  const { dataList, isLoading, hasNextPage } = lotsByLocationState;

  const {
    getURLByState,
    freeTextSearchInputString,
    applyLotFilterFn,
    displayedTableHeadersMap,
    selectedViewModeId,
    setExportCsvFn,
    selectedLocationId,
    selectedViewTypeId
  } = useInventoryTrackPageStateContext();
  const { listTableControl, tableViewTypeControl, combinedDuplicatesDataSources } =
    useInventoryTrackPageConfigContext();

  const isCombinedDuplicatesEnabled = useMemo(() => {
    return combinedDuplicatesDataSources?.includes("lot") || false;
  }, [combinedDuplicatesDataSources]);

  const csvFileName = useMemo(() => {
    return `${selectedLocationId === undefined ? "All locations" : locationTreeMap?.[selectedLocationId]?.name} - ${
      tableViewTypeControl?.[selectedViewTypeId]?.label
    }`;
  }, [locationTreeMap, selectedLocationId, selectedViewTypeId, tableViewTypeControl]);

  const headers = useMemo(() => {
    return displayedTableHeadersMap?.[TABLE_VIEW_TYPE_ID_MAP.lot]?.[selectedViewModeId]
      ?.map((eachHeaderId) => {
        return listTableControl[TABLE_VIEW_TYPE_ID_MAP.lot]?.headers.find(({ id }) => {
          return eachHeaderId === id;
        });
      })
      .filter((each) => {
        return !!each;
      });
  }, [listTableControl, displayedTableHeadersMap, selectedViewModeId]);

  const freeTextSearchInputTextDebounced = useDebounce(freeTextSearchInputString, 250);

  const filteredLotList = useGetFilteredDataList({
    freeTextSearchInputString: freeTextSearchInputTextDebounced,
    dataList,
    filterCallbackFn: applyLotFilterFn,
    headers
  });

  const combinedCountLotList = useMemo(() => {
    return Object.values(
      filteredLotList.reduce((accumulator, eachLot) => {
        const { lotNumber: eachLotNumber, itemTypeId: eachItemTypeId } = eachLot;

        const lotIdentifier = `${eachLotNumber}-${eachItemTypeId}`;
        // Accumulate all count attributes directly
        if (!accumulator[lotIdentifier]) {
          accumulator[lotIdentifier] = { ...eachLot };
        } else {
          Object.keys(eachLot).forEach((key) => {
            if (key.toLowerCase().includes("count")) {
              accumulator[lotIdentifier][key] = (accumulator[lotIdentifier][key] || 0) + eachLot[key];
            }
            if (key.toLowerCase().includes("threshold") && accumulator[lotIdentifier][key]) {
              accumulator[lotIdentifier][key] = [
                Array.isArray(accumulator[lotIdentifier][key])
                  ? [...accumulator[lotIdentifier][key], eachLot[key]]
                  : [accumulator[lotIdentifier][key], eachLot[key]]
              ];
            }
            if (["isExpired", "isExpiringSoon"].includes(key)) {
              accumulator[lotIdentifier][key] = accumulator[lotIdentifier][key] || eachLot[key];
            }
            if (["isNormalExpiry"].includes(key)) {
              accumulator[lotIdentifier][key] = accumulator[lotIdentifier][key] && eachLot[key];
            }
          });
        }

        return accumulator;
      }, {})
    );
  }, [filteredLotList]);

  useEffect(() => {
    const csvData = (isCombinedDuplicatesEnabled ? combinedCountLotList : filteredLotList).map((eachData) => {
      return {
        ...headers.reduce((accumulator, eachHeader) => {
          const { label } = eachHeader;
          accumulator[label] = getValueByType(eachData, eachHeader, true);
          return accumulator;
        }, {})
      };
    });
    setExportCsvFn(() => {
      return () => {
        exportCsv(
          csvData,
          {
            header: headers.map((eachHeader) => {
              return eachHeader.label;
            }),
            cellDates: true
          },
          csvFileName
        );
      };
    });
  }, [isCombinedDuplicatesEnabled, combinedCountLotList, filteredLotList, headers, csvFileName]);

  return (
    <div className={Style.container}>
      {hasNextPage && (
        <div className={Style.partial_loading_message_container}>
          <PartialLoadingMessage />
        </div>
      )}
      <PaginatedListTableWithTypes
        isLoading={isLocationTreeMapLoading || isLoading}
        paginatorLocation="top"
        headers={headers}
        getTitleURLCallbackFn={(lot, headerId) => {
          return getURLByState(lot?.[headerId] ? { selectedItemGroup: { type: headerId, value: lot[headerId] } } : {});
        }}
        dataList={isCombinedDuplicatesEnabled ? combinedCountLotList : filteredLotList}
      />
    </div>
  );
};
