import React, { ReactNode, createContext, useState, useContext, useEffect } from "react";
import { STATUS_OPTIONS } from "../../../../components/status-popup-component/data/constants";
import useKioskSearchParams from "../../hooks/use-kiosk-search-params";
import { READ_STATUS_OPTIONS } from "../../../../hooks/use-mounted-reader";
import { AvailableReader, ItemTypeReport, KioskItem, LocationOption } from "../../data/types";

type KioskStateContextType = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  locationOptions: LocationOption[];
  setLocationOptions: (locationOptions: LocationOption[]) => void;
  itemByTag: Record<string, KioskItem>;
  setItemByTag: (itemByTag: Record<string, KioskItem>) => void;
  itemTypeReports: ItemTypeReport[];
  setItemTypeReports: (itemTypeReports: ItemTypeReport[]) => void;
  searchInput: string;
  setSearchInput: (searchInput: string) => void;
  panelValues: Record<string, any>;
  setPanelValues: (panelValues: Record<string, any>) => void;
  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
  submitStatus: string;
  setSubmitStatus: (submitStatus: string) => void;
  statusMessage: string;
  setStatusMessage: (statusMessage: string) => void;
  optionTemplateMap: Record<string, any>;
  setOptionTemplateMap: (optionTemplateMap: Record<string, any>) => void;
  availableReaders: AvailableReader[];
  setAvailableReaders: (availableReaders: AvailableReader[]) => void;
  readStatus: { id: string; buttonLabel: string };
  setReadStatus: (readStatus: { id: string; buttonLabel: string }) => void;
};

const initialState: KioskStateContextType = {
  loading: false,
  locationOptions: [],
  itemByTag: {},
  itemTypeReports: [],
  searchInput: "",
  panelValues: {},
  isSubmitting: false,
  submitStatus: STATUS_OPTIONS.NONE,
  statusMessage: "",
  optionTemplateMap: {},
  availableReaders: [],
  readStatus: READ_STATUS_OPTIONS.NOT_READING,
  setLoading: () => {},
  setLocationOptions: () => {},
  setItemByTag: () => {},
  setItemTypeReports: () => {},
  setSearchInput: () => {},
  setPanelValues: () => {},
  setIsSubmitting: () => {},
  setSubmitStatus: () => {},
  setStatusMessage: () => {},
  setOptionTemplateMap: () => {},
  setAvailableReaders: () => {},
  setReadStatus: () => {}
};

const KioskStateContext = createContext<KioskStateContextType>(initialState);

export const useKioskStateContext = () => {
  return useContext(KioskStateContext);
};

export const KioskStateContextProvider = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState(initialState.loading);
  const [isSubmitting, setIsSubmitting] = useState(initialState.isSubmitting);
  const [submitStatus, setSubmitStatus] = useState(initialState.submitStatus);
  const [statusMessage, setStatusMessage] = useState(initialState.statusMessage);
  const [locationOptions, setLocationOptions] = useState(initialState.locationOptions);
  const [availableReaders, setAvailableReaders] = useState(initialState.availableReaders);
  const [itemByTag, setItemByTag] = useState(initialState.itemByTag);
  const [itemTypeReports, setItemTypeReports] = useState(initialState.itemTypeReports);
  const [searchInput, setSearchInput] = useState(initialState.searchInput);
  const [panelValues, setPanelValues] = useState(initialState.panelValues);
  const [optionTemplateMap, setOptionTemplateMap] = useState(initialState.optionTemplateMap);
  const [readStatus, setReadStatus] = useState(initialState.readStatus);

  const { actionId } = useKioskSearchParams();

  useEffect(() => {
    if (!actionId) {
      setIsSubmitting(initialState.isSubmitting);
      setItemByTag(initialState.itemByTag);
      setItemTypeReports(initialState.itemTypeReports);
      setPanelValues(initialState.panelValues);
      setSearchInput(initialState.searchInput);
      setAvailableReaders(initialState.availableReaders);
    }
  }, [actionId]);

  return (
    <KioskStateContext.Provider
      value={{
        loading,
        setLoading,
        locationOptions,
        setLocationOptions,
        itemByTag,
        setItemByTag,
        itemTypeReports,
        setItemTypeReports,
        searchInput,
        setSearchInput,
        panelValues,
        setPanelValues,
        isSubmitting,
        setIsSubmitting,
        submitStatus,
        setSubmitStatus,
        statusMessage,
        setStatusMessage,
        optionTemplateMap,
        setOptionTemplateMap,
        availableReaders,
        setAvailableReaders,
        readStatus,
        setReadStatus
      }}
    >
      {children}
    </KioskStateContext.Provider>
  );
};
