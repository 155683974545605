import { XemelgoService } from "../../../../../../../../../../services/XemelgoService";
import { ACTION_OPTIONS_MAP } from "../../../../../../../../data/constants";
import { ItemTypeReport, KioskItem } from "../../../../../../../../data/types";
import { queryItemsFromBasicTags } from "../../../../../../../../utils/query-items-from-basic-tags/queryItemsFromBasicTags";

type ProcessedKioskItem = KioskItem & {
  sensorProfileId: string;
  itemTypeId: string;
  itemId: string;
};

export type ProcessedItemTypeReport = ItemTypeReport & {
  id: string;
  identifier: string;
  itemTypeId: string;
  epcToItemMap: Record<string, ProcessedKioskItem>;
};

export const onboardItems = async (
  itemTypeReports: ItemTypeReport[],
  itemClass: string,
  actionType: string
): Promise<ProcessedItemTypeReport[]> => {
  const inventoryClient = XemelgoService.getClient().getInventoryClient();

  const { epcToCreatedItemAcc: epcToCreatedItem, itemsToQueryAcc: itemsToQuery } = itemTypeReports.reduce(
    (
      acc: {
        epcToCreatedItemAcc: Record<string, ProcessedKioskItem>;
        itemsToQueryAcc: KioskItem[];
      },
      itemTypeReport
    ) => {
      const { epcToCreatedItemAcc, itemsToQueryAcc } = acc;
      const { epcToItemMap } = itemTypeReport;

      Object.entries(epcToItemMap).forEach(([epc, item]) => {
        if (item.id && item.sensorProfileId) {
          // item was retrieved on MainPage when scanning tags
          epcToCreatedItemAcc[epc] = {
            ...item,
            itemId: item.id,
            sensorProfileId: item.sensorProfileId
          } as ProcessedKioskItem;
        } else {
          itemsToQueryAcc.push(item);
        }
      });

      return { epcToCreatedItemAcc, itemsToQueryAcc };
    },
    {
      epcToCreatedItemAcc: {},
      itemsToQueryAcc: []
    }
  );

  if (itemsToQuery.length) {
    const existingItems = Object.values(await queryItemsFromBasicTags(itemsToQuery, inventoryClient, {}, [itemClass]));

    existingItems.forEach((item) => {
      epcToCreatedItem[item.vid] = item;
    });
  }

  const payload = itemTypeReports.reduce((acc: Record<string, string>[], itemTypeReport) => {
    const { identifier: itemTypeIdentifer, epcToItemMap } = itemTypeReport;

    Object.entries(epcToItemMap).forEach(([epc, item]) => {
      if (!epcToCreatedItem[epc]) {
        acc.push({
          item_number: itemTypeIdentifer,
          item_identifier: item.identifier,
          tracker_serial: epc,
          class: itemClass
        });
      }
    });

    return acc;
  }, []);

  if (payload.length) {
    const createdItemVids = (await inventoryClient.createItemSet(payload)).createItemSet.map((vid) => {
      return {
        vid
      };
    });
    const createdItems = Object.values(
      await queryItemsFromBasicTags(createdItemVids, inventoryClient, {}, [itemClass])
    );

    createdItems.forEach((item) => {
      epcToCreatedItem[item.vid] = item;
    });
  }

  return formatItemTypeReports(itemTypeReports, epcToCreatedItem, actionType);
};

const formatItemTypeReports = (
  itemTypeReports: ItemTypeReport[],
  epcToCreatedItem: Record<string, ProcessedKioskItem>,
  actionType: string
) => {
  return itemTypeReports.reduce((acc: ProcessedItemTypeReport[], itemTypeReport) => {
    let { id: itemTypeId } = itemTypeReport;
    const { epcToItemMap } = itemTypeReport;

    const newEpcToItemMap = Object.entries(epcToItemMap)
      .map(([epc, item]) => {
        return {
          ...item,
          ...epcToCreatedItem[epc]
        };
      })
      .filter((item) => {
        if (actionType === ACTION_OPTIONS_MAP.ORDER_CREATION) {
          // Filter out items that are associated already associated with a transfer order
          return item.associatedWithTransferItemState?.length === 0;
        }
        if (actionType === ACTION_OPTIONS_MAP.PICKLIST_VERIFICATION) {
          // Filter out items that are associated with a transfer order other than other the current one
          return item.isAssociatedWithOrder || item.associatedWithTransferItemState?.length === 0;
        }
        return true;
      })
      .reduce((itemAcc: Record<string, ProcessedKioskItem>, item) => {
        itemAcc[item.vid] = item;
        return itemAcc;
      }, {});

    // If a new type was created through createItemSet, use that type id
    if (!itemTypeId && Object.values(newEpcToItemMap).length) {
      itemTypeId = Object.values(newEpcToItemMap)[0].itemTypeId as string;
    }

    acc.push({
      ...itemTypeReport,
      itemTypeId,
      epcToItemMap: newEpcToItemMap
    });

    return acc;
  }, []);
};
