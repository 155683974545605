import { useMemo } from "react";

export const useCalculateLocationMetricsHook = (transferOrderCounts) => {
  const currentLocationMetrics = useMemo(() => {
    return transferOrderCounts.reduce((accumulator, eachLocationCount) => {
      Object.keys(eachLocationCount)
        .filter((eachAttribute) => {
          return eachAttribute.includes("Count");
        })
        .forEach((eachCountAttribute) => {
          accumulator[eachCountAttribute] =
            (accumulator[eachCountAttribute] || 0) + eachLocationCount[eachCountAttribute];
        });
      return accumulator;
    }, {});
  }, [transferOrderCounts]);

  return { currentLocationMetrics };
};
