/*
 * Steps to decode SGTIN-96 tag:
 *
 * 1. Check that the first two bytes are "0x30" and that there are 24 bytes
 * 2. Convert the hex string to bits
 * 3. Get the filter bits (bits with index 8 to 10 inclusive)
 * 4. Get the partition bits (bits with index 11 to 13 inclusive). The partition bits indicate the length of the company
 *    prefix and item reference sections. The table of partition values can be found online.
 * 5. Get the company prefix and item reference bits (in total these two sections should be 44 bits)
 * 6. Get the serial number bits (the remaining 38 bits)
 * 7. Convert all these values to decimal
 * 7. To get the GTIN-14 UPC:
 *     a. First make sure the company prefix and item reference are strings padded with 0s up to the
 *        specified digit length defined by the partition value
 *     b. Put the first digit from the item reference at the start of the UPC, followed by the company prefix,
 *        and then the rest of the item reference
 *     c. Compute the check digit and add it to the end of the UPC
 */

import hexToBinary from "../hex-to-binary";
import isValidHexadecimal from "../is-valid-hexadecimal";
import { SGTINInfo } from "./data/types";

const SGTIN96_HEADER = "30";
const SECTION_INDEXES = {
  FILTER_START: 8,
  FILTER_END: 11,
  PARTITION_START: 11,
  PARTITION_END: 14
};

class SGTINError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "SGTINError";
  }
}

export const decodeSGTIN96Tag = (tagHex: string): SGTINInfo => {
  if (!isValidHexadecimal(tagHex)) {
    throw new SGTINError("Invalid hexadecimal string");
  }

  if (tagHex.length !== 24) {
    throw new SGTINError(`Invalid tag length: ${tagHex.length}`);
  }

  const header = tagHex.slice(0, 2);
  if (header !== SGTIN96_HEADER) {
    throw new SGTINError("Unknown tag header");
  }

  const binary = hexToBinary(tagHex);

  const filterBits = binary.slice(SECTION_INDEXES.FILTER_START, SECTION_INDEXES.FILTER_END);
  const filter = String(parseInt(filterBits, 2));

  const partition = binary.slice(SECTION_INDEXES.PARTITION_START, SECTION_INDEXES.PARTITION_END);

  const { companyBits, itemBits, companyDigits, itemDigits } = getPartitionBitSizes(partition);

  const companyPrefixEnd = SECTION_INDEXES.PARTITION_END + companyBits;
  const companyPrefix = String(parseInt(binary.slice(SECTION_INDEXES.PARTITION_END, companyPrefixEnd), 2));

  const itemReferenceEnd = companyPrefixEnd + itemBits;
  const itemReference = String(parseInt(binary.slice(companyPrefixEnd, itemReferenceEnd), 2));

  const serialNumber = String(parseInt(binary.slice(itemReferenceEnd), 2));

  const upc = getUPC(companyPrefix.padStart(companyDigits, "0"), itemReference.padStart(itemDigits, "0"));

  return {
    epc: tagHex.toUpperCase(),
    upc,
    filter,
    companyPrefix,
    itemReference,
    serialNumber
  };
};

// Gets GTIN-14 UPC
const getUPC = (paddedCompanyPrefix: string, paddedItemReference: string) => {
  const extraDigit = paddedItemReference[0];
  let upc = extraDigit + paddedCompanyPrefix + paddedItemReference.slice(1);

  upc += getCheckDigit(upc);

  return upc;
};

const getCheckDigit = (upc: string) => {
  let oddSum = 0;
  let evenSum = 0;

  for (let i = 0; i < upc.length; i++) {
    if (i % 2 === 0) {
      oddSum += parseInt(upc[i], 10);
    } else {
      evenSum += parseInt(upc[i], 10);
    }
  }

  const total = oddSum * 3 + evenSum;
  const checkDigit = (10 - (total % 10)) % 10;

  return checkDigit.toString();
};

const getPartitionBitSizes = (partitionValue: string) => {
  const PARTITION_TO_COMPANY_SIZES: Record<string, { bits: number; digits: number }> = {
    "000": {
      bits: 40,
      digits: 12
    },
    "001": {
      bits: 37,
      digits: 11
    },
    "010": {
      bits: 34,
      digits: 10
    },
    "011": {
      bits: 30,
      digits: 9
    },
    "100": {
      bits: 27,
      digits: 8
    },
    "101": {
      bits: 24,
      digits: 7
    },
    "110": {
      bits: 20,
      digits: 6
    }
  };

  const companySizes = PARTITION_TO_COMPANY_SIZES[partitionValue];

  if (!companySizes) {
    throw new SGTINError(`Invalid partition value: "0b${partitionValue}"`);
  }

  const totalBits = 44;
  const totalDigits = 13;

  const { bits: companyBits, digits: companyDigits } = companySizes;

  return {
    companyBits,
    companyDigits,
    itemBits: totalBits - companyBits,
    itemDigits: totalDigits - companyDigits
  };
};
