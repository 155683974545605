import React, { useEffect, useState } from "react";
import { TableSortLabel } from "@material-ui/core";
import HeaderWithSortIcon from "./components/header-with-sort-icon";
import { SORT_DIRECTION } from "../../data/constants";

export const useSortableHeader = (defaultOrder, defaultOrderProperty, useV2Header = false) => {
  const [order, setOrder] = useState(defaultOrder || "asc");
  const [orderBy, setOrderBy] = useState(defaultOrderProperty || "");

  useEffect(() => {
    setOrder(defaultOrder);
    setOrderBy(defaultOrderProperty);
  }, [defaultOrder, defaultOrderProperty]);

  const handleRequestSort = (property) => {
    return () => {
      if (property !== orderBy) {
        setOrderBy(property);
      } else {
        setOrder(order === SORT_DIRECTION.descending ? SORT_DIRECTION.ascending : SORT_DIRECTION.descending);
      }
    };
  };

  /**
   *
   * @param {RecordType[]} records
   * @param {'asc' | 'desc'} order
   * @param {string} orderBy
   * @returns {RecordType[]}
   */

  const getSortedData = (records, replacedOrderBy) => {
    const collator = new Intl.Collator("en", {
      numeric: true,
      sensitivity: "base"
    });

    const compare = (a, b) => {
      const aValue = a[replacedOrderBy || orderBy];
      const bValue = b[replacedOrderBy || orderBy];

      if (typeof aValue === "number" || typeof bValue === "number") {
        return (aValue || 0) - (bValue || 0);
      }

      return collator.compare(aValue || "", bValue || "");
    };

    const compareFn = (a, b) => {
      return order === SORT_DIRECTION.ascending ? compare(a, b) : compare(b, a);
    };

    return [...records.sort(compareFn)];
  };

  const getSortableHeader = (headerInfo, elementProps) => {
    const headerContainerClass = elementProps?.headerContainerClass;
    // eslint-disable-next-line no-param-reassign
    delete elementProps?.headerContainerClass;
    if (useV2Header) {
      return (
        <HeaderWithSortIcon
          key={headerInfo.id}
          className={headerContainerClass}
          isActive={orderBy === headerInfo.id}
          direction={order}
          onClick={handleRequestSort(headerInfo.id)}
          label={headerInfo.label}
        />
      );
    }
    return (
      <div
        key={headerInfo.id}
        style={{ flex: 1 }}
        className={headerContainerClass}
      >
        <TableSortLabel
          active={orderBy === headerInfo.id}
          direction={order}
          onClick={handleRequestSort(headerInfo.id)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...elementProps}
        >
          {headerInfo.label}
        </TableSortLabel>
      </div>
    );
  };

  return {
    order,
    orderBy,
    getSortedData,
    getSortableHeader
  };
};
