import React, { useMemo, useState } from "react";
import Style from "./PackageTable.module.css";
import { DetailsPageTable } from "../../../details-page";
import { getItemKeyForS3, getPackageData } from "../../utils/utils";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import StatusPopupComponent, { STATUS_OPTIONS } from "../../../../components/status-popup-component";
import { getItemFromS3 } from "../../../../common/Utilities";
import useMixpanelContext from "../../../../context/mixpanel-context";
import {
  PACKAGE_ORDER_DETAIL_PAGE_EVENT,
  PACKAGE_ORDER_DETAIL_PAGE_EVENT_STEPS
} from "../../../../constants/mixpanel-constant/packageOrderDetailPage";
import CONFIGURATION from "../../../../configuration.json";
import { PackageOrderData, PopupContent } from "../../data/types";
import usePackageOrderDetailConfigContext from "../../contexts/package-order-detail-config-context";
import { PACKAGE_DETAIL_PAGE_URL } from "../../data/constant";
import Status from "../../../../components/paginated-list-table-with-types/components/status";

const REPORT_BUCKET = CONFIGURATION.s3_report_configuration.bucket_name;
const ERROR_POPUP_DURATION_MS = 5000;

const PackageTable = ({ orderData }: { orderData: PackageOrderData }) => {
  const [reportClient] = useState(useXemelgoClient().getReportClient());
  const [popupContent, setPopupContent] = useState<PopupContent | null>(null);

  const { sendMixPanelEvent } = useMixpanelContext();
  const { pdfExportEnabled, packageTable, packageStateToDisplayedStatusMap } = usePackageOrderDetailConfigContext();

  const packageTableData = useMemo(() => {
    const packageData = getPackageData(orderData, packageStateToDisplayedStatusMap);
    const processedPackageData = packageData.map((eachPackage: any) => {
      const { status } = eachPackage || {};
      return {
        ...eachPackage,
        status: status ? <Status statusList={[status]} /> : null
      };
    });

    return processedPackageData;
  }, [orderData, packageStateToDisplayedStatusMap]);

  const getPackageDetailPageUrl = (item: any, headerId: string) => {
    if (headerId === "identifier") {
      return `${PACKAGE_DETAIL_PAGE_URL}${item.id}`;
    }
    return "";
  };

  const onExportBtnPress = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    try {
      setPopupContent({
        status: STATUS_OPTIONS.LOADING,
        message: "Downloading proof of delivery..."
      });

      let reportLink: string | undefined;
      const { identifier: orderIdentifier } = orderData || {};
      const reports = await reportClient.getReportByIdentifier(orderIdentifier);
      if (reports.length !== 0) {
        const { result = {} } = reports[0] || {};
        reportLink = result.reportLink;
      }

      if (!reportLink) {
        setPopupContent({
          status: STATUS_OPTIONS.ERROR,
          message: "Delivery report does not exist for this order."
        });
        return;
      }

      const key = getItemKeyForS3(reportLink);
      await getItemFromS3(REPORT_BUCKET, key, true);
      setPopupContent(null);
      sendMixPanelEvent(
        PACKAGE_ORDER_DETAIL_PAGE_EVENT,
        PACKAGE_ORDER_DETAIL_PAGE_EVENT_STEPS.DELIVERY_REPORT_EXPORT_SUCCESS
      );
    } catch (err) {
      sendMixPanelEvent(
        PACKAGE_ORDER_DETAIL_PAGE_EVENT,
        PACKAGE_ORDER_DETAIL_PAGE_EVENT_STEPS.DELIVERY_REPORT_EXPORT_FAILED
      );
      setPopupContent({
        status: STATUS_OPTIONS.ERROR,
        message: "Error downloading delivery report. Please try again or contact xemelgo support."
      });
    }
  };

  return (
    <div className={Style.table_container}>
      <div className={Style.table_title}>Associated Packages</div>
      <DetailsPageTable
        dataList={packageTableData}
        searchPlaceholder="Search by serial number"
        headers={packageTable.headers}
        textSearchEnabled
        exportEnabled={pdfExportEnabled}
        getTitleURLCallbackFn={getPackageDetailPageUrl}
        onExport={(_, event) => {
          onExportBtnPress(event);
        }}
        data-cy="package-table"
        paginatorLocation="top"
      />
      <div className={Style.centered_popup_container}>
        <StatusPopupComponent
          showPopup={!!popupContent}
          message={(popupContent && popupContent.message) || ""}
          status={popupContent && popupContent.status}
          durationMs={ERROR_POPUP_DURATION_MS}
          closeOnClickOutside
          onPopupClose={() => {
            setPopupContent(null);
          }}
        />
      </div>
    </div>
  );
};

export default PackageTable;
