import { useEffect, useMemo, useRef, useState } from "react";
import { PubSub } from "@aws-amplify/pubsub";
import { Auth } from "aws-amplify";
import AWS from "aws-sdk";
import configuration from "../../configuration.json";
import { READ_MODE_OPTIONS, READ_STATUS_OPTIONS } from "./data/constants";
import { getTagsSinceTimestamp } from "../../services/get-recent-tags-service";
import callEdgeOperation, { EDGE_OPERATION_MAP } from "../../utils/call-edge-operation";
import useAuthenticationContext from "../../context/authentication-context";
import useMixpanelContext from "../../context/mixpanel-context";
import { MOUNTED_READER_ACTION, MOUNTED_READER_ACTION_STEPS } from "../../constants/mixpanel-constant/mountedReader";
import { IoTTagMessage, ReaderOptions, ScannedTag } from "./data/types";
import { decodeSGTIN96Tag, SGTINInfo } from "../../utils/decode-sgtin-96-tag";
import filterIncomingTags from "./utils/filter-incoming-tags";

export const useMountedReader = (detectorVid: string, edgeApiUrl: string, readerOptions: ReaderOptions = {}) => {
  const { sendMixPanelEvent } = useMixpanelContext();
  const { cognitoUser } = useAuthenticationContext();
  const tagMapRef = useRef<Record<string, ScannedTag>>({});
  const tagsTableIntervalIdRef = useRef<NodeJS.Timeout>();
  const iotSubscriptionRef = useRef<{ unsubscribe: () => void } | null>(null);
  const detectorSerialToResetRef = useRef<string>();
  const [readStatus, setReadStatus] = useState(READ_STATUS_OPTIONS.NOT_READING);

  const parseDetectorVid = (vid: string) => {
    const splitVid = vid?.split("#") || [];
    return {
      detectorSerial: splitVid[0] || "",
      antennaId: splitVid[1] || ""
    };
  };

  useEffect(() => {
    grantPolicy();

    return () => {
      removePolicy();
    };
  }, []);

  const grantPolicy = async () => {
    const credential = await Auth.currentCredentials();
    const { identityId } = credential;
    const iot = new AWS.Iot({
      region: "us-west-2",
      credentials: credential
    });
    await iot
      .attachPolicy({
        policyName: configuration.iot_core_configuration.subscription_policy_name,
        target: identityId
      })
      .promise();
  };

  const removePolicy = async () => {
    const credential = await Auth.currentCredentials();
    const { identityId } = credential;
    const iot = new AWS.Iot({
      apiVersion: "2015-05-28",
      region: "us-west-2",
      credentials: credential
    });
    await iot
      .detachPolicy({
        policyName: configuration.iot_core_configuration.subscription_policy_name,
        target: identityId
      })
      .promise();
  };

  const { detectorSerial, antennaId } = useMemo(() => {
    return parseDetectorVid(detectorVid);
  }, [detectorVid]);

  const tenantId: string = useMemo(() => {
    return cognitoUser?.attributes?.["custom:tenantId"];
  }, [cognitoUser]);

  const edgeOperationParams = useMemo(() => {
    return {
      edgeApiUrl,
      deviceId: detectorSerial,
      tenantId,
      sendLogEvent: sendMixPanelEvent
    };
  }, [readerOptions, detectorSerial, tenantId]);

  useEffect(() => {
    return () => {
      resetMountedReader();
    };
  }, [detectorSerial, tenantId]);

  const subscribeToMountedReader = () => {
    if (!detectorSerial) {
      return;
    }

    switch (readerOptions.readMode) {
      case READ_MODE_OPTIONS.IOT_TOPIC:
        subscribeToIoTTopic();
        break;
      case READ_MODE_OPTIONS.TAGS_TABLE:
        subscribeToTagsTable();
        break;
      default:
    }
  };

  const subscribeToIoTTopic = () => {
    if (iotSubscriptionRef.current) {
      iotSubscriptionRef.current.unsubscribe();
    }

    const options = {
      clientId: `xemelgo-web-client-${cognitoUser.username}-${Date.now()}`
    };
    tagMapRef.current = {};

    iotSubscriptionRef.current = PubSub.subscribe(`devices/${detectorSerial}/tags`, options).subscribe({
      next: (data: IoTTagMessage) => {
        const { tags } = data.value;
        const filteredTags = filterIncomingTags(tags, antennaId, readerOptions.rssiFilter);

        filteredTags.forEach((tag) => {
          const { Name: vid = "", analytic } = tag;
          let sgtinInfo: SGTINInfo | undefined;
          try {
            sgtinInfo = decodeSGTIN96Tag(vid);
          } catch (error) {
            sgtinInfo = undefined;
          }

          tagMapRef.current[vid] = {
            vid: vid.toUpperCase(),
            analyticValue: analytic?.value,
            sgtinInfo
          };
        });
      },
      error: (error) => {
        sendMixPanelEvent(MOUNTED_READER_ACTION, MOUNTED_READER_ACTION_STEPS.ACTION_FAILED, {
          operation: "subscribeToIoTTopic",
          topic: `devices/${detectorSerial}/tags`,
          error: error?.error
        });
      }
    });
  };

  const subscribeToTagsTable = async () => {
    const {
      tagsTableQueryFrequencyInSeconds = 3,
      tagsTableApiUrl,
      tagsTableReadBufferMs = 2000,
      rssiFilter
    } = readerOptions;
    if (!tagsTableApiUrl) {
      throw new Error("Missing API URL is for reading tags.");
    }

    let lastQueryTimestamp = Date.now();

    tagMapRef.current = {};

    const intervalId = setInterval(async () => {
      const tags = await getTagsSinceTimestamp(
        tagsTableApiUrl,
        detectorSerial,
        lastQueryTimestamp - tagsTableReadBufferMs
      );

      const filteredTags = filterIncomingTags(tags, antennaId, rssiFilter);

      filteredTags.forEach((tag) => {
        const { Name: vid = "", analytic } = tag;

        const sgtinInfo = {
          epc: vid,
          upc: analytic?.upc || "",
          filter: "",
          companyPrefix: "",
          itemReference: "",
          serialNumber: analytic?.serial || ""
        };

        tagMapRef.current[vid] = {
          vid: vid.toUpperCase(),
          analyticValue: analytic?.value,
          sgtinInfo
        };
      });
      lastQueryTimestamp = Date.now();
    }, 1000 * tagsTableQueryFrequencyInSeconds);

    if (tagsTableIntervalIdRef.current !== undefined) {
      clearInterval(tagsTableIntervalIdRef.current);
    }
    tagsTableIntervalIdRef.current = intervalId;
  };

  const getReaderTagMap = (clearTags = false) => {
    if (!detectorVid) {
      return {};
    }

    const tagsMap = tagMapRef.current || {};

    if (clearTags) {
      tagMapRef.current = {};
    }

    return tagsMap;
  };

  const unsubscribeFromMountedReader = () => {
    if (iotSubscriptionRef.current) {
      iotSubscriptionRef.current.unsubscribe();
      iotSubscriptionRef.current = null;
    }

    if (tagsTableIntervalIdRef.current) {
      clearInterval(tagsTableIntervalIdRef.current);
      tagsTableIntervalIdRef.current = undefined;
    }
  };

  const startMountedReader = async (abortSignal: AbortSignal) => {
    detectorSerialToResetRef.current = detectorSerial;
    setReadStatus(READ_STATUS_OPTIONS.STARTING);
    subscribeToMountedReader();

    const promises = [];

    if (readerOptions.disableIngestion) {
      promises.push(
        callEdgeOperation({
          ...edgeOperationParams,
          operation: EDGE_OPERATION_MAP.DISABLE_INGESTION_RULE,
          maxRetries: 3,
          abortSignal
        })
      );
    }

    if (readerOptions.startReader) {
      promises.push(callEdgeOperation({ ...edgeOperationParams, operation: EDGE_OPERATION_MAP.START, abortSignal }));
    } else if (readerOptions.restartReader) {
      promises.push(
        callEdgeOperation({ ...edgeOperationParams, operation: EDGE_OPERATION_MAP.STOP, abortSignal }).then(() => {
          return callEdgeOperation({ ...edgeOperationParams, operation: EDGE_OPERATION_MAP.START, abortSignal });
        })
      );
    }

    await Promise.all(promises);
    setReadStatus(READ_STATUS_OPTIONS.IN_PROGRESS);
  };

  const pauseMountedReader = async (abortSignal: AbortSignal) => {
    setReadStatus(READ_STATUS_OPTIONS.PAUSING);
    unsubscribeFromMountedReader();

    if (readerOptions.pauseReader) {
      await callEdgeOperation({ ...edgeOperationParams, operation: EDGE_OPERATION_MAP.STOP, abortSignal });
    }

    setReadStatus(READ_STATUS_OPTIONS.PAUSED);
  };

  const resumeMountedReader = async (abortSignal: AbortSignal) => {
    setReadStatus(READ_STATUS_OPTIONS.STARTING);
    subscribeToMountedReader();

    if (readerOptions.pauseReader) {
      await callEdgeOperation({ ...edgeOperationParams, operation: EDGE_OPERATION_MAP.START, abortSignal });
    }

    setReadStatus(READ_STATUS_OPTIONS.IN_PROGRESS);
  };

  const stopMountedReader = async (abortSignal: AbortSignal) => {
    await resetMountedReader(abortSignal);
  };

  const resetMountedReader = async (abortSignal?: AbortSignal) => {
    if (!detectorSerialToResetRef.current) {
      return;
    }
    const detectorSerialToReset = detectorSerialToResetRef.current;
    detectorSerialToResetRef.current = "";

    setReadStatus(READ_STATUS_OPTIONS.STOPPING);
    unsubscribeFromMountedReader();

    const promises = [];

    if (readerOptions.disableIngestion) {
      promises.push(
        callEdgeOperation({
          ...edgeOperationParams,
          detectorSerial: detectorSerialToReset,
          operation: EDGE_OPERATION_MAP.ENABLE_INGESTION_RULE,
          maxRetries: 3,
          abortSignal
        })
      );
    }

    if (readerOptions.startReaderOnSubmit) {
      promises.push(
        callEdgeOperation({
          ...edgeOperationParams,
          detectorSerial: detectorSerialToReset,
          operation: EDGE_OPERATION_MAP.START,
          abortSignal
        })
      );
    } else if (readerOptions.stopReaderOnSubmit) {
      promises.push(
        callEdgeOperation({
          ...edgeOperationParams,
          detectorSerial: detectorSerialToReset,
          operation: EDGE_OPERATION_MAP.STOP,
          abortSignal
        })
      );
    }

    await Promise.all(promises);

    setReadStatus(READ_STATUS_OPTIONS.NOT_READING);
  };

  return {
    startMountedReader,
    pauseMountedReader,
    resumeMountedReader,
    stopMountedReader,
    getReaderTagMap,
    readStatus
  };
};
