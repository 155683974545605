import React, { useMemo, useEffect } from "react";
import Style from "./TableViewTypeControl.module.css";
import useTransferOrderTrackPageStateContext from "../../../../../../contexts/transfer-order-track-page-state-context";
import useTransferOrderTrackPageConfigContext from "../../../../../../contexts/transfer-order-track-page-config-context";

export const TableViewTypeControl = () => {
  const { selectedViewTypeId, setSelectedViewTypeId, setFreeTextSearchInputString, isSearchParamReady } =
    useTransferOrderTrackPageStateContext();

  const { tableViewTypeControl, isLoading: isConfigLoading } = useTransferOrderTrackPageConfigContext();

  const availableOptions = useMemo(() => {
    return tableViewTypeControl.transferOrder;
  }, [tableViewTypeControl]);

  useEffect(() => {
    if (
      !isConfigLoading &&
      isSearchParamReady &&
      !availableOptions.find(({ id }) => {
        return id === selectedViewTypeId;
      })
    ) {
      setSelectedViewTypeId(availableOptions?.[0]?.id);
    }
  }, [availableOptions, selectedViewTypeId, isConfigLoading, isSearchParamReady]);

  return (
    <div className={Style.container}>
      <p className={Style.label}>View:</p>
      {availableOptions.map(({ id, label }) => {
        return (
          <button
            key={id}
            type="button"
            className={`${Style.button} ${selectedViewTypeId === id && Style.button_selected}`}
            onClick={() => {
              setFreeTextSearchInputString("");
              setSelectedViewTypeId(id);
            }}
          >
            {label}
          </button>
        );
      })}
    </div>
  );
};
