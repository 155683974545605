import { XemelgoService } from "../../../../../../../../../../services/XemelgoService";
import { generateTag } from "../../../../../../../../../../utils/generate-tags";
import { ExistingTransferOrderContainer } from "../../../../data/types";

export const createContainer = async (
  newContainer: { identifier: string; vid?: string },
  containerTypeIdentifier = "Pallet"
): Promise<ExistingTransferOrderContainer> => {
  const inventoryClient = XemelgoService.getClient().getInventoryClient();
  const itemClient = XemelgoService.getClient().getItemClient();

  const tag = newContainer?.vid || generateTag("Container");

  const createPayload = {
    item_number: containerTypeIdentifier,
    item_identifier: newContainer?.identifier,
    class: "Container",
    tracker_serial: tag
  };

  const { createItemSet } = await inventoryClient.createItemSet([createPayload]);
  const createdContainer = (await itemClient.getItemsBySensorProfileVids(createItemSet))[0];

  return {
    itemId: createdContainer.itemId,
    itemTypeIdentifier: createdContainer.itemTypeIdentifier,
    identifier: createdContainer.identifier,
    itemTypeId: createdContainer.itemTypeId,
    vid: createdContainer.vid,
    sensorProfileId: createdContainer.sensorProfileId
  };
};
