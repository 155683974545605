import isValidHexadecimal from "../is-valid-hexadecimal";

const NUM_CONVERTABLE_HEX_DIGITS = 12;

export const hexToBinary = (hexStr: string) => {
  if (!isValidHexadecimal(hexStr)) {
    throw new Error(`Invalid hexadecimal string: "${hexStr}"`);
  }

  let binaryStr = "";

  // Convert 12 hex digits at a time to binary to avoid precision issues
  for (let i = 0; i < hexStr.length; i += NUM_CONVERTABLE_HEX_DIGITS) {
    const slice = hexStr.slice(i, i + NUM_CONVERTABLE_HEX_DIGITS);

    const binaryLength = slice.length * 4;
    const binary = parseInt(slice, 16).toString(2).padStart(binaryLength, "0");
    binaryStr += binary;
  }

  return binaryStr;
};
