import React, { useEffect, useState, useMemo } from "react";
import { ItemAssociationConfigContextProvider, useItemAssociationConfigContext } from "../../contexts";
import useUpdateItemConnections from "../../hooks/use-update-item-connections";
import useMixpanelContext from "../../../../context/mixpanel-context";
import {
  ADD_ITEM_ASSOCIATION,
  ADD_ITEM_ASSOCIATION_STEPS
} from "../../../../constants/mixpanel-constant/itemAssociation";
import { ReactComponent as InventoryIcon } from "../../../../assets/icons/inventory.svg";
import OnboardItemModal from "../../../../components/onboard-item-modal";
import SingleItemAssociationForm from "./features/single-item-association-form";
import StatusPopupComponent, { STATUS_OPTIONS } from "../../../../components/status-popup-component";
import { FieldValue, DropdownValue } from "../../../../components/multi-input-form";
import TabBar from "../../../../components/tab-bar";
import startsWithVowel from "../../../../utils/starts-with-vowel";
import { FormSection } from "../../data/types";
import { ITEM } from "../../data/constants";
import Style from "./AddItemAssociationModal.module.css";
import "../../ItemAssociationFeature.css";

const TAB_OPTIONS = {
  SINGLE: "single",
  BULK: "bulk"
};

type AddItemAssociationModalProps = {
  itemId: string;
  onClose: () => void;
};

type AddItemAssociationModalDefaultExportProps = AddItemAssociationModalProps & {
  solutionId: string;
};

export const AddItemAssociationModal: React.FC<AddItemAssociationModalProps> = ({ itemId, onClose }) => {
  const { itemLabel, updateItemAssociation, relationshipMap } = useItemAssociationConfigContext();
  const { verifyItemConnections, attachItems } = useUpdateItemConnections(relationshipMap);
  const { sendMixPanelEvent } = useMixpanelContext();

  const [selectedTab, setSelectedTab] = useState<string>(TAB_OPTIONS.SINGLE);
  const [formData, setFormData] = useState<Record<string, FieldValue>>({});
  const [submitStatus, setSubmitStatus] = useState<string>(STATUS_OPTIONS.NONE);
  const [statusMessage, setStatusMessage] = useState<string>("");
  const [errorMap, setErrorMap] = useState<Record<string, string>>({});

  useEffect(() => {
    sendMixPanelEvent(ADD_ITEM_ASSOCIATION, ADD_ITEM_ASSOCIATION_STEPS.ENTRY);
  }, []);

  const submitDisabled = useMemo<boolean>(() => {
    let unfilledFields = false;
    updateItemAssociation.forEach((section: FormSection) => {
      const { isRequired, id } = section;
      if (isRequired && !formData[id]) {
        unfilledFields = true;
      }
    });
    return unfilledFields || submitStatus === STATUS_OPTIONS.LOADING;
  }, [updateItemAssociation, formData, submitStatus]);

  const tabList = useMemo(() => {
    const tempTabList = [
      {
        id: TAB_OPTIONS.SINGLE,
        label: `Add ${startsWithVowel(itemLabel) ? "an" : "a"} ${itemLabel}`
      }
    ];

    return tempTabList;
  }, [TAB_OPTIONS, itemLabel]);

  const singleSubmit = async () => {
    try {
      const item = formData!.item as DropdownValue;
      const relationship = formData!.relationship as DropdownValue;
      const selectedId = item.value as string;
      const connection = relationship.value as string;

      const connectionErrorMessage = await verifyItemConnections(itemId, selectedId, connection);
      if (connectionErrorMessage) {
        const tempErrorMap = { ...errorMap };
        tempErrorMap[ITEM] = connectionErrorMessage;
        setErrorMap(tempErrorMap);
        setSubmitStatus(STATUS_OPTIONS.NONE);
        setStatusMessage("");
      } else {
        await attachItems(itemId, selectedId, connection);
        setSubmitStatus(STATUS_OPTIONS.SUCCESS);
        setStatusMessage("Successfully associated the items.");
        setTimeout(() => {
          setSubmitStatus(STATUS_OPTIONS.NONE);
          setStatusMessage("");
        }, 5000);
        setFormData({});

        sendMixPanelEvent(ADD_ITEM_ASSOCIATION, ADD_ITEM_ASSOCIATION_STEPS.SUBMISSION_SUCCESSFUL);
      }
    } catch (e) {
      sendMixPanelEvent(ADD_ITEM_ASSOCIATION, ADD_ITEM_ASSOCIATION_STEPS.SUBMISSION_FAILED, {
        error_message: JSON.stringify(e)
      });
      setSubmitStatus(STATUS_OPTIONS.ERROR);
      setStatusMessage("Failed to associate items. Please contact Xemelgo Support for assistance");
    }
  };

  const onSubmit = async () => {
    setSubmitStatus(STATUS_OPTIONS.LOADING);
    setStatusMessage("Updating...");
    if (selectedTab === TAB_OPTIONS.SINGLE) {
      await singleSubmit();
    }
  };

  return (
    <OnboardItemModal
      title={`Add Associated ${itemLabel}`}
      onSubmit={onSubmit}
      onClose={onClose}
      titleIconComponent={
        <div className={Style.title_icon}>
          <InventoryIcon
            width={25}
            height={25}
          />
        </div>
      }
      submitDisabled={submitDisabled}
      modalContainerClassName={Style.modal_container}
      popupComponent={
        <StatusPopupComponent
          showPopup={submitStatus !== STATUS_OPTIONS.NONE && !!statusMessage}
          message={statusMessage}
          status={submitStatus}
        />
      }
    >
      <div>
        <TabBar
          tabList={tabList}
          currentTabId={selectedTab}
          onTabClick={(newTab) => {
            setFormData({});
            setSelectedTab(newTab);
          }}
        />
        {selectedTab == TAB_OPTIONS.SINGLE && (
          <SingleItemAssociationForm
            onChange={(id, value) => {
              const tempErrorMap = { ...errorMap };
              const tempFormData = { ...formData };
              tempFormData[id] = value;
              delete tempErrorMap[id];
              setErrorMap(tempErrorMap);
              setFormData(tempFormData);
            }}
            formData={formData}
            errorMap={errorMap}
          />
        )}
      </div>
    </OnboardItemModal>
  );
};

export default ({ solutionId, itemId, onClose }: AddItemAssociationModalDefaultExportProps) => {
  return (
    <ItemAssociationConfigContextProvider solutionId={solutionId}>
      <AddItemAssociationModal
        itemId={itemId}
        onClose={onClose}
      />
    </ItemAssociationConfigContextProvider>
  );
};
