import React, { useState } from "react";
import Modal from "../../../../components/modal";
import SearchDropdown from "../../../../components/SearchDropdown/SearchDropdown";
import { useXemelgoClient } from "../../../../services/xemelgo-service";

type LocationOption = {
  value: string;
  label: string;
};

interface AddLocationModalProps {
  title?: string;
  itemIds: string[];
  locations: LocationOption[];
  onSubmit: (message: string, hasError: boolean) => void;
  onCancel: () => void;
}

export const AddLocationModal = ({
  title = "Add Location",
  itemIds,
  locations,
  onSubmit,
  onCancel
}: AddLocationModalProps) => {
  const [selectedLocation, setSelectedLocation] = useState<LocationOption>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const client = useXemelgoClient();

  const addLocation = async () => {
    if (!selectedLocation?.value) {
      return;
    }

    setIsSubmitting(true);
    const publishClient = client.getPublishClient();

    try {
      await publishClient.publishUserEvent(itemIds, selectedLocation.value);
    } catch (error) {
      onSubmit("Failed to add location. Please try again.", true);
      return;
    }

    onSubmit("Location added successfully.", false);
  };

  return (
    <Modal
      title={title}
      onCancel={onCancel}
      onConfirm={addLocation}
      confirmDisabled={isSubmitting || Object.keys(selectedLocation || {}).length === 0}
    >
      <p>Select a location</p>
      <SearchDropdown
        selectedItem={selectedLocation || {}}
        showIcon
        options={locations}
        onItemSelected={setSelectedLocation}
      />
    </Modal>
  );
};
