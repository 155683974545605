import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useAppConfigProvider, useConfigurationProvider } from "../../../../services/soft-cache-service";
import {
  DEFAULT_PACKAGE_STATE_TO_DISPLAYED_STATUS_MAP,
  DEFAULT_TRANSFER_ORDER_STATUS_TO_DISPLAYED_STATUS_MAP,
  ORDER_STATUS_TABLE_DEFAULT_HEADERS,
  PACKAGE_ORDER_DETAIL_CARD_DEFAULT_ATTRIBUTES,
  PACKAGE_TABLE_DEFAULT_HEADERS
} from "../../data/constant";

const PackageOrderDetailConfigContext = React.createContext();

const SOLUTION_ID = "package";
const DETAIL_PAGE_CONFIG = "packageOrderDetailPage";

const initialState = {
  isLoading: true,
  imageAttributes: ["image_path"],
  imageBucket: "xemelgo-customer-images",
  additionalTransferOrderFieldToQuery: [],
  detailCard: {
    orderAttributes: PACKAGE_ORDER_DETAIL_CARD_DEFAULT_ATTRIBUTES
  },
  overallStatusTable: {
    headers: ORDER_STATUS_TABLE_DEFAULT_HEADERS
  },
  packageTable: {
    headers: PACKAGE_TABLE_DEFAULT_HEADERS
  },
  transferOrderStatusToDisplayedStatusMap: DEFAULT_TRANSFER_ORDER_STATUS_TO_DISPLAYED_STATUS_MAP,
  packageStateToDisplayedStatusMap: DEFAULT_PACKAGE_STATE_TO_DISPLAYED_STATUS_MAP,
  pdfExportEnabled: false
};

export const usePackageOrderDetailConfigContext = () => {
  return useContext(PackageOrderDetailConfigContext);
};

export const PackageOrderDetailConfigContextProvider = ({ children }) => {
  const fullConfig = useConfigurationProvider();
  const packageOrderDetailConfigProvider = useAppConfigProvider(SOLUTION_ID);
  const detailConfig = packageOrderDetailConfigProvider.getValue(DETAIL_PAGE_CONFIG, "object") || {};
  const [isLoading, setIsLoading] = useState(initialState.isLoading);
  const [imageBucket, setImageBucket] = useState(initialState.imageBucket);
  const [imageAttributes, setImageAttributes] = useState(initialState.imageAttributes);
  const [orderAttributes, setOrderAttributes] = useState(initialState.orderAttributes);
  const [additionalTransferOrderFieldToQuery, setAdditionalTransferOrderFieldToQuery] = useState(
    initialState.additionalTransferOrderFieldToQuery
  );
  const [overallStatusTable, setOverallStatusTable] = useState(initialState.overallStatusTable);
  const [packageTable, setPackageTable] = useState(initialState.packageTable);
  const [pdfExportEnabled, setPdfExportEnabled] = useState(initialState.pdfExportEnabled);
  const [packageStateToDisplayedStatusMap, setPackageStateToDisplayedStatusMap] = useState(
    initialState.packageStateToDisplayedStatusMap
  );
  const [transferOrderStatusToDisplayedStatusMap, setTransferOrderStatusToDisplayedStatusMap] = useState(
    initialState.transferOrderStatusToDisplayedStatusMap
  );

  useEffect(() => {
    setIsLoading(true);
    const {
      pdfExportEnabled: newPdfExportEnabled,
      imageAttributes: newImageAttributes,
      additionalTransferOrderFieldToQuery: newAdditionalTransferOrderFieldToQuery,
      detailCard: newDetailCard,
      packageStateToDisplayedStatusMap: newPackageStateToDisplayedStatusMap,
      overallStatusTable: newOverallStatusTable,
      packageTable: newPackageTable
    } = detailConfig || {};
    const {
      transferOrderStatusToDisplayedStatusMap: newTransferOrderStatusToDisplayedStatusMap,
      orderAttributes: newOrderAttributes
    } = newDetailCard || {};
    setImageAttributes(newImageAttributes || initialState.imageAttributes);
    setAdditionalTransferOrderFieldToQuery(
      newAdditionalTransferOrderFieldToQuery || initialState.additionalTransferOrderFieldToQuery
    );
    setOrderAttributes(newOrderAttributes || PACKAGE_ORDER_DETAIL_CARD_DEFAULT_ATTRIBUTES);
    setPdfExportEnabled(newPdfExportEnabled || initialState.pdfExportEnabled);
    setOverallStatusTable({
      headers: newOverallStatusTable?.attributes || ORDER_STATUS_TABLE_DEFAULT_HEADERS,
      ...(newOverallStatusTable || {})
    });
    setPackageTable({
      headers: newPackageTable?.attributes || PACKAGE_TABLE_DEFAULT_HEADERS,
      ...(newPackageTable || {})
    });
    setTransferOrderStatusToDisplayedStatusMap(
      newTransferOrderStatusToDisplayedStatusMap || initialState.transferOrderStatusToDisplayedStatusMap
    );
    setPackageStateToDisplayedStatusMap(
      newPackageStateToDisplayedStatusMap || initialState.packageStateToDisplayedStatusMap
    );

    setIsLoading(false);
  }, [detailConfig]);

  useEffect(() => {
    const newImageBucket = fullConfig?.config?.infrastructure?.imageStorage?.BUCKET;
    setImageBucket(newImageBucket);
  }, [fullConfig]);

  return (
    <PackageOrderDetailConfigContext.Provider
      value={{
        isLoading,
        imageAttributes,
        imageBucket,
        orderAttributes,
        additionalTransferOrderFieldToQuery,
        pdfExportEnabled,
        packageTable,
        overallStatusTable,
        transferOrderStatusToDisplayedStatusMap,
        packageStateToDisplayedStatusMap
      }}
    >
      {children}
    </PackageOrderDetailConfigContext.Provider>
  );
};

PackageOrderDetailConfigContextProvider.defaultProps = {
  children: null
};

PackageOrderDetailConfigContextProvider.propTypes = {
  children: PropTypes.element
};
