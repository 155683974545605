import { EXCLUDED_ANALYTIC_VALUES_SET } from "../../data/constants";
import { RSSIFilter, TagType } from "../../data/types";

export const filterIncomingTags = (tags: TagType[], expectedAntennaId: string, rssiFilter?: RSSIFilter) => {
  return tags.filter((tag) => {
    const { analytic, AntennaId, PeakRssi } = tag;

    const validAnalyticValue = !EXCLUDED_ANALYTIC_VALUES_SET.has(analytic?.value);
    const validAntennaId = !expectedAntennaId || !AntennaId || String(AntennaId) === expectedAntennaId;
    const validRssi = !PeakRssi || isValidRssi(PeakRssi, rssiFilter);

    return validAnalyticValue && validAntennaId && validRssi;
  });
};

const isValidRssi = (rssi: number, rssiFilter: RSSIFilter = {}) => {
  if (rssiFilter.min && rssi < rssiFilter.min) {
    return false;
  }
  if (rssiFilter.max && rssi > rssiFilter.max) {
    return false;
  }
  return true;
};
