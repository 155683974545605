import React from "react";
import { Route, Switch } from "react-router-dom";
import ManagementDashboardApp from "apps/management-dashboard-app/ManagementDashboardApp";
import AlertsPage from "../pages/alert/AlertsPage";
import UserProfilePage from "../pages/users/UserProfilePage";
import { UnauthorizedPage } from "../pages/error/UnauthorizedPage";
import AuthorizedProcessAppRoute from "./AuthorizedProcessAppRoute";
import InventoryRequestsPage from "../pages/inventory-requests/InventoryRequestsPage";
import WorkOrderStackNavigation from "../navigations/work-order-stack-navigation";
import ShipmentsApp from "../apps/shipments-app/ShipmentsApp";
import { MyFacility } from "../apps/my-facility";
import UsersApp from "../apps/users-app/UsersApp";
import KioskStackNavigation from "../navigations/kiosk-stack-navigation";
import GoodsReceiptApp from "../apps/goods-receipt-app/GoodsReceiptApp";
import ReportsApp from "../apps/reports-app/ReportsApp";
import PurchaseOrderApp from "../apps/purchase-order-app/PurchaseOrderApp";
import ItemTypesManagementApp from "../apps/item-types-management-app/ItemTypesManagementApp";
import { useConfigurationProvider } from "../services/soft-cache-service";
import { useRoleAuthorization } from "../apps/hooks/useRoleAuthorization";
import ScheduleManagementApp from "../apps/schedule-order-app/ScheduleOrderApp";
import PackageStackNavigation from "../navigations/package-stack-navigation";
import InventoryDashboardFeature from "../features/inventory-dashboard-feature/InventoryDashboardFeature";
import AssetStackNavigation from "../navigations/asset-stack-navigation";
import AssetMapStackNavigation from "../navigations/asset-map-stack-navigation";
import InventoryStackNavigation from "../navigations/inventory-stack-navigation";
import TransferOrderStackNavigation from "../navigations/transfer-order-stack-navigation";
// RedirectPath is on temporary used before dashboard page is implemented
const Routes = () => {
  const { settingOptions, solutionOptions } = useConfigurationProvider().config.webClient;

  const { getRoles } = useRoleAuthorization({
    config: [...settingOptions, ...solutionOptions]
  });

  return (
    <Switch>
      <Route
        path="/error/authorized"
        component={UnauthorizedPage}
      />
      <AuthorizedProcessAppRoute
        path="/work-order"
        component={WorkOrderStackNavigation}
        authorizedRoles={getRoles("order")}
      />
      <AuthorizedProcessAppRoute
        path="/shipments"
        component={ShipmentsApp}
        authorizedRoles={getRoles("shipments")}
      />
      <AuthorizedProcessAppRoute
        path="/inventory"
        component={InventoryStackNavigation}
        authorizedRoles={getRoles("inventory")}
      />
      <AuthorizedProcessAppRoute
        path="/check-out"
        component={KioskStackNavigation}
        authorizedRoles={getRoles("inventory")}
      />
      <AuthorizedProcessAppRoute
        path="/goods-receipt"
        component={GoodsReceiptApp}
        authorizedRoles={getRoles("inventory")}
      />
      <AuthorizedProcessAppRoute
        path="/users"
        component={UsersApp}
        authorizedRoles={getRoles("users")}
      />
      <AuthorizedProcessAppRoute
        path="/my-facility"
        component={MyFacility}
        authorizedRoles={getRoles("my-facility")}
      />
      <AuthorizedProcessAppRoute
        path="/alerts"
        component={AlertsPage}
        authorizedRoles={getRoles("alerts")}
      />
      <AuthorizedProcessAppRoute
        path="/profile"
        component={UserProfilePage}
      />
      <AuthorizedProcessAppRoute
        path="/asset"
        component={AssetStackNavigation}
        authorizedRoles={getRoles("asset")}
      />
      <AuthorizedProcessAppRoute
        path="/asset-map"
        component={AssetMapStackNavigation}
        authorizedRoles={getRoles("asset-map")}
      />

      <AuthorizedProcessAppRoute
        path="/inventory-requests"
        component={InventoryRequestsPage}
        authorizedRoles={getRoles("inventory-requests")}
      />
      <AuthorizedProcessAppRoute
        path="/reports"
        component={ReportsApp}
        authorizedRoles={getRoles("reports")}
      />
      <AuthorizedProcessAppRoute
        path="/purchase-order"
        component={PurchaseOrderApp}
        authorizedRoles={getRoles("purchase-order")}
      />
      <AuthorizedProcessAppRoute
        path="/item-types-management"
        component={ItemTypesManagementApp}
        authorizedRoles={getRoles("item-types-management")}
      />
      <AuthorizedProcessAppRoute
        path="/management-dashboard"
        component={ManagementDashboardApp}
        authorizedRoles={getRoles("order")}
      />
      <AuthorizedProcessAppRoute
        path="/scheduling-management"
        component={ScheduleManagementApp}
        authorizedRoles={getRoles("schedule-order")}
      />

      <AuthorizedProcessAppRoute
        path="/scheduling-management"
        component={ScheduleManagementApp}
        authorizedRoles={getRoles("schedule-order")}
      />
      <AuthorizedProcessAppRoute
        path="/package"
        component={PackageStackNavigation}
        authorizedRoles={getRoles("package")}
      />
      <AuthorizedProcessAppRoute
        path="/inventory-dashboard"
        component={InventoryDashboardFeature}
        authorizedRoles={getRoles("inventory-dashboard")}
      />
      <AuthorizedProcessAppRoute
        path="/transfer-order"
        component={TransferOrderStackNavigation}
        authorizedRoles={getRoles("transfer-order")}
      />
    </Switch>
  );
};

export default Routes;
