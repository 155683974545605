/**
 * Finds a value inside a nested object using a path like "user.address.city".
 * Handles special cases like optional chaining (?.) and arrays ([index]).
 *
 * @param obj - The object to search in.
 * @param path - The path to the value, using dots (e.g., "user.address.city").
 * @returns The value at the path, or `undefined` if the path doesn't exist.
 */
export const resolveNestedKey = (obj: any, path: string) => {
  // Clean up the path to make it easier to work with
  // 1. Replace "?." with "." (optional chaining)
  // 2. Change "[index]" to ".index" (array access)
  const keys = path
    .replace(/\?\./g, ".")
    .replace(/\[(\d+)\]/g, ".$1")
    .split(".")
    .filter(Boolean);

  // Go through each part of the path to find the value
  return keys.reduce((acc, key) => {
    // If the current part is undefined or null, stop searching
    if (acc === undefined || acc === null) {
      return undefined;
    }
    // Move to the next part of the path
    return acc[key];
  }, obj);
};

/**
 * Replaces placeholders like `${user.name}` in a string with real values from an object.
 *
 * @param template - The string with placeholders (e.g., "Hello, ${user.name}!").
 * @param variables - The object with values to replace the placeholders.
 * @returns The string with placeholders replaced by actual values.
 */
export const interpolateTemplateString = (template: string, variables: any): string => {
  return template.replace(/\$\{([^}]+)\}/g, (_, keyPath) => {
    // Clean up the key path by removing "?." (optional chaining)
    const cleanedKeyPath = keyPath.replace(/\?\./g, ".");
    // Find the value for this key path
    const resolvedValue = resolveNestedKey(variables, cleanedKeyPath.trim());
    // Return the value, or an empty string if it's undefined
    return resolvedValue !== undefined ? resolvedValue : "";
  });
};

/**
 * Finds a value in an object using a resolved template string as the path.
 * For example, if the resolved template is "user.name", it will return the name.
 *
 * @param resolvedTemplate - The resolved template string (e.g., "user.name").
 * @param variables - The object to search for the value.
 * @returns The final value, or `undefined` if the path doesn't exist.
 */
export const interpolateTemplateValue = (resolvedTemplate: string, variables: any): any => {
  return resolveNestedKey(variables, resolvedTemplate.trim());
};

/**
 * Combines the above functions to replace placeholders in a template and resolve the final value.
 *
 * @param templateString - The template string with placeholders (e.g., "${user.name}").
 * @param data - The object with values to replace placeholders and resolve the final value.
 * @returns The final value after replacing placeholders and resolving the path.
 */
export const interpolateTemplate = (templateString: string, data: any): any => {
  // First, replace placeholders in the template string with values from the data object
  const resolvedTemplate = interpolateTemplateString(templateString, data);
  // Then, use the resolved template as a path to find the final value
  return interpolateTemplateValue(resolvedTemplate, data);
};
