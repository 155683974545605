import React from "react";
import ScreenFrame from "../../components/screen-frame";
import useDetailsPageConfigContext from "./contexts/details-page-config-context";
import Style from "./DetailsPage.module.css";
import DetailsPageTabs from "./features/details-page-tabs";
import DetailsCard from "./features/details-card";
import useDetailsPageStateContext, { DetailsPageStateContextProvider } from "./contexts/details-page-state-context";
import { DetailsPageProps } from "./data/types";
import StatusPopupComponent, { STATUS_OPTIONS } from "../../components/status-popup-component";
import ErrorScreen from "./components/error-screen";

const DetailsPageFeature = ({
  defaultTitle,
  editImagesEnabled,
  moreOptions = [],
  primaryColor,
  secondaryColor,
  iconComponent: IconComponent,
  onEditDetailsSave,
  onEditImagesSave
}: DetailsPageProps) => {
  const { detailsCardData, popupMessage, popupStatus, setPopupStatus, showErrorScreen } = useDetailsPageStateContext();
  const { customText } = useDetailsPageConfigContext();

  return (
    <ScreenFrame
      title={customText.title || defaultTitle}
      titleIconComponent={
        <IconComponent
          width={25}
          height={25}
          style={{ color: primaryColor }}
        />
      }
      color={primaryColor}
      secondaryColor={secondaryColor}
      hasBackButton
    >
      {!showErrorScreen ? (
        <div className={Style.content_container}>
          <div className={Style.detail_card}>
            <DetailsCard
              moreOptions={moreOptions}
              editImagesEnabled={editImagesEnabled}
              detailsCardData={detailsCardData}
              onEditDetailsSave={onEditDetailsSave}
              onEditImagesSave={onEditImagesSave}
            />
          </div>
          <div className={Style.details_page_tabs}>
            <DetailsPageTabs />
          </div>
        </div>
      ) : (
        <ErrorScreen />
      )}
      {popupStatus && popupStatus !== STATUS_OPTIONS.NONE && (
        <StatusPopupComponent
          status={popupStatus}
          message={popupMessage}
          onPopupClose={() => {
            setPopupStatus(STATUS_OPTIONS.NONE);
          }}
          closeOnClickOutside
          isCenterPopup
          showPopup
        />
      )}
    </ScreenFrame>
  );
};

export const DetailsPage = (props: DetailsPageProps) => {
  const { featureMap, onQueryDetails, path } = props;
  return (
    <DetailsPageStateContextProvider
      solutionFeatureMap={featureMap}
      onQueryDetails={onQueryDetails}
      path={path}
    >
      <DetailsPageFeature {...props} />
    </DetailsPageStateContextProvider>
  );
};
