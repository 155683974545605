import React, { useMemo, useState, useEffect, useRef } from "react";
import { useStore } from "react-hookstore";
import Skeleton from "react-loading-skeleton";
import { getStackedXemelgoLogo } from "../../../../../../../../common/Utilities";
import Style from "../../../../../../Kiosk.module.css";
import ListTable from "../../../../../../../../components/ListTable/ListTable";
import { ReactComponent as ChevronRightBlue } from "../../../../../../../../assets/icons/chevron-right-blue.svg";
import {
  TABLE_COLUMN_SIZE_MAP,
  ROW_COUNT_ATTRIBUTE,
  CLEAR_BUTTON_SECTION,
  CLEAR_BUTTON_ID
} from "../../../../../../data/constants";
import useKioskConfigContext from "../../../../../../contexts/kiosk-config-context";
import useKioskStateContext from "../../../../../../contexts/kiosk-state-context";
import { fullScreenModeStore } from "../../../../../../../../state-managements/stores/full-screen-mode-store";
import EpcTable from "./components/epc-table";
import Status from "../../../../../../../../components/paginated-list-table-with-types/components/status";
import { ItemTypeReport } from "../../../../../../data/types";

interface ItemTableProps {
  onClearRow: (id: string) => void;
  onClearItem: (id: string, epc: string) => void;
}

type Header = {
  attributes: {
    id: keyof ItemTypeReport;
    label?: string;
    type?: string;
  }[];
  label?: string;
  size?: string;
  id?: string;
};

export const ItemTable = ({ onClearRow = () => {}, onClearItem = () => {} }: ItemTableProps) => {
  const [isFullScreen] = useStore(fullScreenModeStore);
  const [lastUpdatedTimestamp, setLastUpdatedTimestamp] = useState(0);
  const [expandedRowIdentifier, setExpandedRowIdentifier] = useState("");

  const { tableSections, rowBackgroundColor, tableTextStyle, epcTableHeaders } = useKioskConfigContext();
  const { searchInput, itemTypeReports, itemByTag, setItemByTag, loading } = useKioskStateContext();
  const listTableRef = useRef(null);

  const headers: Header[] = useMemo(() => {
    return [...tableSections, CLEAR_BUTTON_SECTION];
  }, [tableSections]);

  const filterFunction = (input: string, each: ItemTypeReport) => {
    const lowerCaseInput = input.toLowerCase();
    let match = false;
    const keysToCheckList = tableSections.reduce((accumulator: string[], section) => {
      const { attributes = [] } = section;
      const currentAttributes: string[] = [];
      attributes.forEach((attribute) => {
        if (attribute.type !== "image") {
          currentAttributes.push(attribute.id);
        }
      });
      return [...accumulator, ...currentAttributes];
    }, []);

    keysToCheckList.forEach((eachKey) => {
      const eachValue = each[eachKey as keyof ItemTypeReport];
      if (eachValue && String(eachValue).toLowerCase().includes(lowerCaseInput)) {
        match = true;
      }
    });

    return match;
  };

  const filteredReports = useMemo(() => {
    if (itemTypeReports) {
      return itemTypeReports.filter((row) => {
        return filterFunction(searchInput, row);
      });
    }
    return [];
  }, [itemTypeReports, searchInput]);

  useEffect(() => {
    const newLatestTimestamp = filteredReports[filteredReports.length - 1]?.lastUpdatedTimestamp || 0;
    if (listTableRef.current && newLatestTimestamp > lastUpdatedTimestamp) {
      setLastUpdatedTimestamp(newLatestTimestamp);
      // @ts-ignore
      listTableRef.current.scrollToItem(filteredReports.length - 1);
    }
  }, [filteredReports, lastUpdatedTimestamp]);

  const renderHeader = (header: Header) => {
    const { size = "default", label, id } = header;
    const flexSize = TABLE_COLUMN_SIZE_MAP[size];
    return (
      <li
        key={label || id || `header-${size}`}
        className={Style.header_item}
        style={{
          flex: flexSize
        }}
      >
        {header?.label || ""}
      </li>
    );
  };

  const renderRow = (row: ItemTypeReport, index: number) => {
    const { id, epcToItemMap = {}, identifier } = row;
    const items = Object.values(epcToItemMap);

    return (
      <div
        className={Style.table_row_container}
        data-cy={`kiosk-table-row-${id}`}
        key={identifier}
      >
        <div
          className={Style.row_container}
          style={{
            backgroundColor: rowBackgroundColor
          }}
        >
          {renderSections(row, index)}
        </div>
        {expandedRowIdentifier === identifier && items.length > 0 && (
          <div className={Style.epc_table_container}>
            <EpcTable
              items={items}
              onClearItem={(epc) => {
                onClearItem(id, epc);
              }}
              headers={epcTableHeaders}
            />
          </div>
        )}
      </div>
    );
  };

  const onClear = (id: string) => {
    const newItemByTag = { ...itemByTag };
    const reportIndex = itemTypeReports.findIndex((report) => {
      return report.id === id;
    });
    const report = itemTypeReports[reportIndex];

    const epcs = Object.keys(report.epcToItemMap);
    epcs.forEach((epc) => {
      delete newItemByTag[epc];
    });

    setItemByTag(newItemByTag);
    onClearRow(id);
  };

  const renderSections = (row: ItemTypeReport, index: number) => {
    return headers.map((section, j) => {
      const { attributes = [], size = "default" } = section;
      const flexSize = TABLE_COLUMN_SIZE_MAP[size];
      return (
        <div
          style={{
            ...tableTextStyle,
            backgroundColor: rowBackgroundColor,
            flex: flexSize
          }}
          className={Style.section_container}
          key={`${row.id}-${section.id || j}`}
        >
          <div className={Style.section_content_container}>
            {attributes.map((attribute) => {
              const { id, label, type } = attribute;
              let value = row[id];

              if (label === "Color" && row.color_desc_ts) {
                value = `${row[id]}-${row.color_desc_ts}`;
              }

              if (type === "image") {
                const imageSrc = typeof row[id] === "string" ? row[id] : getStackedXemelgoLogo("dark");
                return (
                  <img
                    alt={label}
                    key={`${row.id}-${row[id]}`}
                    src={imageSrc}
                    height="100px"
                    width="100px"
                    className={Style.row_image}
                  />
                );
              }

              switch (id) {
                case ROW_COUNT_ATTRIBUTE:
                  return (
                    <div
                      key={`${row.id}-${id}`}
                      style={{
                        ...tableTextStyle,
                        backgroundColor: rowBackgroundColor,
                        flex: 1
                      }}
                    >
                      {index + 1}
                    </div>
                  );
                case CLEAR_BUTTON_ID:
                  return (
                    <button
                      key={`${row.id}-${id}`}
                      type="button"
                      onClick={() => {
                        onClear(row.id);
                      }}
                      className={Style.clear_button}
                    >
                      Clear
                    </button>
                  );
                case "status":
                  return (
                    <Status
                      key={`${row.id}-${id}`}
                      statusList={[value] as { label: string; backgroundColor: string }[]}
                    />
                  );
                case "showEpcsButton":
                  const isExpanded = expandedRowIdentifier === row.identifier;
                  const hasEpcs = Object.values(row.epcToItemMap).length > 0;
                  return (
                    hasEpcs && (
                      <div
                        key={`${row.id}-${id}`}
                        onClick={() => {
                          setExpandedRowIdentifier(isExpanded ? "" : row.identifier);
                        }}
                        className={Style.expand_button}
                      >
                        <ChevronRightBlue className={`${Style.expand_icon} ${isExpanded ? Style.rotated_icon : ""}`} />
                        {isExpanded ? "Hide EPCs" : "View EPCs"}
                      </div>
                    )
                  );
                default:
                  return (
                    <div
                      className={`${Style.section_text_container} ${
                        !label && value === undefined ? Style.empty_section : ""
                      }`}
                      key={`${row.id}-${label || id}`}
                    >
                      {label && <p className={Style.section_label}>{`${label}:`}</p>}
                      <p className={Style.section_value}>{value}</p>
                    </div>
                  );
              }
            })}
          </div>
        </div>
      );
    });
  };

  return (
    <div className={isFullScreen ? Style.fullscreen_table_container : Style.table_container}>
      <ListTable
        // @ts-ignore
        header={headers}
        tableClassName={Style.table}
        headerContainerClassName={Style.header}
        data={filteredReports}
        renderHeader={renderHeader}
        renderItem={renderRow}
        renderEmptyList={() => {
          return loading ? (
            <Skeleton
              className={Style.loading_table_row}
              count={3}
            />
          ) : (
            <div className={Style.empty_items_container}>No Matching Results</div>
          );
        }}
        ref={listTableRef}
      />
    </div>
  );
};
