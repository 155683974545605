import React, { useMemo } from "react";
import { DEFAULT_TRANSFER_ORDER_ITEMS_TABLE_HEADERS } from "./data/constants";
import { Header, Item } from "../../../../components/paginated-list-table-with-types";
import { DetailsPageTable } from "../../../details-page";
import { ITEM_CLASS_TO_URL_PATH } from "../transfer-order-item-types-table/data/constants";
import { TransferOrderItem } from "../../data/types";

type TransferOrderItemsTableOptions = {
  csvFileName?: string;
  headers?: Header[];
};

export const TransferOrderItemsTable = ({
  items,
  transferOrderIdentifier,
  options
}: {
  items: TransferOrderItem[];
  transferOrderIdentifier: string;
  options: TransferOrderItemsTableOptions;
}) => {
  const { csvFileName = "Associated Item Details", headers = DEFAULT_TRANSFER_ORDER_ITEMS_TABLE_HEADERS } =
    options || {};

  const transferOrderItems: Item[] = useMemo(() => {
    return items.reduce((acc: Item[], item) => {
      const { class: itemClass } = item || {};

      if (itemClass !== "Container") {
        acc.push(item);
      }

      return acc;
    }, []);
  }, [items]);

  return (
    <DetailsPageTable
      paginatorLocation="top"
      headers={headers}
      dataList={transferOrderItems}
      csvFileName={`${csvFileName} - ${transferOrderIdentifier}`}
      getTitleURLCallbackFn={(item: any, headerId: string) => {
        if (headerId.includes("container")) {
          return item.container_id ? `/${ITEM_CLASS_TO_URL_PATH.Inventory}/detail?container=${item.container_id}` : "";
        }

        if (headerId.includes("itemType_")) {
          return item.itemType_id ? `/item-types-management/detail?id=${item.itemType_id}` : "";
        }

        const path = ITEM_CLASS_TO_URL_PATH[item.class];
        if (path && item.id) {
          return `/${path}/item/detail?itemId=${item.id}`;
        }

        return "";
      }}
      sortIdReplacementMap={{
        status: "statusSortValue"
      }}
      numItemsPerPage={5}
    />
  );
};
