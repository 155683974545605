import React, { useEffect, useMemo, useRef, useState } from "react";
import { Event as CalendarIcon } from "@material-ui/icons";
import Skeleton from "react-loading-skeleton";
import { ItemRoute } from "@xemelgo/x-client";
import { getFormattedDate, TIME_IN_MS } from "../../common/Utilities";
import { useXemelgoAppsyncClient } from "../../services/xemelgo-appsync-service";
import ProgressGraph from "../../components/RouteTracker/ProgressGraph";
import PaginatedListTableWithTypes from "../../components/paginated-list-table-with-types";
import DateRangePicker from "../../components/data-range-picker";
import { DEFAULT_ROUTE_OPTIONS, DEFAULT_ROUTE_TABLE_HEADERS } from "./data/constants";
import { ALL_TIME_ID, ALL_TIME_LABEL, QUICK_FILTER_OPTIONS } from "../../components/data-range-picker/data/constants";
import Style from "./ItemRouteFeature.module.css";
import useOnClickOutside from "../../hooks/use-on-click-outside";
import StatusPopupComponent from "../../components/status-popup-component";
import { STATUS_OPTIONS } from "../../components/status-popup-component/data/constants";
import AddLocationModal from "./features/add-location-modal";
import { XemelgoService } from "../../services/XemelgoService";
import { ProcessedItemRoute, RouteOptions } from "./data/types";

interface ItemRouteFeatureProps {
  itemId: string;
  itemIdentifier: string;
  currentLocationId?: string;
  onLocationAdded?: () => void;
  solution: "asset" | "inventory";
  options?: RouteOptions;
}

export const ItemRouteFeature = ({
  itemId,
  itemIdentifier,
  currentLocationId,
  onLocationAdded = () => {},
  solution,
  options = DEFAULT_ROUTE_OPTIONS
}: ItemRouteFeatureProps) => {
  const itemAppsyncClient = useXemelgoAppsyncClient().getItemClient();

  const {
    defaultRouteTimeframeMs = TIME_IN_MS.WEEKS,
    timeFormat = "MMM D, YYYY h:mm A",
    routeTableHeaders = DEFAULT_ROUTE_TABLE_HEADERS,
    dateRangeQuickFilterOptions = QUICK_FILTER_OPTIONS,
    locationCategories = [],
    allowAddLocation = true
  } = options;

  const popupRef = useRef(null);
  const [nowTimestamp] = useState<number>(Date.now());
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<Date>(new Date(nowTimestamp - defaultRouteTimeframeMs));
  const [endDate, setEndDate] = useState<Date>(new Date(nowTimestamp));
  const [itemRoute, setItemRoute] = useState<ProcessedItemRoute[]>([]);
  const [showDateRangePicker, setShowDateRangePicker] = useState<boolean>(false);
  const [isAllTimeSelected, setIsAllTimeSelected] = useState<boolean>(false);
  const [showAddLocationModal, setShowAddLocationModal] = useState<boolean>(false);
  const [availableLocations, setAvailableLocations] = useState<{ key: string; label: string; value: string }[]>([]);
  const [popup, setPopup] = useState<{
    status: string;
    message: string;
  }>();

  useOnClickOutside(popupRef, () => {
    setShowDateRangePicker(false);
  });

  useEffect(() => {
    fetchItemRoute(startDate.getTime(), endDate.getTime());
  }, []);

  useEffect(() => {
    fetchAvailableLocations();
  }, [currentLocationId]);

  const fetchItemRoute = async (startTimestamp?: number, endTimestamp?: number) => {
    setIsLoading(true);
    setPopup({
      status: STATUS_OPTIONS.NONE,
      message: ""
    });

    let prevStep: ItemRoute;

    try {
      const isEndDateNow = !endTimestamp || endTimestamp >= nowTimestamp;
      const newItemRoute = await itemAppsyncClient.getItemRoute(solution, itemIdentifier, startTimestamp, endTimestamp);

      const processedItemRoute = newItemRoute.reverse().map((step, i) => {
        const status = {
          done: true,
          processing: !prevStep?.startDate
        };

        let timeToNext;
        if (prevStep?.startDate) {
          timeToNext = prevStep.startDate - step.endDate;
        }
        prevStep = step;

        // Hide duration for the most recent step if end date has been changed because duration value is wrong
        const hideDuration = !isEndDateNow && i === 0;

        return {
          ...step.location,
          id: `${step.location.id}-${step.startDate}`,
          locationIdentifier: step.location.id,
          locationName: step.location.name,
          startDate: step.startDate,
          endDate: step.endDate,
          duration: hideDuration ? undefined : step.duration,
          timeToNext,
          route: step.location.name,
          ...status
        };
      });

      setItemRoute(processedItemRoute);
    } catch (error) {
      // TODO: Add mixpanel tracking for error
      setItemRoute([]);
      setPopup({
        status: STATUS_OPTIONS.ERROR,
        message: "Unable to retrieve route. Please contact an administrator for assistance."
      });
    }

    setIsLoading(false);
  };

  const fetchAvailableLocations = async () => {
    const locationClient = XemelgoService.getClient().getLocationClient();
    const locations = (await locationClient.getLocationsOfCategory(locationCategories))
      .filter((location) => {
        return location.id !== currentLocationId;
      })
      .map((each) => {
        return {
          key: each.id,
          label: each.name,
          value: each.id
        };
      });

    setAvailableLocations(locations);
  };

  const timeRangeText = useMemo(() => {
    if (isAllTimeSelected) {
      return (
        dateRangeQuickFilterOptions.find((option) => {
          return option.id === ALL_TIME_ID;
        })?.label || ALL_TIME_LABEL
      );
    }

    const isEndDateNow = endDate.getTime() >= nowTimestamp;
    const timeDifference = endDate.getTime() - startDate.getTime();

    const quickSelectOption = dateRangeQuickFilterOptions.find((option) => {
      return option.value === timeDifference;
    });

    return isEndDateNow && quickSelectOption
      ? quickSelectOption.label
      : `${getFormattedDate(startDate, timeFormat)} - ${getFormattedDate(endDate, timeFormat)}`;
  }, [startDate, endDate, isAllTimeSelected]);

  const progressGraphTitle = useMemo(() => {
    if (itemRoute.length && (isAllTimeSelected || endDate.getTime() >= nowTimestamp)) {
      return `Last ${Math.min(5, itemRoute.length)} Location${itemRoute.length > 1 ? "s" : ""}:`;
    }

    return "";
  }, [isAllTimeSelected, endDate, itemRoute]);

  return (
    <>
      <div
        className={Style.container}
        data-cy="item-route-container"
      >
        <div className={Style.button_container}>
          <div
            className={Style.date_range_button}
            onClick={() => {
              setShowDateRangePicker(!showDateRangePicker);
            }}
          >
            {`Time Range: ${timeRangeText}`}
            <CalendarIcon className={Style.icon} />
          </div>
          {allowAddLocation && (
            <div
              className={Style.add_location_button}
              onClick={() => {
                setShowAddLocationModal(true);
              }}
            >
              Add Location
            </div>
          )}
        </div>
        {showDateRangePicker && (
          <div className={Style.date_control}>
            <div
              className={Style.date_range_container}
              ref={popupRef}
            >
              <DateRangePicker
                initialStartDate={startDate}
                initialEndDate={endDate}
                onClose={() => {
                  return setShowDateRangePicker(false);
                }}
                onConfirm={(newIsAllTimeSelected, newStartDate, newEndDate) => {
                  setIsAllTimeSelected(newIsAllTimeSelected);
                  setShowDateRangePicker(false);

                  if (newIsAllTimeSelected) {
                    fetchItemRoute();
                  } else {
                    fetchItemRoute(newStartDate.getTime(), newEndDate.getTime());
                    setStartDate(newStartDate);
                    setEndDate(newEndDate);
                  }
                }}
                defaultDateRangeMs={defaultRouteTimeframeMs}
                allTimeSelected={isAllTimeSelected}
                quickFilterOptions={dateRangeQuickFilterOptions}
              />
            </div>
          </div>
        )}
        {isLoading ? (
          <Skeleton className={Style.loading_progress_graph} />
        ) : (
          itemRoute.length > 0 && (
            <ProgressGraph
              stages={itemRoute.slice().reverse()}
              title={progressGraphTitle}
            />
          )
        )}
        <PaginatedListTableWithTypes
          isLoading={isLoading}
          numItemsPerPage={5}
          headers={routeTableHeaders}
          dataList={itemRoute}
          paginatorLocation="top"
          headerRowContainerClassName={Style.header_row_container}
          headerCellContainerClassName={Style.table_header}
          itemCellContainerClassName={Style.table_item_container}
          itemRowContainerClassName={isLoading ? Style.loading_table_item_row : Style.table_item_row}
          emptyListContainerClassName={Style.empty_list_container}
        />
      </div>
      {popup && popup.status !== STATUS_OPTIONS.NONE && (
        <div className={Style.status_popup_container}>
          <StatusPopupComponent
            showPopup
            message={popup.message}
            status={popup.status}
            closeOnClickOutside
            onPopupClose={() => {
              setPopup({
                status: STATUS_OPTIONS.NONE,
                message: ""
              });
            }}
          />
        </div>
      )}
      {showAddLocationModal && (
        <AddLocationModal
          itemIds={[itemId]}
          locations={availableLocations}
          onCancel={() => {
            setShowAddLocationModal(false);
          }}
          onSubmit={(message, hasError) => {
            setShowAddLocationModal(false);
            if (hasError) {
              setPopup({
                status: STATUS_OPTIONS.ERROR,
                message
              });
            } else {
              setPopup({
                status: STATUS_OPTIONS.SUCCESS,
                message
              });
              onLocationAdded();
            }
          }}
        />
      )}
    </>
  );
};
