import React, { useState } from "react";
import Style from "../../FeatureButtons.module.css";
import DeleteItemTypesActionModal from "../../../../../../../../../delete-item-types-action-modal";

interface DeleteItemTypesButtonProps {
  label?: string;
  itemTypeClass?: string;
  itemClasses?: string[];
}

export const DeleteItemTypesButton: React.FC<DeleteItemTypesButtonProps> = ({
  label = "Delete Item Types",
  itemTypeClass = "Inventory",
  itemClasses = ["Inventory"]
}) => {
  const [showDeleteItemTypesModal, setShowDeleteItemTypesModal] = useState(false);

  return (
    <div>
      <button
        type="button"
        className={Style.button}
        onClick={() => {
          setShowDeleteItemTypesModal(true);
        }}
      >
        {label}
      </button>
      {showDeleteItemTypesModal && (
        <DeleteItemTypesActionModal
          title={label}
          itemTypeClass={itemTypeClass}
          itemClasses={itemClasses}
          onCloseModal={() => {
            setShowDeleteItemTypesModal(false);
          }}
          onPostConfirm={(error) => {
            if (!error) {
              setShowDeleteItemTypesModal(false);
            }
          }}
        />
      )}
    </div>
  );
};
