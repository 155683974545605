import React, { useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import Slides from "../../../../../../components/slides";
import useTransferOrderTrackPageDataSourceContext from "../../../../contexts/transfer-order-track-page-data-source-context";
import useTransferOrderTrackPageConfigContext from "../../../../contexts/transfer-order-track-page-config-context";
import useTransferOrderTrackPageStateContext from "../../../../contexts/transfer-order-track-page-state-context";
import Style from "./Charts.module.css";
import BarChart from "../../../../../../components/bar-chart";

export const Charts = () => {
  const { currentLocationMetrics, transferOrderCountsState } = useTransferOrderTrackPageDataSourceContext();

  const { chartsControl } = useTransferOrderTrackPageConfigContext();

  const { selectedViewTypeId } = useTransferOrderTrackPageStateContext();

  const chartsData = useMemo(() => {
    return chartsControl
      .filter((eachChartControl) => {
        const { tableViewType } = eachChartControl || {};

        if (tableViewType && !tableViewType.includes(selectedViewTypeId) && !tableViewType.includes("all")) {
          return false;
        }

        return true;
      })
      .map((eachChartControl) => {
        const { dataControl } = eachChartControl || {};
        const dataList = dataControl.map(({ label: columnLabel, values }) => {
          return {
            title: "Status",
            x: columnLabel,
            values: values.map(({ id, label, ...rest }) => {
              return { ...rest, value: currentLocationMetrics[id] || 0, title: label };
            })
          };
        });
        return { ...eachChartControl, dataList };
      });
  }, [currentLocationMetrics, chartsControl, selectedViewTypeId]);

  const isLoading = useMemo(() => {
    return transferOrderCountsState.isLoading || transferOrderCountsState.hasNextPage;
  }, [transferOrderCountsState]);

  if (!chartsData.length) {
    return null;
  }

  return (
    <div className={Style.group}>
      <Slides
        allowTouchMove={false}
        id="transfer-order-track-page-charts-slides"
        numOfSlidesPerPage={1}
        numOfRowsPerPage={1}
        data={chartsData}
        renderSlideContent={(eachChartControl) => {
          const { label, subLabel, dataList } = eachChartControl;
          return (
            <div
              key={eachChartControl.id}
              className={Style.chart_slide_container}
            >
              <div className={Style.title_text}>
                <p className={`${Style.title_separation} ${Style.title_text}`}>
                  {isLoading ? (
                    <Skeleton
                      height={27}
                      width={100}
                    />
                  ) : (
                    label
                  )}
                </p>
                <p className={Style.sub_title_text}>
                  {isLoading ? (
                    <Skeleton
                      height={27}
                      width={100}
                    />
                  ) : (
                    subLabel
                  )}
                </p>
              </div>
              <BarChart
                isLoading={isLoading}
                dataList={dataList}
              />
            </div>
          );
        }}
      />
    </div>
  );
};
