import React from "react";
import ChangeDataViewDropdown, {
  DATA_VIEW_TYPE_MAP,
  DATA_VIEW_TYPE_TITLE_MAP
} from "../../../../../../components/change-data-view-dropdown";
import useTransferOrderTrackPageStateContext from "../../../../contexts/transfer-order-track-page-state-context";

const ChangeDataView = () => {
  const { selectedDataViewId, setSelectedDataViewId } = useTransferOrderTrackPageStateContext();
  return (
    <ChangeDataViewDropdown
      selectedDataViewId={selectedDataViewId}
      onOptionClick={(newSelectedDataViewId) => {
        setSelectedDataViewId(newSelectedDataViewId);
      }}
    />
  );
};

export { ChangeDataView, DATA_VIEW_TYPE_MAP, DATA_VIEW_TYPE_TITLE_MAP };
