import React, { useRef, useMemo } from "react";
import MetricsCardSlides from "../../../../../../components/metrics-card-slides";
import useComponentDimensions from "../../../../../../hooks/use-component-dimensions";
import useTransferOrderTrackPageDataSourceContext from "../../../../contexts/transfer-order-track-page-data-source-context";
import useTransferOrderTrackPageConfigContext from "../../../../contexts/transfer-order-track-page-config-context";
import useTransferOrderTrackPageStateContext from "../../../../contexts/transfer-order-track-page-state-context";

import Style from "./Metrics.module.css";

export const Metrics = () => {
  const containerRef = useRef();

  const dimensions = useComponentDimensions(containerRef);

  const { transferOrderCountsState, currentLocationMetrics } = useTransferOrderTrackPageDataSourceContext();

  const { selectedViewTypeId } = useTransferOrderTrackPageStateContext();

  const { metricsControl } = useTransferOrderTrackPageConfigContext();

  const metricsData = useMemo(() => {
    return metricsControl
      .filter((eachChartControl) => {
        const { tableViewType } = eachChartControl || {};

        if (tableViewType && !tableViewType.includes(selectedViewTypeId) && !tableViewType.includes("all")) {
          return false;
        }

        return true;
      })
      .map((eachMetricsControl) => {
        return {
          ...eachMetricsControl,
          value:
            eachMetricsControl.value !== undefined
              ? eachMetricsControl.value
              : eachMetricsControl.includesAttributes?.length
              ? eachMetricsControl.includesAttributes.reduce((accumulator, eachMetricsAttribute) => {
                  if (currentLocationMetrics[eachMetricsAttribute]) {
                    if (typeof currentLocationMetrics[eachMetricsAttribute] === "number") {
                      accumulator = (accumulator || 0) + currentLocationMetrics[eachMetricsAttribute];
                    } else if (!accumulator) {
                      accumulator = `${currentLocationMetrics[eachMetricsAttribute]}`;
                    } else {
                      accumulator = `${accumulator}, ${currentLocationMetrics[eachMetricsAttribute]}`;
                    }
                  }
                  return accumulator;
                }, undefined)
              : currentLocationMetrics[eachMetricsControl.id]
        };
      });
  }, [currentLocationMetrics, metricsControl, selectedViewTypeId]);

  const numOfSlidesPerPage = useMemo(() => {
    if (dimensions.width >= 575) {
      return 6;
    }
    if (dimensions.width >= 475) {
      return 4;
    }
    return 2;
  }, [dimensions.width]);

  if (!metricsData.length) {
    return null;
  }

  return (
    <div className={Style.group}>
      <div
        className={Style.container}
        ref={containerRef}
      >
        <MetricsCardSlides
          id="transfer-order-track-page-metrics-slide"
          numOfSlidesPerPage={metricsData.length <= numOfSlidesPerPage ? metricsData.length : numOfSlidesPerPage}
          numOfRowsPerPage={metricsData.length <= numOfSlidesPerPage / 2 ? 1 : 2}
          data={metricsData}
          isLoading={transferOrderCountsState.isLoading || transferOrderCountsState.hasNextPage}
        />
      </div>
    </div>
  );
};
