import React, { useState } from "react";
import { ItemAssociationConfigContextProvider, useItemAssociationConfigContext } from "../../contexts";
import useUpdateItemConnections from "../../hooks/use-update-item-connections";
import Modal from "../../../../components/modal";
import { ItemToRemove } from "../../data/types";

type RemoveItemAssociationModalProps = {
  itemsToRemove: ItemToRemove[];
  onSubmit: () => void;
  onClose: () => void;
};

type RemoveItemAssociationModalDefaultExportProps = RemoveItemAssociationModalProps & {
  solutionId: string;
};

export const RemoveItemAssociationModal: React.FC<RemoveItemAssociationModalProps> = ({
  itemsToRemove,
  onSubmit,
  onClose
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { itemLabel } = useItemAssociationConfigContext();
  const { detachItems } = useUpdateItemConnections();

  const onConfirm = async () => {
    setIsLoading(true);
    const promises = itemsToRemove.map((item: ItemToRemove) => {
      const { mainItemId, selectedItemId, connection } = item;

      return detachItems(mainItemId, selectedItemId, connection);
    });

    await Promise.all(promises);
    setIsLoading(false);
    onSubmit();
  };

  return (
    <Modal
      title={`Remove ${itemLabel}`}
      onConfirm={onConfirm}
      confirmDisabled={isLoading}
      onCancel={onClose}
    >
      <div>You are about to disassociate the selected parts. Press “Confirm” to proceed.</div>
    </Modal>
  );
};

export default ({ solutionId, itemsToRemove, onSubmit, onClose }: RemoveItemAssociationModalDefaultExportProps) => {
  return (
    <ItemAssociationConfigContextProvider solutionId={solutionId}>
      <RemoveItemAssociationModal
        itemsToRemove={itemsToRemove}
        onSubmit={onSubmit}
        onClose={onClose}
      />
    </ItemAssociationConfigContextProvider>
  );
};
