import React from "react";
import FreeTextSearchInput from "../../../../../../../../components/free-text-search-input";
import Style from "./FreeTextSearch.module.css";
import useTransferOrderTrackPageStateContext from "../../../../../../contexts/transfer-order-track-page-state-context";

export const FreeTextSearch = () => {
  const { freeTextSearchInputString, setFreeTextSearchInputString } = useTransferOrderTrackPageStateContext();

  return (
    <div className={Style.container}>
      <FreeTextSearchInput
        value={freeTextSearchInputString}
        onChangeText={(newText) => {
          setFreeTextSearchInputString(newText);
        }}
      />
    </div>
  );
};
