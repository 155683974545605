import { Header } from "../../../components/paginated-list-table-with-types";

export const PACKAGE_DETAIL_PAGE_URL = "/package/detail?itemId=";

export const PACKAGE_TABLE_DEFAULT_HEADERS: Header[] = [
  {
    id: "identifier",
    label: "Package",
    type: "text"
  },
  {
    id: "status",
    label: "Status",
    type: "text"
  }
];

export const ORDER_STATUS_TABLE_DEFAULT_HEADERS: Header[] = [
  {
    id: "status",
    label: "Status",
    type: "text"
  },
  {
    id: "packageCount",
    label: "Package Count",
    type: "number"
  }
];

export const PACKAGE_ORDER_DETAIL_CARD_DEFAULT_ATTRIBUTES = [
  {
    id: "identifier",
    isHeader: true,
    label: "Order #"
  }
];

export const DEFAULT_TRANSFER_ORDER_STATUS_TO_DISPLAYED_STATUS_MAP = {
  Created: {
    color: "#737880",
    label: "Order Created"
  },
  InProgress: {
    color: "#4B9910",
    label: "Delivery in Progress"
  },
  Received: {
    color: "#4B9910",
    label: "Completed"
  },
  ReceivingInProgress: {
    color: "#F6B044",
    label: "Delivery in Progress"
  },
  StagingInProgress: {
    color: "#F6B044",
    label: "Delivery in Progress"
  }
};

export const DEFAULT_PACKAGE_STATE_TO_DISPLAYED_STATUS_MAP = {
  created: {
    color: "#737880",
    label: "Created"
  },
  delivered: {
    color: "#4B9910",
    label: "Delivered"
  },
  exception: {
    color: "#4B9910",
    label: "Exception"
  },
  onhand: {
    color: "#4B9910",
    label: "Stock"
  },
  put_away: {
    color: "#4B9910",
    label: "Put Away"
  },
  received: {
    color: "#4B9910",
    label: "Received"
  },
  shipped: {
    color: "#4B9910",
    label: "Out For Delivery"
  },
  staged: {
    color: "#4B9910",
    label: "Stage for Delivery"
  }
};