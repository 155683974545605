import React, { useMemo, useEffect } from "react";
import useInventoryTrackPageStateContext from "../../../../../../contexts/inventory-track-page-state-context";
import PaginatedListTableWithTypes, {
  getValueByType
} from "../../../../../../../../components/paginated-list-table-with-types";
import useInventoryTrackPageDataSourceContext from "../../../../../../contexts/inventory-track-page-data-source-context";
import Style from "./ItemTypeTable.module.css";
import PartialLoadingMessage from "../../components/partial-loading-message";
import useDebounce from "../../../../../../../../hooks/use-debounce";
import useGetFilteredDataList from "../../hooks/use-get-filtered-data-list";
import useInventoryTrackPageConfigContext from "../../../../../../contexts/inventory-track-page-config-context";
import { TABLE_VIEW_TYPE_ID_MAP } from "../../../table-control-actions/features/table-view-type-control";
import { exportCsv } from "../../../../../../../../common/Utilities";

export const ItemTypeTable = () => {
  const { itemTypesByLocationState, locationTreeMap, isLocationTreeMapLoading } =
    useInventoryTrackPageDataSourceContext();

  const { dataList, isLoading, hasNextPage } = itemTypesByLocationState;

  const {
    getURLByState,
    applyItemTypeFilterFn,
    freeTextSearchInputString,
    displayedTableHeadersMap,
    selectedViewModeId,
    setExportCsvFn,
    selectedLocationId,
    selectedViewTypeId
  } = useInventoryTrackPageStateContext();

  const { listTableControl, tableViewTypeControl, combinedDuplicatesDataSources } =
    useInventoryTrackPageConfigContext();

  const isCombinedDuplicatesEnabled = useMemo(() => {
    return combinedDuplicatesDataSources?.includes("itemType") || false;
  }, [combinedDuplicatesDataSources]);

  const csvFileName = useMemo(() => {
    return `${selectedLocationId === undefined ? "All locations" : locationTreeMap?.[selectedLocationId]?.name} - ${
      tableViewTypeControl?.[selectedViewTypeId]?.label
    }`;
  }, [locationTreeMap, selectedLocationId, selectedViewTypeId, tableViewTypeControl]);

  const headers = useMemo(() => {
    return displayedTableHeadersMap?.[TABLE_VIEW_TYPE_ID_MAP.itemType]?.[selectedViewModeId]
      ?.map((eachHeaderId) => {
        return listTableControl[TABLE_VIEW_TYPE_ID_MAP.itemType]?.headers.find(({ id }) => {
          return eachHeaderId === id;
        });
      })
      .filter((each) => {
        return !!each;
      });
  }, [listTableControl, displayedTableHeadersMap, selectedViewModeId]);

  const freeTextSearchInputTextDebounced = useDebounce(freeTextSearchInputString, 250);

  const filteredItemTypeList = useGetFilteredDataList({
    freeTextSearchInputString: freeTextSearchInputTextDebounced,
    dataList,
    filterCallbackFn: applyItemTypeFilterFn,
    headers
  });

  const combinedCountItemTypeList = useMemo(() => {
    return Object.values(
      filteredItemTypeList.reduce((accumulator, eachItemType) => {
        const { id: itemTypeId } = eachItemType;

        // Accumulate all count attributes directly
        if (!accumulator[itemTypeId]) {
          accumulator[itemTypeId] = { ...eachItemType };
        } else {
          Object.keys(eachItemType).forEach((key) => {
            if (key.toLowerCase().includes("count")) {
              accumulator[itemTypeId][key] = (accumulator[itemTypeId][key] || 0) + eachItemType[key];
            }
            if (key.toLowerCase().includes("threshold") && accumulator[itemTypeId][key]) {
              accumulator[itemTypeId][key] = [
                Array.isArray(accumulator[itemTypeId][key])
                  ? [...accumulator[itemTypeId][key], eachItemType[key]]
                  : [accumulator[itemTypeId][key], eachItemType[key]]
              ];
            }
            if (["isLowStock", "isOutOfStock"].includes(key)) {
              accumulator[itemTypeId][key] = accumulator[itemTypeId][key] || eachItemType[key];
            }
            if (["isNormalStock"].includes(key)) {
              accumulator[itemTypeId][key] = accumulator[itemTypeId][key] && eachItemType[key];
            }
          });
        }

        return accumulator;
      }, {})
    );
  }, [filteredItemTypeList]);

  useEffect(() => {
    const csvData = (isCombinedDuplicatesEnabled ? combinedCountItemTypeList : filteredItemTypeList).map((eachData) => {
      return {
        ...headers.reduce((accumulator, eachHeader) => {
          const { label } = eachHeader;
          accumulator[label] = getValueByType(eachData, eachHeader, true);
          return accumulator;
        }, {})
      };
    });
    setExportCsvFn(() => {
      return () => {
        exportCsv(
          csvData,
          {
            header: headers.map((eachHeader) => {
              return eachHeader.label;
            }),
            cellDates: true
          },
          csvFileName
        );
      };
    });
  }, [isCombinedDuplicatesEnabled, combinedCountItemTypeList, filteredItemTypeList, headers, csvFileName]);


  return (
    <div className={Style.container}>
      {hasNextPage && (
        <div className={Style.partial_loading_message_container}>
          <PartialLoadingMessage />
        </div>
      )}
      <PaginatedListTableWithTypes
        isLoading={isLocationTreeMapLoading || isLoading}
        paginatorLocation="top"
        headers={headers}
        getTitleURLCallbackFn={(itemType) => {
          return getURLByState(
            itemType.identifier ? { selectedItemGroup: { type: "itemTypeIdentifier", value: itemType.identifier } } : {}
          );
        }}
        dataList={isCombinedDuplicatesEnabled ? combinedCountItemTypeList : filteredItemTypeList}
      />
    </div>
  );
};
