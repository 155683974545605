import React, { createContext, useContext, useMemo } from "react";
import { useStore } from "react-hookstore";
import { userProfileStore } from "../../../../state-managements/stores/user-profile-store";
import { useAppConfigProvider } from "../../../../services/soft-cache-service";
import { SOLUTION_CONFIGS } from "./data/constants";
import { DetailsPageConfigType, MoreOptionsType } from "./data/types";
import getDefaultedConfig from "../../../../utils/get-defaulted-config";
import { FEATURES, ROLES } from "../../../../data/constants";

const defaultConfig: DetailsPageConfigType = {
  additionalAttributes: {
    item: [],
    itemType: ["image_path"]
  },
  customText: {
    title: "Details",
    subtitle: "Name"
  },
  detailsCard: {
    fields: [
      {
        key: "node_last_updated_time",
        label: "Last Updated",
        editable: false,
        type: "date"
      },
      {
        key: "node_created_time",
        label: "Created",
        editable: false,
        type: "date"
      }
    ]
  },
  tabs: [],
  subfeatureOptions: {},
  moreOptions: [
    {
      id: "edit",
      label: "Edit Details",
      roles: [ROLES.ADMIN, ROLES.USER, ROLES.VENDOR_CLIENT_ADMIN, ROLES.VENDOR_CLIENT_USER]
    }
  ]
};

const DetailsPageConfigContext = createContext<DetailsPageConfigType>(defaultConfig);

export const useDetailsPageConfigContext = () => {
  return useContext(DetailsPageConfigContext);
};

export const DetailsPageConfigContextProvider = ({
  children,
  solutionId
}: {
  children: React.ReactNode;
  solutionId: string;
}) => {
  const [userProfile] = useStore(userProfileStore);

  const configProvider = useAppConfigProvider(solutionId);

  const configValues = useMemo(() => {
    const detailsPageConfig = configProvider.getValue(FEATURES.DETAILS_PAGE, "object") || {};
    const solutionDefaultConfig = SOLUTION_CONFIGS[solutionId] || defaultConfig;
    return getDefaultedConfig(solutionDefaultConfig, detailsPageConfig);
  }, [configProvider]);

  const moreOptions = useMemo(() => {
    const { moreOptions: tempMoreOptions = defaultConfig.moreOptions } = configValues;
    if (userProfile && tempMoreOptions) {
      const userRole = userProfile.getRole();
      return tempMoreOptions.filter((option: MoreOptionsType) => {
        const { roles = [] } = option;
        return roles.includes(userRole);
      });
    }
    return [];
  }, [configValues, userProfile]);

  return (
    <DetailsPageConfigContext.Provider value={{ ...configValues, moreOptions }}>
      {children}
    </DetailsPageConfigContext.Provider>
  );
};
