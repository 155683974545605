import React from "react";
import useDetailsPageConfigContext from "../../contexts/details-page-config-context";
import DetailCard from "../../../../components/TrackPageComponents/DetailCard";
import { DetailsCardData } from "./data/types";
import { MoreOptionsType } from "../../contexts/details-page-config-context/data/types";
import useDetailsPageStateContext from "../../contexts/details-page-state-context";
import defaultEditDetails from "./utils/default-edit-details";
import defaultEditImages from "./utils/default-edit-images";
import { STATUS_OPTIONS } from "../../../../components/status-popup-component";

interface DetailsCardV2Props {
  editImagesEnabled?: boolean;
  moreOptions?: MoreOptionsType[];
  onEditDetailsSave?: (id: string, newDetails: Record<string, unknown>) => Promise<void>;
  onEditImagesSave?: (id: string, imagesToAdd: File[], imagesToRemove: string[]) => Promise<void>;
  detailsCardData: DetailsCardData | null;
}

export const DetailsCardV2 = ({
  editImagesEnabled,
  moreOptions = [],
  onEditDetailsSave = defaultEditDetails,
  onEditImagesSave = defaultEditImages,
  detailsCardData
}: DetailsCardV2Props) => {
  const { customText } = useDetailsPageConfigContext();
  const { id, isLoading, setIsLoading, refreshDetails, setPopupMessage, setPopupStatus } = useDetailsPageStateContext();

  const handleEditSave = async (newDetails: Record<string, unknown>) => {
    if (Object.keys(newDetails).length === 0) {
      return;
    }

    setIsLoading(true);
    try {
      await onEditDetailsSave(id, newDetails);
      await refreshDetails();
      setPopupMessage("Successfully updated details.");
      setPopupStatus(STATUS_OPTIONS.SUCCESS);
    } catch (error) {
      setPopupMessage("Error while saving details.");
      setPopupStatus(STATUS_OPTIONS.ERROR);
    }
  };

  const handleImageSave = async (imagesToAdd: File[], imagesToRemove: string[]) => {
    setIsLoading(true);
    try {
      await onEditImagesSave(id, imagesToAdd, imagesToRemove);
      await refreshDetails();
      setPopupMessage("Successfully updated images.");
      setPopupStatus(STATUS_OPTIONS.SUCCESS);
    } catch (error) {
      setPopupMessage("Error while saving details.");
      setPopupStatus(STATUS_OPTIONS.ERROR);
    }
  };

  return (
    <DetailCard
      isLoading={isLoading}
      titleStructure={
        detailsCardData
          ? {
              key: detailsCardData.id,
              name: customText.subtitle,
              value: detailsCardData.identifier,
              editable: false
            }
          : undefined
      }
      detailStructureList={detailsCardData?.displayedProperties || []}
      statusList={detailsCardData?.statuses || []}
      moreOptions={moreOptions}
      editImagesEnabled={editImagesEnabled}
      onSubmit={handleEditSave}
      onSubmitImages={handleImageSave}
      defaultImage={detailsCardData?.defaultImage}
      images={detailsCardData?.imageUrls || []}
    />
  );
};
