import React from "react";
import moment from "moment";
import DateRangePopupButton from "../../components/date-range-popup-button";
import ScreenFrame from "../../components/screen-frame";
import SideFilters from "./features/side-filters";
import xemelgoStyle from "../../styles/variable";
import Style from "./TransferOrderTrackPageFeature.module.css";
import { ReactComponent as TransferOrderIcon } from "../../assets/icons/transfer-order.svg";
import TitleRightActions from "./features/title-right-actions";
import { TransferOrderTrackPageConfigContextProvider } from "./contexts/transfer-order-track-page-config-context";
import useTransferOrderTrackPageDataSourceContext, {
  TransferOrderTrackPageDataSourceContextProvider
} from "./contexts/transfer-order-track-page-data-source-context";
import useTransferOrderTrackPageStateContext, {
  TransferOrderTrackPageStateContextProvider
} from "./contexts/transfer-order-track-page-state-context";
import LocationOverview from "./features/location-overview";
import TransferOrderInfoTable from "./features/transfer-order-info-table";
import FilterOverviewControl from "./features/filter-overview-control";
import { TIME_IN_MS } from "../../common/Utilities";

export const TransferOrderTrackPage = () => {
  const { setStartDate, setEndDate } = useTransferOrderTrackPageStateContext();
  const { transferOrderCountsState } = useTransferOrderTrackPageDataSourceContext();

  return (
    <ScreenFrame
      color={xemelgoStyle.theme.INVENTORY_PRIMARY}
      secondaryColor={xemelgoStyle.theme.INVENTORY_SECONDARY}
      titleIconComponent={
        <TransferOrderIcon
          width={25}
          height={25}
          style={{ color: xemelgoStyle.theme.INVENTORY_PRIMARY }}
        />
      }
      title="Transfer Order"
      titleRightComponent={<TitleRightActions />}
    >
      <div className={Style.main_container}>
        <SideFilters />
        <div className={Style.right_side_container}>
          <FilterOverviewControl />
          <div className={Style.top_group}>
            <DateRangePopupButton
              maxDuration={TIME_IN_MS.DAYS * 90}
              onConfirm={(newStartDate, newEndDate) => {
                setStartDate(newStartDate);
                setEndDate(newEndDate);
              }}
            />
            <div className={Style.last_updated_time_container}>
              <p className={Style.last_updated_time_text}>Last Updated:</p>
              <p className={Style.last_updated_time_text}>
                {transferOrderCountsState?.lastUpdatedTime
                  ? moment(transferOrderCountsState.lastUpdatedTime).calendar()
                  : "-"}
              </p>
              <p />
            </div>
          </div>
          <LocationOverview />
          <TransferOrderInfoTable />
        </div>
      </div>
    </ScreenFrame>
  );
};

export default () => {
  return (
    <TransferOrderTrackPageConfigContextProvider>
      <TransferOrderTrackPageStateContextProvider>
        <TransferOrderTrackPageDataSourceContextProvider>
          <TransferOrderTrackPage />
        </TransferOrderTrackPageDataSourceContextProvider>
      </TransferOrderTrackPageStateContextProvider>
    </TransferOrderTrackPageConfigContextProvider>
  );
};
