import { FILTER_TYPE } from "../../data/constants";
import { convertToRegex } from "./utils/convert-to-regex/convertToRegex";

const FILTER_OPERATION = {
  IN: "IN",
  STARTS_WITH: "STARTS_WITH",
  REGEXP: "REGEXP",
  GREATER_THAN: "GREATER_THAN",
  GREATER_THAN_OR_EQUAL: "GREATER_THAN_OR_EQUAL",
  LESS_THAN: "LESS_THAN",
  LESS_THAN_OR_EQUAL: "LESS_THAN_OR_EQUAL",
  AND: "AND",
  OR: "OR",
  NOT: "NOT"
} as const; // Use `as const` to infer literal types

interface CheckBoxFilterValue {
  id: string;
  value?: boolean;
}

interface DateRangeFilterValue {
  startTime?: number;
  endTime?: number;
}

interface SearchDropdownFilterValue {
  id?: string;
}

interface Filter {
  property: string;
  operation: keyof typeof FILTER_OPERATION;
  values: any;
}

interface FilterGroup {
  operation: keyof typeof FILTER_OPERATION;
  filters: Filter[];
}

interface IdToTypeMap {
  [key: string]: keyof typeof FILTER_TYPE;
}

interface UseSolutionMetricsFilterBuilder {
  build: (filterValue: Record<string, any>, idToTypeMap: IdToTypeMap) => Array<Filter | FilterGroup>;
}

export const useSolutionMetricsFilterBuilder = (): UseSolutionMetricsFilterBuilder => {
  const getCheckBoxFilterString = (eachFilterKey: string, eachFilterValue: CheckBoxFilterValue[]): Filter | null => {
    const selectedItems = (eachFilterValue || []).filter(({ value }) => {
      return value;
    });

    if (!selectedItems.length) {
      return null;
    }

    return {
      property: `properties.${eachFilterKey}`,
      operation: FILTER_OPERATION.IN,
      values: selectedItems.map(({ id }) => {
        return id;
      })
    };
  };

  const getDateRangeFilterString = (
    eachFilterKey: string,
    eachFilterValue: DateRangeFilterValue
  ): FilterGroup | null => {
    const property = `properties.${eachFilterKey}`;
    const { startTime, endTime } = eachFilterValue || {};

    if (!startTime && !endTime) {
      return null;
    }

    const filterGroup: FilterGroup = { operation: FILTER_OPERATION.AND, filters: [] };

    if (startTime) {
      filterGroup.filters.push({
        property,
        operation: FILTER_OPERATION.GREATER_THAN_OR_EQUAL,
        values: startTime
      });
    }
    if (endTime) {
      filterGroup.filters.push({
        property,
        operation: FILTER_OPERATION.LESS_THAN_OR_EQUAL,
        values: endTime
      });
    }
    return filterGroup;
  };

  const getSearchDropdownFilterString = (
    eachFilterKey: string,
    eachFilterValue: SearchDropdownFilterValue
  ): Filter | null => {
    if (!eachFilterValue?.id) {
      return null;
    }

    return {
      property: `properties.${eachFilterKey}`,
      operation: FILTER_OPERATION.IN,
      values: [eachFilterValue.id]
    };
  };

  const getInputFilterString = (eachFilterKey: string, eachFilterValue: string[]): FilterGroup | null => {
    const filterGroup: FilterGroup = {
      operation: FILTER_OPERATION.OR,
      filters: eachFilterValue.map((eachString: string): Filter => {
        return {
          property: `properties.${eachFilterKey}`,
          operation: FILTER_OPERATION.REGEXP,
          values: convertToRegex(eachString)
        };
      })
    };

    if (!filterGroup.filters.length) {
      return null;
    }

    return filterGroup;
  };

  const getFilterStringByType = (
    eachFilterKey: string,
    eachFilterValue: any,
    type: keyof typeof FILTER_TYPE
  ): Filter | FilterGroup | null => {
    switch (type) {
      case FILTER_TYPE.checkboxes:
        return getCheckBoxFilterString(eachFilterKey, eachFilterValue);
      case FILTER_TYPE.dateRange:
        return getDateRangeFilterString(eachFilterKey, eachFilterValue);
      case FILTER_TYPE.searchDropdown:
        return getSearchDropdownFilterString(eachFilterKey, eachFilterValue);
      case FILTER_TYPE.input:
      default:
        return getInputFilterString(eachFilterKey, eachFilterValue);
    }
  };

  const build = (filterValue: Record<string, any>, idToTypeMap: IdToTypeMap): Array<Filter | FilterGroup> => {
    return Object.keys(filterValue || {})
      .map((eachFilterKey) => {
        return getFilterStringByType(eachFilterKey, filterValue[eachFilterKey], idToTypeMap[eachFilterKey]);
      })
      .filter((eachFilter): eachFilter is Filter | FilterGroup => {
        return eachFilter !== null;
      }); // Type guard to remove nulls
  };

  return { build };
};
