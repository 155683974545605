import React from "react";
import PropTypes from "prop-types";
import PrintTagsModal from "../../../../../../../print-tags-modal";

export const MultiSelectActionModal = ({ action, selectedItems, onModalClose }) => {
  switch (action.id) {
    case "printTags":
      return (
        <PrintTagsModal
          onCloseModal={onModalClose}
          selectedItems={selectedItems}
          printTagsOptions={action}
          appId="inventory"
          solutionDisplayName="inventory item"
        />
      );
    default:
      return null;
  }
};

MultiSelectActionModal.defaultProps = {
  onModalClose: () => {}
};

MultiSelectActionModal.propTypes = {
  action: PropTypes.shape({ id: PropTypes.string, label: PropTypes.string }).isRequired,
  selectedItems: PropTypes.array.isRequired,
  onModalClose: PropTypes.func
};
