import React, { useState, memo, useRef, useEffect } from "react";
import { useStore } from "react-hookstore";
import PropTypes from "prop-types";
import { loggingUserStore } from "../../../state-managements/stores/login-store";
import { isValidEmail } from "../../../common/Utilities";
import "../LoginPage.css";
import { ReactComponent as CircleArrowRightIcon } from "../../../assets/icons/circle-arrow-right.svg";
import { isValidFederatedSignInEmail } from "../../../utils/is-valid-federated-sign-in-email/isValidFederatedSignInEmail";
import { getFederatedSignInCustomProvider } from "../../../utils/get-federated-sign-in-custom-provider/getFederatedSignInCustomProvider";
import { useAuthenticationContext } from "../../../context/authentication-context/AuthenticationContext";
import CONFIGURATION from "../../../configuration.json";
import LoginFormInput from "../../../components/login-form-input";
import Style from "./LoginForm.module.css";
import xemelgoStyle from "../../../styles/variable";

const LoginForm = memo(({ logo, poweredByLogo, fnSubmit, fnForgotPassword }) => {
  const { isEnvironmentSetupSuccessful } = useAuthenticationContext();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [, setLoggingUser] = useStore(loggingUserStore);

  const [emailOnlyMode, setEmailOnlyMode] = useState(true);

  const passwordInputRef = useRef(null);

  const onUsernameInput = (newUserName) => {
    setUsername(newUserName);
  };

  const onPasswordInput = (newPassword) => {
    setPassword(newPassword);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    const trimmedUserName = username.trim();

    if (trimmedUserName.includes(" ")) {
      setErrorMessage("Username cannot contain any space");
    } else {
      setLoggingUser(trimmedUserName);
      setIsSubmitting(true);
      try {
        await fnSubmit(
          trimmedUserName,
          password,
          isValidFederatedSignInEmail(trimmedUserName),
          getFederatedSignInCustomProvider(trimmedUserName)
        );
      } catch (error) {
        if (error.code === "InvalidParameterException") {
          setErrorMessage("Please enter your password");
        } else {
          setErrorMessage(error.message);
        }
        setIsSubmitting(false);
      }
    }
  };

  const onForgotPassword = () => {
    setLoggingUser(username);
    fnForgotPassword(username);
  };

  const onNextPress = async (event) => {
    event.preventDefault();
    const trimmedUserName = username.trim();

    if (trimmedUserName && isValidEmail(trimmedUserName)) {
      if (isValidFederatedSignInEmail(trimmedUserName)) {
        await fnSubmit(trimmedUserName, null, true, getFederatedSignInCustomProvider(trimmedUserName));
      } else {
        setEmailOnlyMode(false);
      }
    }
  };

  useEffect(() => {
    if (passwordInputRef.current && !emailOnlyMode) {
      passwordInputRef.current.focus();
    }
  }, [passwordInputRef.current, emailOnlyMode]);

  useEffect(() => {
    if (!isEnvironmentSetupSuccessful) {
      setErrorMessage("There was an error logging in. Please contact support@xemelgo.com for assistance.");
      setIsSubmitting(false);
    }
  }, [isEnvironmentSetupSuccessful]);

  return (
    <div
      className={
        CONFIGURATION.controlspan ? "content-body controlspan-login-page-body" : "content-body login-page-body"
      }
    >
      <div
        className="container login-form"
        style={{ textAlign: "initial" }}
      >
        <form className="login-form-background">
          <img
            src={logo}
            className={CONFIGURATION.controlspan ? "controlspan-brand-logo" : "brand-logo"}
            alt="brand-logo"
          />
          <div className="error_message">{errorMessage}</div>
          <div className={Style.login_form_fields}>
            <div className="form-group-email-only">
              <LoginFormInput
                secondaryColor={xemelgoStyle.theme.APP_WHITE}
                className="input-default"
                disabled={isSubmitting}
                label="Email"
                id="username"
                onChangeText={onUsernameInput}
                data-cy-login-form__email-input
              />
              {emailOnlyMode && (
                <button
                  type="submit"
                  onClick={onNextPress}
                  data-cy-login-form__email-next-button
                >
                  <CircleArrowRightIcon className="next_button" />
                </button>
              )}
            </div>
            {!emailOnlyMode && (
              <>
                <div className="form-group">
                  <LoginFormInput
                    secondaryColor={xemelgoStyle.theme.APP_WHITE}
                    ref={passwordInputRef}
                    className="input-default"
                    disabled={isSubmitting}
                    label="Password"
                    id="password"
                    onChangeText={onPasswordInput}
                    type="password"
                    data-cy-login-form__password-input
                  />
                </div>
                <button
                  type="submit"
                  onClick={onSubmit}
                  className="add-user-button"
                  data-cy-login-form__login-button
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Logging in..." : "Log In"}
                </button>

                <div className="center-align">
                  <button
                    onClick={onForgotPassword}
                    disabled={isSubmitting}
                    className="asText"
                    type="button"
                  >
                    Forgot Password?
                  </button>
                </div>
              </>
            )}
          </div>
        </form>
        {poweredByLogo && (
          <img
            src={poweredByLogo}
            className="powered-by-logo"
            alt="powered-by-logo"
          />
        )}
      </div>
    </div>
  );
});

LoginForm.propTypes = {
  logo: PropTypes.string.isRequired,
  poweredByLogo: PropTypes.string.isRequired,
  fnSubmit: PropTypes.func.isRequired,
  fnForgotPassword: PropTypes.func.isRequired
};

export { LoginForm };
