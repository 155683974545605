import React, { useState, useRef, useMemo, useImperativeHandle, forwardRef } from "react";
import xemelgoStyle from "../../styles/variable";
import Style from "./LoginFormInput.module.css";

interface LoginFormInputProps {
  label?: string;
  id?: string;
  className?: string;
  containerClassName?: string;
  hasError?: boolean;
  disabled: boolean;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeText?: (text: string, e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  primaryColor?: string;
  secondaryColor?: string;
}

export const LoginFormInput = forwardRef<HTMLInputElement, LoginFormInputProps>(
  (
    {
      label,
      id,
      containerClassName,
      hasError,
      disabled,
      onChange = () => {},
      onChangeText = () => {},
      onFocus = () => {},
      onBlur = () => {},
      primaryColor = xemelgoStyle.theme.APP_BLUE,
      secondaryColor = xemelgoStyle.theme.APP_LIGHTBLACK,
      ...props
    },
    ref
  ) => {
    const [focused, setFocused] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    // Combine ref and inputRef
    useImperativeHandle(ref, () => {
      return inputRef.current as HTMLInputElement;
    });

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      onFocus(event);
      setFocused(true);
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      onBlur(event);
      setFocused(false);
    };

    const inputHasValue = useMemo(() => {
      return (props.value && props.value.trim()) || (inputRef.current && inputRef.current.value.trim() !== "");
    }, [inputRef?.current?.value, props.value]);

    return (
      <div className={`${Style.input_container} ${containerClassName || ""}`}>
        {/* Input Wrapper */}
        <div
          className={`${Style.input_wrapper} ${focused ? Style.focused : ""} ${hasError ? Style.error : ""} ${
            disabled ? Style.disabled : ""
          } ${inputHasValue ? Style.has_value : ""}`}
          style={focused ? { borderColor: primaryColor } : undefined}
        >
          <input
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            ref={inputRef}
            id={id}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={disabled}
            onChange={(e) => {
              onChange(e);
              onChangeText(e.target.value, e);
            }}
          />
          {label && (
            <label
              htmlFor={id}
              style={{ color: focused ? primaryColor : secondaryColor }}
            >
              {label}
            </label>
          )}
        </div>
      </div>
    );
  }
);

// Set display name for the component (optional but recommended for debugging)
LoginFormInput.displayName = "LoginFormInput";
