import React, { useCallback, useMemo, useState } from "react";
import DetailsPage from "../details-page";
import { ReactComponent as InventoryIcon } from "../../assets/icons/inventory.svg";
import { SOLUTIONS } from "../../data/constants";
import TransferOrderContainersTable from "./features/transfer-order-containers-table";
import TransferOrderItemTypesTable from "./features/transfer-order-item-types-table";
import fetchTransferOrder from "./utils/fetch-transfer-order";
import TransferOrderItemsTable from "./features/transfer-order-items-table";
import {
  AdditionalAttributes,
  DetailsCardConfig
} from "../details-page/contexts/details-page-config-context/data/types";
import { TransferOrderDetails, TransferOrderDetailsPageProps } from "./data/types";
import xemelgoStyle from "../../styles/variable";
import useDetailsPageConfigContext, {
  DetailsPageConfigContextProvider
} from "../details-page/contexts/details-page-config-context";
import { TRANSFER_ORDER_DETAILS_PATH } from "./data/constants";

const TransferOrderDetailsPage: React.FC<TransferOrderDetailsPageProps> = ({ path = TRANSFER_ORDER_DETAILS_PATH }) => {
  const [details, setDetails] = useState<TransferOrderDetails>();
  const { subfeatureOptions } = useDetailsPageConfigContext();

  const onQueryDetails = useCallback(
    async (id: string, additionalAttributes: AdditionalAttributes, detailsCardConfig: DetailsCardConfig) => {
      if (!id) {
        throw new Error("No id provided");
      }

      const transferOrder = await fetchTransferOrder(id, additionalAttributes, detailsCardConfig);

      setDetails(transferOrder);
      return transferOrder.detailsCardData;
    },
    []
  );

  const transferOrderSubfeatureMap = useMemo(() => {
    return {
      transferOrderContainersTable: (
        <TransferOrderContainersTable
          itemTypes={details?.itemTypes || []}
          transferOrderIdentifier={details?.identifier || ""}
          options={subfeatureOptions.transferOrderContainersTable}
        />
      ),
      transferOrderItemTypesTable: (
        <TransferOrderItemTypesTable
          itemTypes={details?.itemTypes || []}
          transferOrderIdentifier={details?.identifier || ""}
          options={subfeatureOptions.transferOrderItemTypesTable}
        />
      ),
      transferOrderItemsTable: (
        <TransferOrderItemsTable
          items={
            details?.itemTypes?.flatMap((itemType) => {
              return itemType.items;
            }) || []
          }
          transferOrderIdentifier={details?.identifier || ""}
          options={subfeatureOptions.transferOrderItems}
        />
      )
    };
  }, [details, subfeatureOptions]);

  return (
    <DetailsPage
      defaultTitle="Shipment Details"
      featureMap={transferOrderSubfeatureMap}
      onQueryDetails={onQueryDetails}
      primaryColor={xemelgoStyle.theme.INVENTORY_PRIMARY}
      secondaryColor={xemelgoStyle.theme.INVENTORY_SECONDARY}
      iconComponent={InventoryIcon}
      path={path}
    />
  );
};

export default (props: TransferOrderDetailsPageProps) => {
  return (
    <DetailsPageConfigContextProvider solutionId={SOLUTIONS.TRANSFER_ORDER}>
      <TransferOrderDetailsPage {...props} />
    </DetailsPageConfigContextProvider>
  );
};
