import { useXemelgoClient } from "../../../../services/xemelgo-service";
import { ItemV2 } from "@xemelgo/x-client";
import { OPPOSITE_DIRECTION_RELATIONSHIP_MAP, CONNECTIONS_MAP } from "../../data/constants";
import { RelationshipConfig } from "../../data/types";

export const useUpdateItemConnections = (relationshipMap: Record<string, RelationshipConfig> = {}) => {
  const xemelgoClient = useXemelgoClient();

  const verifyItemConnections = async (
    currentItemId: string,
    selectedItemId: string,
    connection: string
  ): Promise<string> => {
    if (currentItemId === selectedItemId) {
      return "Cannot associate an item to itself.";
    }
    const itemClient = xemelgoClient.getItemClient();

    const { maxConnections: maxCurrentConnections, label: connectionLabel } = relationshipMap[connection];
    const oppositeDirection = OPPOSITE_DIRECTION_RELATIONSHIP_MAP[connection];
    const { maxConnections: maxOppositeConnections, label: oppositeConnectionLabel } =
      relationshipMap[oppositeDirection];
    const items = await itemClient.getItemsByIds([currentItemId, selectedItemId], undefined, undefined, [
      connection,
      oppositeDirection
    ]);
    if (maxCurrentConnections) {
      const item = items?.find((item: ItemV2) => {
        return currentItemId === item.getId();
      });

      const rawItemData = item?.getResponseData();
      const currentConnections = rawItemData[connection] || [];
      if (currentConnections.length >= maxCurrentConnections) {
        return `Current item has too many ${connectionLabel}(s)`;
      }
    }

    if (maxOppositeConnections) {
      const item = items?.find((item: ItemV2) => {
        return selectedItemId === item.getId();
      });

      const rawItemData = item?.getResponseData();
      const oppositeConnections = rawItemData[oppositeDirection] || [];
      if (oppositeConnections.length >= maxOppositeConnections) {
        return `Selected item has too many ${oppositeConnectionLabel}(s)`;
      }
    }

    return "";
  };

  const attachItems = async (mainItemId: string, selectedItemId: string, connection: string) => {
    const itemClient = xemelgoClient.getItemClient();
    const isReversed = connection === CONNECTIONS_MAP.HAS_PART || connection === CONNECTIONS_MAP.IS_CONTAINED_IN;
    const fromId = isReversed ? selectedItemId : mainItemId;
    const toId = isReversed ? mainItemId : selectedItemId;

    switch (connection) {
      case CONNECTIONS_MAP.HAS_PART:
      case CONNECTIONS_MAP.PART_OF:
        await itemClient.attachPartToItem(fromId, toId);
        break;
      case CONNECTIONS_MAP.IS_CONTAINED_IN:
      case CONNECTIONS_MAP.CONTAINS:
        await itemClient.addItemToContainer(fromId, toId);
      default:
        break;
    }
  };

  const detachItems = async (mainItemId: string, selectedItemId: string, connection: string) => {
    const itemClient = xemelgoClient.getItemClient();
    const isReversed = connection === CONNECTIONS_MAP.HAS_PART || connection === CONNECTIONS_MAP.IS_CONTAINED_IN;
    const fromId = isReversed ? selectedItemId : mainItemId;
    const toId = isReversed ? mainItemId : selectedItemId;

    switch (connection) {
      case CONNECTIONS_MAP.HAS_PART:
      case CONNECTIONS_MAP.PART_OF:
        await itemClient.detachPartFromItem(fromId, toId);
        break;
      case CONNECTIONS_MAP.IS_CONTAINED_IN:
      case CONNECTIONS_MAP.CONTAINS:
        await itemClient.removeItemFromContainer(fromId, toId);
        break;
      default:
        break;
    }
  };

  return {
    verifyItemConnections,
    attachItems,
    detachItems
  };
};
