import type { Header } from "../../../../../components/paginated-list-table-with-types";
import { VALUE_TYPE_MAP } from "../../../../../components/paginated-list-table-with-types/data/constants";

export const DEFAULT_TRANSFER_ORDER_CONTAINER_OVERVIEW_TABLE_HEADERS: Header[] = [
  {
    id: "status",
    label: "Status",
    type: VALUE_TYPE_MAP.text
  },
  {
    id: "count",
    label: "Item Count",
    type: VALUE_TYPE_MAP.number
  }
];
