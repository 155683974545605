import { VALUE_TYPE_MAP } from "../../../../../../../components/paginated-list-table-with-types/data/constants";
import xemelgoStyle from "../../../../../../../styles/variable";
import { TransferOrderStage } from "./types";

export const ITEM_TYPE_STATUS_MAP = {
  complete: {
    id: "complete",
    label: "Complete",
    backgroundColor: xemelgoStyle.theme.STATUS_GREEN,
    sortIndex: 3
  },
  partial: {
    id: "partial",
    label: "Partial",
    backgroundColor: xemelgoStyle.theme.STATUS_YELLOW,
    textColor: xemelgoStyle.theme.TEXT_PRIMARY,
    sortIndex: 1
  },
  extraScanned: {
    id: "extraScanned",
    label: "Extra Scanned",
    backgroundColor: xemelgoStyle.theme.STATUS_RED,
    sortIndex: 0
  },
  notScanned: {
    id: "notScanned",
    label: "Not Scanned",
    backgroundColor: xemelgoStyle.theme.NEUTRAL_PRIMARY,
    sortIndex: 2
  }
};

export const ITEM_SCAN_STATUS_MAP = {
  SCANNED: {
    id: "scanned",
    label: "Scanned",
    backgroundColor: xemelgoStyle.theme.STATUS_GREEN,
    sortIndex: 2
  },
  NOT_SCANNED: {
    id: "notScanned",
    label: "Not Scanned",
    backgroundColor: xemelgoStyle.theme.NEUTRAL_PRIMARY,
    sortIndex: 1
  },
  INVALID: {
    id: "invalid",
    label: "Invalid",
    backgroundColor: xemelgoStyle.theme.STATUS_RED,
    sortIndex: 0
  }
};

export const STAGES = {
  STAGE: "stage",
  SHIP: "ship",
  RECEIVE: "receive",
  VERIFY: "verify"
};

export const DEFAULT_STAGE_OPTIONS = [
  {
    index: 0,
    label: "Stage",
    value: STAGES.STAGE
  },
  {
    index: 1,
    label: "Ship",
    value: STAGES.SHIP
  },
  {
    index: 2,
    label: "Receive",
    value: STAGES.RECEIVE
  },
  {
    index: 3,
    label: "Verify",
    value: STAGES.VERIFY
  }
];

export const TRANSFER_ORDER_STATUS = {
  ADDED: "Added",
  CREATED: "Created",
  STAGING_IN_PROGRESS: "StagingInProgress",
  STAGED: "Staged",
  SHIPPING_IN_PROGRESS: "ShippingInProgress",
  SHIPPED: "InProgress",
  RECEIVING_IN_PROGRESS: "ReceivingInProgress",
  RECEIVED: "Received",
  VERIFYING_IN_PROGRESS: "VerifyingInProgress",
  VERIFIED: "Verified",
  CANCELLED: "Cancelled"
};

export const TRANSFER_STATUS_MAP = {
  [TRANSFER_ORDER_STATUS.ADDED]: {
    displayText: "Created",
    label: "Created",
    color: xemelgoStyle.theme.NEUTRAL_PRIMARY,
    sortIndex: 0
  },
  [TRANSFER_ORDER_STATUS.CREATED]: {
    displayText: "Created",
    label: "Created",
    color: xemelgoStyle.theme.NEUTRAL_PRIMARY,
    sortIndex: 1
  },
  [TRANSFER_ORDER_STATUS.STAGING_IN_PROGRESS]: {
    displayText: "Staging in Progress",
    label: "Staging in Progress",
    color: xemelgoStyle.theme.NEUTRAL_PRIMARY,
    sortIndex: 3
  },
  [TRANSFER_ORDER_STATUS.STAGED]: {
    displayText: "Staged",
    label: "Staged",
    color: xemelgoStyle.theme.NEUTRAL_PRIMARY,
    sortIndex: 4
  },
  [TRANSFER_ORDER_STATUS.SHIPPING_IN_PROGRESS]: {
    displayText: "Partially Shipped",
    label: "Partially Shipped",
    color: xemelgoStyle.theme.STATUS_YELLOW,
    sortIndex: 5
  },
  [TRANSFER_ORDER_STATUS.SHIPPED]: {
    displayText: "Shipped",
    label: "Shipped",
    color: xemelgoStyle.theme.STATUS_YELLOW,
    sortIndex: 6
  },
  [TRANSFER_ORDER_STATUS.RECEIVING_IN_PROGRESS]: {
    displayText: "Partially Received",
    label: "Partially Received",
    color: xemelgoStyle.theme.APP_BLUE,
    sortIndex: 7
  },
  [TRANSFER_ORDER_STATUS.RECEIVED]: {
    displayText: "Received",
    label: "Received",
    color: xemelgoStyle.theme.APP_BLUE,
    sortIndex: 8
  },
  [TRANSFER_ORDER_STATUS.VERIFYING_IN_PROGRESS]: {
    displayText: "Partially Verified",
    label: "Partially Verified",
    color: xemelgoStyle.theme.STATUS_GREEN,
    sortIndex: 9
  },
  [TRANSFER_ORDER_STATUS.VERIFIED]: {
    displayText: "Verified",
    label: "Verified",
    color: xemelgoStyle.theme.STATUS_GREEN,
    sortIndex: 10
  },
  [TRANSFER_ORDER_STATUS.CANCELLED]: {
    displayText: "Cancelled",
    label: "Cancelled",
    color: xemelgoStyle.theme.STATUS_RED,
    sortIndex: 11
  }
};

export const TRANSFER_ITEM_STATE_STATUS = {
  ...TRANSFER_ORDER_STATUS,
  CREATED: undefined,
  ADDED: "Added"
};

export const TRANSFER_ORDER_STAGES: Record<string, TransferOrderStage> = {
  [STAGES.STAGE]: {
    id: "stage",
    actionLabel: "stage",
    quantityName: "quantityStaged",
    submitStatus: TRANSFER_ORDER_STATUS.STAGED,
    statusLabel: "Staging in Progress"
  },
  [STAGES.SHIP]: {
    id: "ship",
    actionLabel: "ship",
    quantityName: "quantityInProgress",
    submitStatus: TRANSFER_ORDER_STATUS.SHIPPED,
    statusLabel: "Shipping in Progress"
  },
  [STAGES.RECEIVE]: {
    id: "receive",
    actionLabel: "receive",
    quantityName: "quantityReceived",
    submitStatus: TRANSFER_ORDER_STATUS.RECEIVED,
    statusLabel: "Receiving in Progress"
  },
  [STAGES.VERIFY]: {
    id: "verify",
    actionLabel: "verify",
    quantityName: "quantityVerified",
    submitStatus: TRANSFER_ORDER_STATUS.VERIFIED,
    statusLabel: "Verification in Progress"
  }
};

export const STAGE_TO_SHOWN_ITEM_STATUSES = {
  [STAGES.STAGE]: [TRANSFER_ITEM_STATE_STATUS.ADDED],
  [STAGES.SHIP]: [
    TRANSFER_ITEM_STATE_STATUS.ADDED,
    TRANSFER_ITEM_STATE_STATUS.STAGING_IN_PROGRESS,
    TRANSFER_ITEM_STATE_STATUS.STAGED
  ],
  [STAGES.RECEIVE]: [
    TRANSFER_ITEM_STATE_STATUS.ADDED,
    TRANSFER_ITEM_STATE_STATUS.STAGING_IN_PROGRESS,
    TRANSFER_ITEM_STATE_STATUS.STAGED,
    TRANSFER_ITEM_STATE_STATUS.SHIPPING_IN_PROGRESS,
    TRANSFER_ITEM_STATE_STATUS.SHIPPED
  ],
  [STAGES.VERIFY]: [
    TRANSFER_ITEM_STATE_STATUS.ADDED,
    TRANSFER_ITEM_STATE_STATUS.STAGING_IN_PROGRESS,
    TRANSFER_ITEM_STATE_STATUS.STAGED,
    TRANSFER_ITEM_STATE_STATUS.SHIPPED,
    TRANSFER_ITEM_STATE_STATUS.SHIPPING_IN_PROGRESS,
    TRANSFER_ITEM_STATE_STATUS.RECEIVED,
    TRANSFER_ITEM_STATE_STATUS.RECEIVING_IN_PROGRESS
  ]
};

export const DEFAULT_ITEM_TYPE_DISPLAY_NAME = "Item Type";

export const DEFAULT_EPC_TABLE_HEADERS = [
  { id: "vid", label: "EPC", isNavLink: true, openLinkInNewTab: true, type: VALUE_TYPE_MAP.text },
  { id: "status", label: "Status", type: VALUE_TYPE_MAP.status, defaultSort: true }
];

export const QR_CODE_COLUMN_TYPE = "QRCode";

export const DEFAULT_UNEXPECTED_ITEMS_TABLE_HEADERS = [
  {
    id: "itemType_identifier",
    label: DEFAULT_ITEM_TYPE_DISPLAY_NAME,
    type: "text"
  },
  {
    id: "identifier",
    label: "Item",
    type: "text"
  },
  {
    id: "reason",
    label: "Reason",
    type: "text"
  },
  {
    id: "vid",
    label: "",
    type: QR_CODE_COLUMN_TYPE
  }
];

export const GPO_SCENARIOS = {
  START: "start",
  UNEXPECTED_ITEM: "unexpectedItem",
  ALL_ITEMS_SCANNED: "allItemsScanned",
  MISSING_ITEM: "missingItem",
  MISSING_ITEM_ON_SUBMIT: "missingItemOnSubmit",
  UNEXPECTED_ITEM_ON_SUBMIT: "unexpectedItemOnSubmit",
  SUBMIT_START: "submitStart",
  SUBMIT_SUCCESS: "submitSuccess",
  SUBMIT_FAILURE: "submitFailure",
  STOP: "stop"
};
