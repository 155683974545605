export const getDetailsPageLink = (nodeType, subCategory, id, transferOrderClass = "") => {
  switch (nodeType) {
    case "item":
      switch (subCategory) {
        case "Part":
          return `/work-order/part/detail?itemId=${id}`;
        case "Traveller":
          return `/work-order/detail?itemId=${id}`;
        case "Asset":
          return `/asset/detail?itemId=${id}`;
        case "Inventory":
        case "Stock":
          return `/inventory/item/detail?itemId=${id}`;
        // TODO: Move transfer order from here since it is not an item nodeType
        case "TransferOrder":
          switch (transferOrderClass) {
            case "Package":
              return `/package/order-detail?itemId=${id}`;
            default:
              return `/inventory/transfer-order-detail/detail?id=${id}`;
          }
        case "Container":
          // TODO: Navigate to transfer order solution if container is in sssa transfer order
          // TODO: When sub_class is added to items, use that to detemine which solution to navigate to
          return `/inventory/detail/container?id=${id}`;
        default:
          return `/${subCategory}/detail?itemId=${id}`;
      }
    case "itemType":
    default:
      return `/item-types-management/detail?id=${id}`;
  }
};
