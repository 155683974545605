import React, { useContext, useState, useEffect } from "react";
import { useAppConfigProvider } from "../../../../services/soft-cache-service";
import {
  TransferOrderTrackPageConfigContextType,
  MetricsControl,
  ChartControl,
  ListTableControl,
  TableViewTypeControl,
  StatusMap,
  SideFilterControl,
  UnknownLocationControl,
  FeatureButton
} from "./data/types";

const SOLUTION_ID = "transferOrder";
const TRACK_PAGE_FEATURE_ID = "trackPage";

const initialState: TransferOrderTrackPageConfigContextType = {
  isLoading: true,
  metricsControl: [
    {
      id: "totalCount",
      label: "TOTAL TRANSFER ORDERS"
    },
    {
      id: "createdCount",
      label: "CREATED"
    },
    { id: "stagingInProgressCount", label: "STAGING" },
    {
      id: "stagedCount",
      label: "STAGED"
    },
    { id: "shippingInProgressCount", label: "SHIPPING" },
    {
      id: "shippedCount",
      label: "SHIPPED"
    },
    { id: "receivingInProgressCount", label: "RECEIVING" },
    {
      id: "receivedCount",
      label: "RECEIVED"
    },
    {
      id: "verifyingInProgressCount",
      label: "VERIFYING"
    },
    {
      id: "verifiedCount",
      label: "VERIFIED"
    }
  ],
  chartsControl: [
    {
      tableViewType: ["all"],
      id: "status-bar-chart",
      label: "Transfer Order Statuses",
      dataControl: [
        { label: "Created", values: [{ id: "createdCount", color: "", label: "Created" }] },
        {
          label: "Staged",
          values: [
            { id: "stagedCount", color: "", label: "Staged" },
            { id: "stagingInProgressCount", color: "#E2E2EA", label: "Staging In Progress" }
          ]
        },
        {
          label: "Shipped",
          values: [
            { id: "shippedCount", color: "", label: "Shipped" },
            { id: "shippingInProgressCount", color: "#E2E2EA", label: "Shipping In Progress" }
          ]
        },
        {
          label: "Received",
          values: [
            { id: "receivedCount", color: "", label: "Received" },
            { id: "receivingInProgressCount", color: "#E2E2EA", label: "Receiving In Progress" }
          ]
        },
        {
          label: "Verified",
          values: [
            { id: "verifiedCount", color: "", label: "Verified" },
            { id: "verifyingInProgressCount", color: "#E2E2EA", label: "Verifying In Progress" }
          ]
        }
      ]
    }
  ],
  listTableControl: {
    transferOrder: {
      headers: [
        { id: "identifier", label: "Order #", isNavLink: true, viewTypes: ["all"] },
        { id: "locationName", label: "Last Activity At" },
        { id: "nodeLastUpdatedTime", label: "Last Updated", type: "dateTime" },
        { id: "transferStatus", label: "Status", type: "status" },
        {
          id: "scannedQty",
          label: "Scanned Qty",
          type: "progressBar",
          progressBarValueAttribute: "total${highLevelTransferStatus}Items",
          progressBarMaxValueAttribute: "totalItems"
        },
        {
          id: "completedSkus",
          label: "Completed SKUs",
          type: "progressBar",
          progressBarValueAttribute: "total${highLevelTransferStatus}Entries",
          progressBarMaxValueAttribute: "totalEntries"
        }
      ]
    }
  },
  tableViewTypeControl: {
    transferOrder: [
      {
        id: "all",
        label: "All",
        includeStatuses: [
          "Created",
          "StagingInProgress",
          "Staged",
          "ShippingInProgress",
          "Shipped",
          "ReceivingInProgress",
          "Received",
          "VerifyingInProgress",
          "Verified"
        ]
      },
      { id: "created", label: "Created", includeStatuses: ["Created"] },
      { id: "staged", label: "Staged", includeStatuses: ["Staged", "StagingInProgress"] },
      { id: "shipped", label: "Shipped", includeStatuses: ["Shipped", "ShippingInProgress"] },
      { id: "received", label: "Received", includeStatuses: ["Received", "ReceivingInProgress"] },
      { id: "verified", label: "Verified", includeStatuses: ["Verified", "VerifyingInProgress"] }
    ]
  },
  statusMap: {
    Created: { label: "Created", color: "#737880" },
    StagingInProgress: { label: "Partially Staged", color: "#737880" },
    Staged: { label: "Staged", color: "#737880" },
    ShippingInProgress: { label: "Partially Shipped", color: "#f6b044", textColor: "black" },
    Shipped: { label: "Shipped", color: "#f6b044", textColor: "black" },
    ReceivingInProgress: { label: "Partially Received", color: "#4a90ff" },
    Received: { label: "Received", color: "#4a90ff" },
    VerifyingInProgress: { label: "Partially Verified", color: "#4b9910" },
    Verified: { label: "Verified", color: "#4b9910" }
  },
  sideFilterControl: [],
  unknownLocationControl: {
    properties: {
      id: "unknown-location-112358",
      name: "Unknown",
      identifier: "Unknown"
    }
  },
  featureButtonsControl: [
    {
      id: "add-transfer-order",
      roles: ["admin", "user"],
      disabled: false
    }
  ],
  queryPageLimit: 1000
};

const TransferOrderTrackPageConfigContext = React.createContext<TransferOrderTrackPageConfigContextType>(initialState);

export const useTransferOrderTrackPageConfigContext = () => {
  return useContext(TransferOrderTrackPageConfigContext);
};

export const TransferOrderTrackPageConfigContextProvider = ({ children }: any) => {
  const transferOrderSolutionConfigProvider = useAppConfigProvider(SOLUTION_ID);

  const [metricsControl, setMetricsControl] = useState<MetricsControl[]>(initialState.metricsControl);
  const [chartsControl, setChartsControl] = useState<ChartControl[]>(initialState.chartsControl);
  const [listTableControl, setListTableControl] = useState<ListTableControl>(initialState.listTableControl);
  const [tableViewTypeControl, setTableViewTypeControl] = useState<TableViewTypeControl>(
    initialState.tableViewTypeControl
  );
  const [statusMap, setStatusMap] = useState<StatusMap>(initialState.statusMap);
  const [sideFilterControl, setSideFilterControl] = useState<SideFilterControl[]>(initialState.sideFilterControl);
  const [unknownLocationControl, setUnknownLocationControl] = useState<UnknownLocationControl>(
    initialState.unknownLocationControl
  );
  const [featureButtonsControl, setFeatureButtonsControl] = useState<FeatureButton[]>(
    initialState.featureButtonsControl
  );
  const [queryPageLimit, setQueryPageLimit] = useState<number>(initialState.queryPageLimit);

  const [isLoading, setIsLoading] = useState<boolean>(initialState.isLoading);

  useEffect(() => {
    setIsLoading(true);
    const trackPageConfig = transferOrderSolutionConfigProvider.getValue(TRACK_PAGE_FEATURE_ID, "object") || {};
    const {
      listTableControl: newListTableControl = initialState.listTableControl,
      tableViewTypeControl: newTableViewTypeControl = initialState.tableViewTypeControl,
      sideFilterControl: newSideFilterControl = initialState.sideFilterControl,
      metricsControl: newMetricsControl = initialState.metricsControl,
      chartsControl: newChartsControl = initialState.chartsControl,
      unknownLocationControl: newUnknownLocationControl = initialState.unknownLocationControl,
      featureButtonsControl: newFeatureButtonsControl = initialState.featureButtonsControl,
      statusMap: newStatusMap = initialState.statusMap,
      queryPageLimit: newQueryPageLimit = initialState.queryPageLimit
    } = trackPageConfig || {};

    setListTableControl(newListTableControl);
    setTableViewTypeControl(newTableViewTypeControl);
    setSideFilterControl(newSideFilterControl);
    setMetricsControl(newMetricsControl);
    setChartsControl(newChartsControl);
    setUnknownLocationControl(newUnknownLocationControl);
    setStatusMap(newStatusMap);
    setFeatureButtonsControl(newFeatureButtonsControl);
    setQueryPageLimit(newQueryPageLimit);

    setIsLoading(false);
  }, [transferOrderSolutionConfigProvider]);

  return (
    <TransferOrderTrackPageConfigContext.Provider
      value={{
        isLoading,
        metricsControl,
        chartsControl,
        listTableControl,
        tableViewTypeControl,
        statusMap,
        sideFilterControl,
        unknownLocationControl,
        featureButtonsControl,
        queryPageLimit
      }}
    >
      {children}
    </TransferOrderTrackPageConfigContext.Provider>
  );
};
