import { TagType } from "../hooks/use-mounted-reader/data/types";

export const queryForLatestDetectionsByReader = async (apiUrl: string, detectorSerial: string) => {
  const requestOptions: RequestInit = {
    method: "GET",
    redirect: "follow"
  };

  return fetch(`${apiUrl}${detectorSerial}/recent-tags`, requestOptions);
};

export const getTagsSinceTimestamp = async (
  apiUrl: string,
  detectorSerial: string,
  timestamp: number
): Promise<TagType[]> => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      deviceId: detectorSerial,
      timeStampSince: timestamp
    })
  };
  try {
    const response = await fetch(`${apiUrl}/streaming-tags`, requestOptions);
    const results = await response.json();
    return results;
  } catch (e) {
    return [];
  }
};

export const updateDeviceState = async (
  apiUrl: string,
  detectorSerial: string,
  vendor: string,
  state: string,
  transmitPower = 25
) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      vendor: vendor.toLowerCase(),
      state,
      transmitPower
    })
  };

  try {
    const result = await fetch(`${apiUrl}/devices/${detectorSerial}/state`, requestOptions);
    return result;
  } catch (e) {
    return Promise.resolve({});
  }
};
