import React, { useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { isValidPhoneNumber, getCountryCallingCode } from "react-phone-number-input";
import { IconButton, InputAdornment } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import Style from "./DetailsForm.module.css";
import { isValidEmail } from "../../common/Utilities";
import PhoneNumberInput from "../add-page-component/add-page-inputs/PhoneNumberInput";
import TextInput from "../add-page-component/add-page-inputs/TextInput";
import {
  useInputStyle,
  usePhoneInputStyle,
  useInputIconStyle
} from "../add-page-component/add-page-inputs/AddPageInputsStyle";

const getInputComponent = (type) => {
  switch (type) {
    case "phone":
      return PhoneNumberInput;
    case "input":
    case "email":
    default:
      return TextInput;
  }
};

const DetailsForm = ({ data, onChangeFn, isVendorClientUser }) => {
  const [formData, setFormData] = useState(data);

  const inputStyle = useInputStyle({ width: "20vw" });
  const phoneInputStyle = usePhoneInputStyle({ width: "17vw" });
  const inputIconStyle = useInputIconStyle();

  const onFormDataChange = (id, value) => {
    if (id) {
      const { type, required } = formData[id];
      const formDataToUpdate = { ...formData, [id]: { ...formData[id], value } };

      if (id === "phone") {
        formDataToUpdate[id].formattedValue = "";
      }
      if (!value && !required) {
        formDataToUpdate[id].error = false;
        formDataToUpdate[id].errorMessage = "";
      }

      setFormData(formDataToUpdate);
      onChangeFn(formDataToUpdate);
    }
  };

  const onBlur = (event, countryCode) => {
    const { id } = event.target;
    const { value, required, type, label } = formData[id];
    const updatedData = _.cloneDeep(formData);
    let error;
    let errorMessage;
    let valueToUpdate = value || "";

    if (typeof value === "string") {
      valueToUpdate = valueToUpdate.trim();
    }

    if (required && !valueToUpdate.length) {
      error = true;
      errorMessage = `${label} is a required field`;
    } else if (type === "email" && valueToUpdate) {
      valueToUpdate = valueToUpdate.toLowerCase();
      const validEmail = isValidEmail(valueToUpdate);
      if (!validEmail) {
        error = true;
        errorMessage = `Please enter a valid email address`;
      }
    } else if (type === "phone" && valueToUpdate) {
      const valueToValidate = `+${getCountryCallingCode(countryCode)}${valueToUpdate}`;
      const validPhoneNumber = isValidPhoneNumber(valueToValidate);
      if (!validPhoneNumber) {
        error = true;
        errorMessage = `Please enter a valid phone number`;
      }
      updatedData[id].formattedValue = valueToValidate;
    } else if (type === "roleSection") {
      error = true;
      errorMessage = `${label} is required. Please select an option.`;
    }

    updatedData[id].error = error;
    updatedData[id].errorMessage = errorMessage;
    updatedData[id].value = valueToUpdate;

    setFormData(updatedData);
    onChangeFn(updatedData);
  };

  const renderInput = (key) => {
    const { hiddenForVendorClientUser, type, value, label, required, options, disabled, error, errorMessage } =
      formData[key];
    if (isVendorClientUser && hiddenForVendorClientUser) {
      return null;
    }
    if (type === "roleSection") {
      return (
        <div
          className={Style.radio_group_container}
          key={key}
        >
          <label>
            {label}
            {<span className={Style.required_asterisk}>*</span>}
          </label>
          {options.map((option) => {
            return (
              <div
                key={option.id}
                className={Style.radio_item}
              >
                <input
                  type="radio"
                  checked={value === option.id}
                  onChange={() => onFormDataChange(key, option.id)}
                  disabled={disabled}
                />
                <span className={Style.radio_label}>{option.label}</span>
              </div>
            );
          })}
          <p className={Style.input_error_text}>{errorMessage}</p>
        </div>
      );
    }

    const InputByTypeComponent = getInputComponent(type);
    return (
      <div
        className={type === "roleSection" ? Style.radio_group_container : Style.input_container}
        key={key}
      >
        <InputByTypeComponent
          id={key}
          value={value}
          label={label}
          required={required}
          options={options}
          disabled={disabled}
          onChange={onFormDataChange}
          onBlur={onBlur}
          error={error}
          InputProps={{
            classes: type === "phone" ? phoneInputStyle : inputStyle,
            endAdornment: !disabled && (
              <InputAdornment position="end">
                <IconButton
                  disableRipple
                  classes={inputIconStyle}
                  size="small"
                  onClick={() => onFormDataChange(key, "")}
                  tabIndex={-1}
                >
                  <ClearIcon
                    fontSize="small"
                    hidden={false}
                  />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <p className={Style.input_error_text}>{errorMessage}</p>
      </div>
    );
  };

  return (
    <div className={Style.form_container}>
      <div className={Style.form_content}>
        {Object.keys(formData)
          .sort((a, b) => formData[a].index - formData[b].index)
          .map((key) => renderInput(key))}
      </div>
    </div>
  );
};

export default DetailsForm;

DetailsForm.defaultProps = {
  isVendorClientUser: false
};

DetailsForm.propTypes = {
  data: PropTypes.object.isRequired,
  onChangeFn: PropTypes.func.isRequired,
  isVendorClientUser: PropTypes.bool
};
