import { useMemo } from "react";
import { useSearchParams, useLocation } from "react-router-dom-v5-compat";

const SEARCH_PARAM_NAME = {
  id: "id",
  tab: "tab"
};

export const useDetailsPageSearchParams = (expectedPath: string) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();

  const areSearchParamsReady = useMemo(() => {
    return !!expectedPath && pathname.includes(expectedPath);
  }, [expectedPath, pathname]);

  const setSelectedTabLabel = (newTab: string) => {
    setSearchParams((prev) => {
      if (!newTab) {
        prev.delete(SEARCH_PARAM_NAME.tab);
      } else {
        prev.set(SEARCH_PARAM_NAME.tab, newTab);
      }
      return prev;
    });
  };

  return {
    areSearchParamsReady,
    selectedTabLabel: searchParams.get(SEARCH_PARAM_NAME.tab) || "",
    setSelectedTabLabel,
    id: searchParams.get(SEARCH_PARAM_NAME.id) || ""
  };
};
