import { useMemo, useEffect, useState } from "react";
import { useSearchParams, useLocation } from "react-router-dom-v5-compat";
import { SEARCH_PARAM_NAME } from "./data/constants";

export const useTransferOrderTrackPageSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const location = useLocation();

  const selectedViewTypeId = searchParams.get(SEARCH_PARAM_NAME.selectedViewTypeId) || "";

  const sideFilterValueString = searchParams.get(SEARCH_PARAM_NAME.sideFilterValue);

  const setSelectedViewTypeId = (newSelectedViewTypeId) => {
    setSearchParams(
      (prev) => {
        if (!newSelectedViewTypeId) {
          prev.delete(SEARCH_PARAM_NAME.selectedViewTypeId);
        } else {
          prev.set(SEARCH_PARAM_NAME.selectedViewTypeId, newSelectedViewTypeId);
        }
        return prev;
      },
      { replace: true }
    );
  };
  const sideFilterValue = useMemo(() => {
    return JSON.parse(sideFilterValueString || "{}");
  }, [sideFilterValueString]);

  const setSideFilterValue = (newSideFilterValue) => {
    setSearchParams(
      (prev) => {
        if (!newSideFilterValue || !Object.keys(newSideFilterValue || {}).length) {
          prev.delete(SEARCH_PARAM_NAME.sideFilterValue);
        } else {
          prev.set(SEARCH_PARAM_NAME.sideFilterValue, JSON.stringify(newSideFilterValue));
        }
        return prev;
      },
      {
        replace: true
      }
    );
  };

  const [isSearchParamReady, setIsSearchParamReady] = useState(false);

  useEffect(() => {
    if (location.pathname === "/transfer-order") {
      setIsSearchParamReady(true);
    }
  }, [location.pathname]);

  return {
    selectedViewTypeId,
    setSelectedViewTypeId,
    sideFilterValue,
    setSideFilterValue,
    isSearchParamReady
  };
};
