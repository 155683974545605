import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import Style from "./UploadCsvFeature.module.css";
import generateCsvTemplate from "./utils/generate-csv-template";
import { SOLUTION_UNITS } from "../../data/constants";
import UploadedFileInfoSection from "./components/uploaded-file-info-section";
import FileDropOffSection from "./components/file-dropoff-section";
import ImportErrorSummary from "./features/import-errors-summary";
import UploadStatusComponent from "./components/upload-status-component";

export const UploadCsvFeature = ({
  csvHeadersMap,
  solution,
  errorResolveModal: ErrorResolveModal,
  onShowErrorResolveModal,
  csvDataMap,
  onCsvDataChange
}) => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadMessage, setUploadMessage] = useState("");
  const [uploadError, setUploadError] = useState(false);
  const [numberOfInvalidItem, setNumberOfInvalidItem] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);

  // get csv headers list
  const csvHeaders = useMemo(() => {
    return Object.values(csvHeadersMap).map((headerObject) => {
      const { label, isRequired } = headerObject;

      // add a asterisk to a required csv header
      return `${label}${isRequired ? "*" : ""}`;
    });
  }, [csvHeadersMap]);

  const importErrorSummaryDataMap = useMemo(() => {
    const errorMap = {};
    let index = 1;
    Object.values(csvDataMap)
      .filter((csvRow) => {
        return csvRow.isError;
      })
      .forEach((csvRow) => {
        const { rowNumber, data } = csvRow;
        Object.values(data).forEach((eachField) => {
          const { errorMessage } = eachField;
          if (errorMessage) {
            if (!errorMap[errorMessage]) {
              errorMap[errorMessage] = {
                index: index++,
                error_type: errorMessage,
                num_of_affected_row: 1,
                affected_row_list: rowNumber
              };
              return;
            }
            errorMap[errorMessage].num_of_affected_row++;
            errorMap[errorMessage].affected_row_list += `, ${rowNumber}`;
          }
        });
      });

    return errorMap;
  }, [csvDataMap]);

  const handleRemove = () => {
    setUploadedFile(null);
    setUploadError(false);
    setUploadProgress(0);
    setUploadMessage("");
    setNumberOfInvalidItem(0);
    onShowErrorResolveModal(false);
    onCsvDataChange({});
  };

  const handleOnError = (errorMessage) => {
    setUploadError(true);
    setUploadMessage(errorMessage);
  };

  // This useEffect control the message display to the user based on the number of invalid items
  useEffect(() => {
    if (!uploadedFile && !Object.keys(csvDataMap).length) {
      return;
    }

    if (numberOfInvalidItem) {
      setUploadMessage(
        `${numberOfInvalidItem}
           out of
          ${Object.keys(csvDataMap).length}
          ${SOLUTION_UNITS[solution]}
           were not ready to be imported due to the incorrect data.`
      );
      setUploadError(true);
    } else {
      setUploadError(false);
      setUploadMessage(
        `${Object.keys(csvDataMap).length}
           ${SOLUTION_UNITS[solution]}
           ready to be imported.`
      );
    }
  }, [numberOfInvalidItem, csvDataMap, uploadedFile]);

  useEffect(() => {
    const newNumberOfInvalidItems = Object.values(csvDataMap).reduce((count, csvRow) => {
      if (csvRow.isError === true) {
        count++;
      }
      return count;
    }, 0);

    setNumberOfInvalidItem(newNumberOfInvalidItems);
  }, [csvDataMap]);

  if (uploadedFile) {
    return (
      <>
        <UploadedFileInfoSection
          onRemove={handleRemove}
          isError={uploadError}
          uploadMessageComponent={() => {
            return (
              <UploadStatusComponent
                uploadMessage={uploadMessage}
                uploadError={uploadError}
                uploadProgress={uploadProgress}
                showModalNavigatorMessage={numberOfInvalidItem > 0}
                onNavigatorClick={onShowErrorResolveModal}
              />
            );
          }}
          progressPercentage={uploadProgress}
          uploadedFileName={uploadedFile?.name}
        />

        {numberOfInvalidItem > 0 && (
          <ImportErrorSummary
            errorSummaryData={Object.values(importErrorSummaryDataMap)}
            solutionType={solution}
          />
        )}
        {ErrorResolveModal && <ErrorResolveModal />}
      </>
    );
  }

  return (
    <div className={Style.csv_upload_container}>
      <div className={Style.download_template_button_container}>
        <button
          type="button"
          className={`${Style.download_button}`}
          onClick={() => {
            generateCsvTemplate(csvHeaders);
          }}
        >
          Download Template
        </button>
      </div>
      <FileDropOffSection
        csvHeadersMap={csvHeadersMap}
        onUploadProgressChange={setUploadProgress}
        onFileUpload={setUploadedFile}
        onError={handleOnError}
        onDataInput={(newValidatedData) => {
          onCsvDataChange(newValidatedData);
        }}
      />
    </div>
  );
};

const FormHeadersMapType = PropTypes.objectOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    type: PropTypes.string,
    hidden: PropTypes.bool
  })
);

const itemEntryPropTypes = PropTypes.shape({
  value: PropTypes.string,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string
});

UploadCsvFeature.defaultProps = {
  errorResolveModal: null,
  onShowErrorResolveModal: () => {}
};

UploadCsvFeature.propTypes = {
  csvHeadersMap: FormHeadersMapType.isRequired,
  solution: PropTypes.string.isRequired,
  csvDataMap: PropTypes.shape({
    id: PropTypes.string,
    data: PropTypes.objectOf(itemEntryPropTypes),
    rowNumber: PropTypes.number,
    isError: PropTypes.bool
  }).isRequired,
  onCsvDataChange: PropTypes.func.isRequired,
  errorResolveModal: PropTypes.func,
  onShowErrorResolveModal: PropTypes.func
};
