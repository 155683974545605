import React, { useMemo, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Style from "./TransferOrderTable.module.css";
import PaginatedListTableWithTypes, {
  getValueByType,
  VALUE_TYPE_MAP
} from "../../../../../../components/paginated-list-table-with-types";
import useTransferOrderTrackPageDataSourceContext from "../../../../contexts/transfer-order-track-page-data-source-context";
import PartialLoadingMessage from "./components/partial-loading-message";
import useGetFilteredDataList from "./hooks/use-get-filtered-data-list";
import useTransferOrderTrackPageStateContext from "../../../../contexts/transfer-order-track-page-state-context";
import useDebounce from "../../../../../../hooks/use-debounce";
import useTransferOrderTrackPageConfigContext from "../../../../contexts/transfer-order-track-page-config-context";
import { exportCsv } from "../../../../../../common/Utilities";
import MultiSelectActionsBar from "../../../../../../components/multi-select-actions-bar";
import MultiSelectActionModal from "./features/multi-select-action-modal";
import useAuthenticationContext from "../../../../../../context/authentication-context";
import { interpolateTemplate } from "../../../../../../utils";

export const TransferOrderTable = () => {
  const history = useHistory();

  const { cognitoUser } = useAuthenticationContext();

  const { transferOrderState, locationTreeMap, isLocationTreeMapLoading } =
    useTransferOrderTrackPageDataSourceContext();

  const { dataList = [], isLoading, hasNextPage } = transferOrderState || {};

  const { freeTextSearchInputString, displayedTableHeadersMap, setExportCsvFn, selectedViewTypeId } =
    useTransferOrderTrackPageStateContext();

  const { listTableControl, statusMap } = useTransferOrderTrackPageConfigContext();

  const [selectedItemIdsMap, setSelectedItemIdsMap] = useState({});
  const [selectedMultiSelectAction, setSelectedMultiSelectAction] = useState({});

  const numOfSelectedItem = useMemo(() => {
    return Object.values(selectedItemIdsMap).filter((eachValue) => {
      return eachValue;
    }).length;
  }, [selectedItemIdsMap]);

  const csvFileName = useMemo(() => {
    return "Transfer Orders";
  }, [locationTreeMap, selectedViewTypeId]);

  const { enabled: multiSelectEnabled, availableActions } = useMemo(() => {
    return {
      enabled: false,
      availableActions: []
    };
  }, [listTableControl, cognitoUser]);

  const headers = useMemo(() => {
    return (displayedTableHeadersMap?.transferOrder?.[selectedViewTypeId] || [])
      ?.map((eachHeaderId) => {
        return listTableControl.transferOrder?.headers.find(({ id }) => {
          return eachHeaderId === id;
        });
      })
      .filter((each) => {
        return !!each;
      });
  }, [listTableControl, displayedTableHeadersMap, selectedViewTypeId]);

  const freeTextSearchInputTextDebounced = useDebounce(freeTextSearchInputString, 250);

  const displayedDataList = useMemo(() => {
    // Get all the headers that are of type progressBar
    const progressBarHeaders = headers.filter(({ type }) => {
      return type === VALUE_TYPE_MAP.progressBar;
    });

    return dataList.map((eachData) => {
      // Get the string values of the progressBar headers
      const progressBarValuesMap = progressBarHeaders.reduce((accumulator, eachProgressBarHeader) => {
        const { id, progressBarValueAttribute, progressBarMaxValueAttribute } = eachProgressBarHeader;

        let maxValue;
        let value;
        if (!progressBarValueAttribute) {
          value = 0;
        } else {
          value = interpolateTemplate(progressBarValueAttribute, eachData) || 0;
        }

        if (!progressBarMaxValueAttribute) {
          maxValue = 0;
        } else {
          maxValue = interpolateTemplate(progressBarMaxValueAttribute, eachData) || 0;
        }
        if (value >= maxValue || maxValue <= 0) {
          accumulator[id] = 100;
        } else if (value <= 0) {
          accumulator[id] = 0;
        } else {
          accumulator[id] = (value / maxValue) * 100;
        }
        return accumulator;
      }, {});

      return {
        ...eachData,
        transferStatus: statusMap[eachData.transferStatus] && [statusMap[eachData.transferStatus]],
        transferStatusString: eachData.transferStatus,
        ...progressBarValuesMap
      };
    });
  }, [dataList, statusMap, headers]);

  const filteredItemList = useGetFilteredDataList({
    freeTextSearchInputString: freeTextSearchInputTextDebounced,
    dataList: displayedDataList,
    headers
  });

  useEffect(() => {
    const csvData = filteredItemList.map((eachData) => {
      return {
        ...[...(headers || [])].reduce((accumulator, eachHeader) => {
          const { label } = eachHeader;
          accumulator[label] = getValueByType(eachData, eachHeader, true);
          return accumulator;
        }, {})
      };
    });

    setExportCsvFn(() => {
      return () => {
        exportCsv(
          csvData,
          {
            header: [
              ...headers.map((eachHeader) => {
                return eachHeader.label;
              })
            ],
            cellDates: true
          },
          csvFileName
        );
      };
    });
  }, [filteredItemList, headers, csvFileName]);

  const filteredItemListWithSelectedValue = useMemo(() => {
    return filteredItemList.map((eachItem) => {
      return { ...eachItem, isChecked: selectedItemIdsMap[eachItem.id] };
    });
  }, [filteredItemList, selectedItemIdsMap]);

  const selectedItems = filteredItemListWithSelectedValue.filter((eachItem) => {
    return eachItem.isChecked;
  });

  return (
    <div className={Style.container}>
      {hasNextPage && (
        <div
          className={`${Style.partial_loading_message_container} ${
            multiSelectEnabled &&
            !!numOfSelectedItem &&
            Style.partial_loading_message_container_with_multi_select_bar_enabled
          }`}
        >
          <PartialLoadingMessage />
        </div>
      )}
      {multiSelectEnabled && !!numOfSelectedItem && (
        <MultiSelectActionsBar
          numOfSelectedItem={numOfSelectedItem}
          multiSelectOptions={availableActions}
          onOptionClick={(action) => {
            setSelectedMultiSelectAction(action);
          }}
          onDeselectAllClick={() => {
            setSelectedItemIdsMap({});
          }}
        />
      )}
      <PaginatedListTableWithTypes
        useV2
        sortIdReplacementMap={{ transferStatus: "transferStatusString" }}
        onSelectClick={(ids, isSelected) => {
          const newSelectedItemIdsMap = ids.reduce(
            (accumulator, eachId) => {
              return { ...accumulator, [eachId]: isSelected };
            },
            { ...selectedItemIdsMap }
          );
          setSelectedItemIdsMap(newSelectedItemIdsMap);
        }}
        selectable={multiSelectEnabled}
        isLoading={isLocationTreeMapLoading || isLoading}
        paginatorLocation="top"
        headers={headers}
        getTitleURLCallbackFn={(eachItem) => {
          return `${history.location.pathname}/detail?id=${eachItem.id}`;
        }}
        dataList={filteredItemListWithSelectedValue}
        showRowsPerPage
        paginatorFontWeight="bold"
      />
      {multiSelectEnabled && selectedMultiSelectAction?.id && (
        <MultiSelectActionModal
          action={selectedMultiSelectAction}
          selectedItems={selectedItems}
          onModalClose={() => {
            setSelectedMultiSelectAction(null);
          }}
        />
      )}
    </div>
  );
};
