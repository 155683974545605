import { uploadImagesToS3 } from "../../../../../../common/Utilities";
import { LocalCacheService } from "../../../../../../services/local-cache-service";
import { XemelgoService } from "../../../../../../services/XemelgoService";

export const defaultEditImages = async (id: string, imagesToAdd: File[], imagesToRemove: string[]) => {
  const client = XemelgoService.getClient().getItemClient();

  if (!imagesToAdd.length && !imagesToRemove.length) {
    return;
  }

  const propertiesToUpdate = {
    images: {
      flagsToAdd: [] as string[],
      flagsToRemove: [] as string[]
    }
  };

  if (imagesToAdd.length) {
    const tenantId = LocalCacheService.loadUserProfile().getTenantId();
    propertiesToUpdate.images.flagsToAdd = await uploadImagesToS3(imagesToAdd, tenantId, "images");
  }
  if (imagesToRemove.length) {
    propertiesToUpdate.images.flagsToRemove = imagesToRemove;
    // Should remove the images from S3 but this is not allowed from the client.
  }
  await client.updateItemV2(id, propertiesToUpdate);
};
