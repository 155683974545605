import React, { useMemo } from "react";
import Status from "../../../../../../../../../../components/paginated-list-table-with-types/components/status";
import xemelgoStyle from "../../../../../../../../../../styles/variable";
import Style from "./SubmissionConfirmation.module.css";
import Modal from "../../../../../../../../../../components/modal";
import useKioskConfigContext from "../../../../../../../../contexts/kiosk-config-context";
import useKioskStateContext from "../../../../../../../../contexts/kiosk-state-context";
import { STATUS_OPTIONS } from "../../../../../../../../../../components/status-popup-component";
import useTransferOrderActionsConfigContext from "../../../../contexts/transfer-order-actions-config-context";
import { ItemTypeReport } from "../../../../../../../../data/types";
import { TransferOrderUpdate } from "../../utils/get-transfer-order-updates/getTransferOrderUpdates";

interface SubmissionConfirmationWorkflowProps {
  stageStatus: string;
  onCancel: () => void;
  onConfirm: () => void;
  transferOrderUpdates: TransferOrderUpdate[];
  containerIdentifier?: string;
  itemTypeReports: ItemTypeReport[];
}

export const SubmissionConfirmationModal = ({
  stageStatus,
  onCancel,
  onConfirm,
  transferOrderUpdates,
  containerIdentifier,
  itemTypeReports
}: SubmissionConfirmationWorkflowProps) => {
  const { action } = useKioskConfigContext();
  const { customText } = useTransferOrderActionsConfigContext();
  const { panelValues, submitStatus } = useKioskStateContext();

  const transferOrdersSummary = useMemo(() => {
    return transferOrderUpdates
      .map((transferOrderEvent) => {
        const { itemTypeEvents, identifier } = transferOrderEvent;
        const { expectedCount, scannedCount } = itemTypeEvents.reduce(
          (acc, itemTypeReport) => {
            acc.expectedCount += itemTypeReport.expectedCount;
            acc.scannedCount += itemTypeReport.scannedCount;
            return acc;
          },
          { expectedCount: 0, scannedCount: 0 }
        );

        return {
          identifier,
          scannedCount,
          expectedCount
        };
      })
      .sort((a, b) => {
        return a.identifier.localeCompare(b.identifier);
      });
  }, [transferOrderUpdates]);

  const itemTypeSummary = useMemo(() => {
    const collator = new Intl.Collator("en", { numeric: true, sensitivity: "base" });

    return itemTypeReports.sort((a, b) => {
      return collator.compare(a.identifier, b.identifier);
    });
  }, [itemTypeReports]);

  const numItems = useMemo(() => {
    return itemTypeReports.reduce((acc: number, curr) => {
      return acc + curr.quantityScanned;
    }, 0);
  }, [itemTypeReports]);

  return (
    <Modal
      title={action.label}
      onCancel={onCancel}
      onConfirm={onConfirm}
      confirmDisabled={submitStatus === STATUS_OPTIONS.LOADING}
    >
      <div className={Style.top_section}>
        <div className={Style.top_section_item}>
          <p className={Style.header_text}>{customText.transferOrderIdentifierLabel}</p>
          {panelValues.transferOrderIdentifiersString}
        </div>
        {containerIdentifier && (
          <div className={Style.top_section_item}>
            <p className={Style.header_text}>{customText.containerIdentifierLabel}</p>
            {containerIdentifier}
          </div>
        )}
      </div>
      <div className={Style.summary_container}>
        {transferOrdersSummary.length > 1 ? (
          <>
            <p className={Style.header_text}>Summary</p>
            {transferOrdersSummary.map((transferOrder) => {
              const { identifier, scannedCount, expectedCount } = transferOrder;
              return (
                <div
                  key={identifier}
                  className={Style.summary_table_row}
                >
                  <div className={Style.summary_table_row_left}>
                    <p className={Style.main_row_text}>{identifier}</p>
                    <div className={Style.row}>
                      Status:
                      <Status
                        statusList={[
                          {
                            label: stageStatus,
                            backgroundColor: xemelgoStyle.theme.APP_BLUE
                          }
                        ]}
                      />
                    </div>
                  </div>
                  <p className={Style.secondary_text}>{`${scannedCount}/${expectedCount} items scanned`}</p>
                </div>
              );
            })}
          </>
        ) : (
          <>
            <p className={Style.header_text}>{`Items Scanned (${numItems})`}</p>
            {itemTypeSummary.map((transferOrder) => {
              const { identifier, quantityScanned } = transferOrder;
              return (
                <div
                  key={identifier}
                  className={Style.summary_table_row}
                >
                  <p className={Style.main_row_text}>{identifier}</p>
                  <p className={Style.secondary_text}>
                    {`Qty: `}
                    <span className={Style.bold_text}>{quantityScanned}</span>
                  </p>
                </div>
              );
            })}
          </>
        )}
      </div>
    </Modal>
  );
};
