import { SOLUTIONS } from "../../../../../data/constants";
import type { DetailsPageConfigType } from "./types";

const DEFAULT_TRANSFER_ORDER_DETAILS_CONFIG: DetailsPageConfigType = {
  additionalAttributes: {
    item: ["class"],
    itemType: ["image_path"]
  },
  customText: {
    title: "Transfer Order Details",
    subtitle: "Tracking #"
  },
  detailsCard: {
    fields: [
      {
        key: "node_last_updated_time",
        label: "Last Updated",
        editable: false,
        type: "date"
      },
      {
        key: "node_start_time",
        label: "Created",
        editable: false,
        type: "date"
      },
      {
        key: "fromLocationName",
        label: "From Location",
        editable: false
      },
      {
        key: "toLocationName",
        label: "To Location",
        editable: false
      }
    ]
  },
  tabs: [
    {
      features: [
        {
          id: "transferOrderItemTypesTable",
          title: "Shipment Details"
        },
        {
          id: "transferOrderContainersTable",
          title: "Pallet Details"
        }
      ]
    }
  ],
  subfeatureOptions: {}
};

const DEFAULT_CONTAINER_DETAILS_CONFIG: DetailsPageConfigType = {
  additionalAttributes: {
    item: []
  },
  customText: {
    title: "Pallet Details",
    subtitle: "Pallet #"
  },
  detailsCard: {
    fields: [
      {
        key: "transferOrder",
        label: "Transfer Order #",
        type: "internalLink",
        linkOptions: {
          displayTextField: "identifier",
          path: "/inventory/transfer-order-detail/detail?id="
        }
      },
      {
        key: "totalItems",
        label: "Total Items"
      },
      {
        key: "trackerSerial",
        label: "Tag #"
      },
      {
        key: "node_last_updated_time",
        label: "Last Updated",
        type: "date"
      }
    ]
  },
  tabs: [
    {
      label: "Summary",
      features: [
        {
          id: "transferOrderContainerOveriewTable",
          title: "Overall Status"
        },
        {
          id: "transferOrderItemsTable",
          title: "Associated Items"
        }
      ]
    }
  ],
  subfeatureOptions: {}
};

export const SOLUTION_CONFIGS: { [solutionId: string]: DetailsPageConfigType } = {
  [SOLUTIONS.TRANSFER_ORDER]: DEFAULT_TRANSFER_ORDER_DETAILS_CONFIG,
  [SOLUTIONS.INVENTORY_CONTAINER]: DEFAULT_CONTAINER_DETAILS_CONFIG
};
