import UpdateTransferOrdersButton from "../features/update-transfer-orders-button";
import AddTransferOrderButton from "../features/add-transfer-order-button";

export const FEATURE_BUTTON_ID_MAP = {
  updateTransferOrders: "update-transfer-orders",
  addTransferOrder: "add-transfer-order"
};

export const FEATURE_BUTTON_COMPONENT_MAP = {
  [FEATURE_BUTTON_ID_MAP.updateTransferOrders]: UpdateTransferOrdersButton,
  [FEATURE_BUTTON_ID_MAP.addTransferOrder]: AddTransferOrderButton
};
