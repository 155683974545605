import React, { useState, useEffect } from "react";
import { useStore } from "react-hookstore";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { Modal, Alert } from "react-bootstrap";
import { useXemelgoClient } from "../../services/xemelgo-service";
import ConfigurationService from "../../services/ConfigurationService";
import { userProfileStore } from "../../state-managements/stores/user-profile-store";
import { getDaysToMs } from "../../common/Utilities";
import LoadingCircle from "../../components/loading/LoadingCircle";
import DetailCard from "../../components/TrackPageComponents/DetailCard";
import Style from "./css/AssetTypeDetailPage.module.css";
import NotFoundComponent from "../../components/not-found-component/NotFoundComponent";
import { ROLES } from "../../data/constants";

const DAY_IN_MS = getDaysToMs(1);

const AssetTypeDetailPage = ({ history }) => {
  const [userProfile] = useStore(userProfileStore);

  const [titleStructure, setTitleStructure] = useState({});
  const [contentStrcutureList, setContentStructureList] = useState([]);
  const [ItemTypeClient] = useState(useXemelgoClient().getItemTypeClient());
  const [showDelete, setShowDelete] = useState(false);
  const [itemTypeInfo, setItemTypeInfo] = useState({});
  const [alert, setAlert] = useState("");
  const [loading, setLoading] = useState(true);
  const [moreOptions, setMoreOptions] = useState([]);

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    const payload = queryString.parse(history.location.search);
    const { id } = payload;
    const data = await ItemTypeClient.getItemTypeAndItems(id);
    if (!data) {
      setLoading(false);
    } else {
      setItemTypeInfo(data);
      const config = await ConfigurationService.getAssetItemType();

      const userRole = userProfile.getRole();
      const {
        moreOptions: tempMoreOptions = [
          {
            id: "edit",
            label: "Edit Asset Type",
            roles: [ROLES.ADMIN, ROLES.USER, ROLES.VENDOR_CLIENT_ADMIN, ROLES.VENDOR_CLIENT_USER]
          },
          {
            id: "delete",
            label: "Delete Asset Type",
            roles: [ROLES.ADMIN, ROLES.VENDOR_CLIENT_ADMIN]
          }
        ]
      } = config;

      const formattedMoreOptions = tempMoreOptions
        .filter((option) => {
          const { roles } = option;
          return roles.includes(userRole);
        })
        .map((option) => {
          const { id } = option;
          let onClick, textClassName;
          switch (id) {
            case "delete":
              onClick = () => {
                setShowDelete(true);
              };
              textClassName = Style.red_text;
              break;
            default:
              break;
          }
          return { ...option, onClick, textClassName };
        });
      setMoreOptions(formattedMoreOptions);
      parseData(data, config);
    }
  };

  const parseData = (data, properties) => {
    const propertyKeys = Object.keys(properties);
    const structureList = [];
    const title = {};
    propertyKeys.forEach((property) => {
      const currentProperty = properties[property];
      const { __updatable, index, displayName, inputType } = currentProperty;
      if (index !== 0) {
        const currentStructureObject = {
          id: property,
          value:
            property === "schedule_refresh_interval" || property === "expected_lifespan"
              ? data[property] / DAY_IN_MS
              : inputType === "number"
              ? +data[property]
              : data[property],
          editable: __updatable,
          key: property,
          format: undefined,
          index,
          name: displayName,
          relativeDayCount: {},
          type: inputType
        };
        structureList.push(currentStructureObject);
      } else {
        title.editable = __updatable;
        title.name = displayName;
        title.key = property;
        title.value = data[property];
      }
    });
    setTitleStructure(title);
    setContentStructureList(structureList);
    setLoading(false);
  };

  const handleSubmit = async (dataForm, event) => {
    const { id, identifier: originalIdentifier } = itemTypeInfo;
    const { identifier, expected_lifespan, schedule_refresh_interval, usage_limit } = dataForm;
    const expectedLifespanInMs = expected_lifespan * DAY_IN_MS;
    const refreshIntervalInMs = schedule_refresh_interval * DAY_IN_MS;
    if (identifier && originalIdentifier !== identifier) {
      const duplicate = await ItemTypeClient.getItemTypeByIdentifier(identifier);
      if (duplicate) {
        setAlert(`Item type ${identifier} already exists. Please use a different name.`);
        return;
      }
    }
    await ItemTypeClient.updateItemType(id, {
      name: identifier,
      identifier,
      expected_lifespan: expected_lifespan ? expectedLifespanInMs : undefined,
      schedule_refresh_interval: schedule_refresh_interval ? refreshIntervalInMs : undefined,
      usage_limit: usage_limit && +usage_limit
    });
  };

  const deleteAsset = async () => {
    const currentItemTypeInfo = await ItemTypeClient.getItemTypeAndItems(itemTypeInfo.id);
    if (currentItemTypeInfo.items !== null) {
      setAlert(
        "This asset type is currently tracking assets and cannot be deleted. Please ensure that all assets are deleted prior to deleting this asset type."
      );
    } else {
      await ItemTypeClient.removeItemType(itemTypeInfo.id);
      const fullPath = history.location.pathname;
      const parentPath = fullPath.slice(0, fullPath.indexOf("/itemType/detail"));
      history.replace(parentPath);
    }
    setLoading(false);
  };

  const renderDeleteAssetModal = () => {
    return (
      <Modal show={showDelete}>
        <Modal.Header className="route-modal-header">
          <Modal.Title className="route-modal-title">Delete Asset Type?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Deleting an asset type would permanently remove it from Xemelgo. Please ensure that all assets associated
            with this asset type have already been deleted before proceeding.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className={`${Style.delete_buttons} cancel-button`}
            onClick={() => {
              setShowDelete(false);
            }}
          >
            No, Keep Asset Type
          </button>
          <button
            className={`${Style.delete_buttons} confirm-delete-button`}
            onClick={() => {
              setLoading(true);
              setShowDelete(false);
              deleteAsset();
            }}
          >
            Yes, Delete Asset Type
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  const displayBanner = () => {
    return (
      <Alert
        variant="danger"
        onClose={() => {
          setAlert(false);
        }}
        dismissible
      >
        <h4>Error</h4>
        <p>{alert}</p>
      </Alert>
    );
  };

  if (loading) {
    return <LoadingCircle />;
  }

  if (!itemTypeInfo?.id) {
    return (
      <NotFoundComponent
        path="/asset"
        buttonLabel="Go To Track Page"
        mainText="Sorry, the asset type you have requested has been deleted."
        subText="Please click the following button to go back to track page."
      />
    );
  }

  return (
    <>
      {alert && displayBanner()}
      <div className={Style.main_container}>
        <DetailCard
          defaultImage={itemTypeInfo.imagePath}
          isActive
          titleStructure={titleStructure}
          detailStructureList={contentStrcutureList}
          onSubmit={handleSubmit}
          moreOptions={moreOptions}
        />
      </div>
      {renderDeleteAssetModal()}
    </>
  );
};

export default withRouter(AssetTypeDetailPage);
