export const TABLE_COLUMN_SIZE_MAP: Record<string, number> = {
  xsmall: 0.5,
  small: 2,
  medium: 5,
  large: 8,
  default: 1
};

export const ROW_COUNT_ATTRIBUTE = "rowCount";

export const CLEAR_BUTTON_ID = "clearButton";

export const CLEAR_BUTTON_SECTION = {
  attributes: [
    {
      id: CLEAR_BUTTON_ID
    }
  ]
};

export const ACTION_OPTIONS_MAP = {
  CHECK_OUT: "check-out",
  ADD: "add",
  MOVE: "move",
  CONSUME: "consume",
  UNCONSUME: "unconsume",
  PICKLIST_VERIFICATION: "picklistVerification",
  ORDER_CREATION: "orderCreation"
};

export const OPTION_TEMPLATE_OPTIONS = {
  USERS: "users"
};

export const QUERY_PAGE = 50;
